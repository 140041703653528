import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { happinessSurveyPropType, itemSurveyPropType } from "components/propTypes";
import happinessSurveyValues, {
  itemCondition,
  itemConditionInputOptions,
  optionalFitFeedBackInputOptions,
  overallFitFeedBackInputOptions,
  wornInquiryInputOptions,
  pages,
  happinessSurveyCTACopy,
} from "constants/happiness-survey-values";

import Rating from "components/source/reviews/rating";
import InputContainer from "./inputs/input-container";
import SurveyControls from "components/source/happiness_survey/hs_reviews_combined/survey-controls/survey-controls";
import SurveyItemBanner from "components/source/happiness_survey/hs_reviews_combined/survey-item-banner";
import HappinessSurveyFooter from "components/source/happiness_survey/hs_reviews_combined/happiness-survey-footer";
import CapsuleSelect from "./inputs/capsule-select";
import AccordionContainer from "./inputs/accordion-container";

import HappinessSurveyStyles from "./happiness-survey.module.scss";
import InputStyles from "./inputs/inputs.module.scss";
import { addSurveyRating, addFeedback } from "actions/happiness-survey-actions";
import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";
import { convertCurrentSurveyIntoForm, isLocalValueSelected } from "helpers/happiness-survey-helpers";

const propTypes = {
  currentSurvey: itemSurveyPropType,
  goToPage: PropTypes.func.isRequired,
  happinessSurvey: happinessSurveyPropType,
  handleCompleteSurvey: PropTypes.func,
  hasSubmittedFirstSurvey: PropTypes.bool,
  isHappinessSurveySkipFlagEnabled: PropTypes.bool,
  closeHappinessSurvey: PropTypes.func,
  isLastSurvey: PropTypes.bool,
  isPreviouslyReviewed: PropTypes.bool,
};

const optionalFitFeedBackInputKeys = Object.keys(optionalFitFeedBackInputOptions);
const rateItInputKeys = [
  happinessSurveyValues.feedbackTypes.wornInquiry,
  happinessSurveyValues.feedbackTypes.itemCondition,
  happinessSurveyValues.feedbackTypes.overallFit,
  ...optionalFitFeedBackInputKeys,
];
const defaultForm = rateItInputKeys.reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: "",
  }),
  {}
);
const feedbackValuesMap = {
  [happinessSurveyValues.feedbackTypes.wornInquiry]: wornInquiryInputOptions.map(option => option.value),
};

function RateIt({
  currentSurvey,
  goToPage,
  happinessSurvey,
  isHappinessSurveySkipFlagEnabled,
  closeHappinessSurvey = () => {},
  handleCompleteSurvey,
  hasSubmittedFirstSurvey,
  isLastSurvey,
  isPreviouslyReviewed,
}) {
  if (!currentSurvey || !currentSurvey.bookingId) {
    return null;
  }

  const [rating, setRating] = React.useState(currentSurvey.rating ?? 0);
  const [form, setForm] = React.useState(convertCurrentSurveyIntoForm(currentSurvey, defaultForm, feedbackValuesMap));
  const dispatch = useDispatch();

  const saveFeedbackToRedux = () => {
    dispatch(addSurveyRating(rating, currentSurvey.bookingId));

    for (const key in form) {
      let feedbackValuesMap = happinessSurveyValues[key];
      if (key === happinessSurveyValues.feedbackTypes.wornInquiry) {
        // structure looks like: { [wornInquiryOption]: wornInquiryOption }
        feedbackValuesMap = wornInquiryInputOptions
          .map(option => option.value)
          .reduce((p, c) => ({ ...p, [c]: c }), {});
      }

      if (
        (!form[key] && happinessSurveyContainsFeedback(currentSurvey, Object.keys(feedbackValuesMap))) ||
        (form[key] && !happinessSurveyContainsFeedback(currentSurvey, form[key]))
      ) {
        // dispatching addFeedback does two things:
        // 1. with a declared type will remove all feedback related to that key
        // 2a. dispatching with a new value will add the feedback value to the survey
        // 2b. dispatching with a null value will do nothing
        dispatch(addFeedback(form[key], currentSurvey.bookingId, key, feedbackValuesMap));
      }
    }
  };

  // if we return nothing, we hide the back button
  const handleClickBack = () => {
    if (!hasSubmittedFirstSurvey) {
      return () => {
        saveFeedbackToRedux();
        goToPage(pages.START);
      };
    } else {
      return;
    }
  };

  const handleClickNext = () => {
    saveFeedbackToRedux();
    if (form[happinessSurveyValues.feedbackTypes.itemCondition] === itemCondition.ITEM_CONDITION_POOR) {
      // when we deprecate the old HS, let's replace this with happinessSurveyValues.negativeFeedback.POOR_CONDITION in SWAT-464
      goToPage(pages.QUALITY_ISSUES);
    } else if (isPreviouslyReviewed) {
      handleCompleteSurvey();
    } else {
      goToPage(pages.REVIEW);
    }
  };

  const handleSelect = (value, type) => {
    setForm(oldForm => ({
      ...oldForm,
      [type]: oldForm[type] === value ? "" : value,
    }));
  };

  const renderWornHappinessLevelLabels = rating => {
    if (rating in currentSurvey.ratingMaps.ratingMap) {
      return currentSurvey.ratingMaps.ratingMap[rating];
    } else {
      return "";
    }
  };

  const REVIEW_RATING_VALUES = Object.keys(currentSurvey.ratingMaps.ratingMap).map(x => parseInt(x));

  const ratingLabelProps = {
    headerText: "How would you rate this item?",
    isRequired: true,
  };

  const wearBucketLabelProps = {
    headerText: "How many times did you wear this item?",
    isRequired: true,
  };

  const conditionLabelProps = {
    headerText: "What was the condition of the item?",
    isRequired: true,
  };

  const fitLabelProps = {
    headerText: "How did it fit?",
  };

  const optionalFitLabelProps = {
    headerText: "Any other fit details to add?",
  };

  const primaryCtaText = isLastSurvey
    ? happinessSurveyCTACopy.SUBMIT_LAST_SURVEY
    : happinessSurveyCTACopy.SUBMIT_SURVEY_AND_CONTINUE;

  const submissionButtonDisabled =
    rating < 1 ||
    !form[happinessSurveyValues.feedbackTypes.itemCondition] ||
    !form[happinessSurveyValues.feedbackTypes.wornInquiry];

  const footerProps = isHappinessSurveySkipFlagEnabled
    ? {
        showSecondaryCta: true,
        secondaryCtaText: "Save For Later",
        secondaryCtaDisabled: false,
        onClickSecondaryCta: closeHappinessSurvey,
      }
    : {};

  const footer = isPreviouslyReviewed ? (
    <HappinessSurveyFooter
      onClick={handleClickNext}
      disabled={submissionButtonDisabled}
      primaryCtaText={primaryCtaText}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...footerProps}
    />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HappinessSurveyFooter onClick={handleClickNext} disabled={submissionButtonDisabled} {...footerProps} />
  );

  return (
    <div className={HappinessSurveyStyles["happiness-survey__container"]}>
      <SurveyControls onClickBack={handleClickBack()} />
      <div className={HappinessSurveyStyles["happiness-survey__body"]}>
        <SurveyItemBanner currentSurvey={currentSurvey} happinessSurvey={happinessSurvey} />
        <div className={HappinessSurveyStyles["happiness-survey__content"]}>
          {isPreviouslyReviewed && (
            <>
              <div className={HappinessSurveyStyles["happiness-survey__previously-reviewed"]}>
                <p>Let us know how this rental went.</p>
                <p className={HappinessSurveyStyles["happiness-survey__previously-reviewed__body"]}>
                  You previously reviewed this item. To update your review, go to your Order History.
                </p>
              </div>
              <div className={HappinessSurveyStyles["happiness-survey__divider"]} />
            </>
          )}

          <InputContainer labelProps={ratingLabelProps}>
            <div className={InputStyles["star-rating__container"]}>
              <Rating
                className={InputStyles["star-rating__input"]}
                defaultValue={rating}
                values={REVIEW_RATING_VALUES}
                onSelectRating={rating => setRating(rating)}
              />
              {rating > 0 && (
                <span className={InputStyles["star-rating__value-label"]}>
                  {renderWornHappinessLevelLabels(rating)}
                </span>
              )}
            </div>
          </InputContainer>

          <InputContainer labelProps={wearBucketLabelProps}>
            <CapsuleSelect
              isOptionsOnly={true}
              onSelect={handleSelect}
              options={wornInquiryInputOptions.map(option => ({
                ...option,
                selected: isLocalValueSelected(form, option.value, happinessSurveyValues.feedbackTypes.wornInquiry),
              }))}
              type={happinessSurveyValues.feedbackTypes.wornInquiry}
            />
          </InputContainer>

          <InputContainer labelProps={conditionLabelProps}>
            <CapsuleSelect
              isOptionsOnly={true}
              onSelect={handleSelect}
              options={itemConditionInputOptions.map(option => ({
                ...option,
                selected: isLocalValueSelected(form, option.value, happinessSurveyValues.feedbackTypes.itemCondition),
              }))}
              type={happinessSurveyValues.feedbackTypes.itemCondition}
            />
          </InputContainer>

          {!isPreviouslyReviewed && (
            <>
              <div className={HappinessSurveyStyles["happiness-survey__divider"]} />
              <InputContainer labelProps={fitLabelProps}>
                <CapsuleSelect
                  isOptionsOnly={true}
                  onSelect={handleSelect}
                  options={overallFitFeedBackInputOptions.map(option => ({
                    ...option,
                    selected: isLocalValueSelected(form, option.value, happinessSurveyValues.feedbackTypes.overallFit),
                  }))}
                  type={happinessSurveyValues.feedbackTypes.overallFit}
                />
              </InputContainer>

              <AccordionContainer
                showDisplay={true}
                labelProps={optionalFitLabelProps}
                additionalClassName={HappinessSurveyStyles["happiness-survey__other-fit-options-container"]}>
                {optionalFitFeedBackInputKeys.map(key => {
                  const inputObject = optionalFitFeedBackInputOptions[key];
                  const options = inputObject.options.map(option => ({
                    ...option,
                    selected: isLocalValueSelected(form, option.value, key),
                  }));
                  const labelProps = {
                    headerText: inputObject.title,
                  };

                  return (
                    <InputContainer labelProps={labelProps}>
                      <CapsuleSelect
                        isOptionsOnly={true}
                        key={key}
                        onSelect={handleSelect}
                        options={options}
                        type={key}
                        typeAsText={inputObject.title}
                      />
                    </InputContainer>
                  );
                })}
              </AccordionContainer>
            </>
          )}
        </div>
      </div>
      {footer}
    </div>
  );
}
RateIt.pageName = happinessSurveyValues.pages.RATE_IT;
RateIt.propTypes = propTypes;

export default RateIt;
