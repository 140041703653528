import React from "react";
import PropTypes from "prop-types";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import ActionLogger from "action-logger";
import RtrImage from "../shared/rtr-image";

export class PDPPhotoReviewThumbnailCarousel extends React.Component {
  static propTypes = {
    reviewsWithPhotos: PropTypes.arrayOf(
      PropTypes.shape({
        moment: PropTypes.shape({
          photos: PropTypes.arrayOf(
            PropTypes.shape({
              urls: PropTypes.shape({
                imageCroppedThumb: PropTypes.string,
                imageCroppedLargeThumb: PropTypes.string,
              }),
            })
          ),
          reviewId: PropTypes.number,
        }),
      })
    ),
    thumbNailSize: PropTypes.oneOf(["imageCroppedThumb", "imageCroppedLargeThumb", "imageThumb"]),
    pageSize: PropTypes.number,
    onImgClick: PropTypes.func,
    title: PropTypes.string,
    className: PropTypes.string,
    isUserSpecificReview: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    reviewsWithPhotos: [],
    thumbNailSize: "imageCroppedThumb",
    pageSize: 5,
    className: "",
    title: "",
    onImgClick: () => {},
  };

  // eslint-disable-next-line no-unused-vars
  arrowClick = (direction, carousel, index) => {
    const { pageSize } = this.props;
    ActionLogger.logAction({
      object_type: "node",
      action: "moment_carousel_" + (direction > 0 ? "next" : "prev"),
      page: Math.floor(index / pageSize),
    });
  };

  onImgPreviewClick(review) {
    const { onImgClick, isUserSpecificReview } = this.props;
    onImgClick(isUserSpecificReview ? review.reviewId : review.moment.reviewId);
  }

  render() {
    const { reviewsWithPhotos, pageSize, title, className, thumbNailSize, isUserSpecificReview } = this.props;
    if (reviewsWithPhotos.length === 0) {
      return null;
    }

    return (
      <div
        className={`photo-review-thumbnail-carousel ${className}`}
        data-test-id="pdp-photo-review-thumbnail-carousel">
        <p className="title">
          {title} ({reviewsWithPhotos.length})
        </p>
        <SwipeableCarousel pageSize={pageSize} logClickArrow={this.arrowClick} carousel={{}}>
          {reviewsWithPhotos
            .filter(r => {
              return isUserSpecificReview
                ? r?.photos?.[0]?.urls?.[thumbNailSize]
                : r?.moment?.photos?.[0]?.urls?.[thumbNailSize];
            })
            .map((review, i) => (
              <button onClick={() => this.onImgPreviewClick(review)} key={i}>
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <RtrImage
                  src={
                    isUserSpecificReview
                      ? review.photos[0].urls[thumbNailSize]
                      : review.moment.photos[0].urls[thumbNailSize]
                  }
                  alt="preview of another review photo"
                />
              </button>
            ))}
        </SwipeableCarousel>
      </div>
    );
  }
}

export default PDPPhotoReviewThumbnailCarousel;
