import PropTypes from "prop-types";
import InputsStyles from "./inputs.module.scss";

const propTypes = {
  selectAllText: PropTypes.string,
  selectNoneText: PropTypes.string,
  isSelectAllChecked: PropTypes.bool,
  isSelectNoneChecked: PropTypes.bool,
  handleSelectAll: PropTypes.func,
  handleSelectNone: PropTypes.func,
  isHappinessSurveySkipFlagEnabled: PropTypes.bool,
};

const HappinessSurveyCheckbox = ({
  selectAllText,
  isSelectAllChecked = false,
  handleSelectAll,
  selectNoneText,
  isSelectNoneChecked = false,
  handleSelectNone,
  isHappinessSurveySkipFlagEnabled,
}) => {
  const renderSelectAll = () => {
    return (
      <div className={InputsStyles["checkbox-selection"]}>
        {isSelectAllChecked ? (
          <svg data-test-id="select-all-checked" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" />
            <path d="M6 13.186L10.2439 18L18 6" />
          </svg>
        ) : (
          <svg data-test-id="select-all-unchecked" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" />
          </svg>
        )}
      </div>
    );
  };

  const renderSelectNone = () => {
    return (
      <div className={InputsStyles["checkbox-selection"]}>
        {isSelectNoneChecked ? (
          <svg data-test-id="select-none-checked" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" />
            <path d="M6 13.186L10.2439 18L18 6" />
          </svg>
        ) : (
          <svg data-test-id="select-none-unchecked" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" />
          </svg>
        )}
      </div>
    );
  };

  const checkboxes = isHappinessSurveySkipFlagEnabled ? (
    <div className={InputsStyles["checkboxes"]}>
      <label className={InputsStyles["double-checkbox"]}>
        <input type="checkbox" checked={isSelectAllChecked} onChange={handleSelectAll} />
        {renderSelectAll()}
        {selectAllText}
      </label>
      <label className={InputsStyles["double-checkbox"]}>
        <input
          type="checkbox"
          className="pointer-events-none"
          checked={isSelectNoneChecked}
          onChange={handleSelectNone}
        />
        {renderSelectNone()}
        {selectNoneText}
      </label>
    </div>
  ) : (
    <div className={InputsStyles["checkboxes"]}>
      <label className={InputsStyles["checkbox-label"]}>
        <input
          type="checkbox"
          className="pointer-events-none"
          checked={isSelectAllChecked}
          onChange={handleSelectAll}
        />
        {renderSelectAll()}
        {selectAllText}
      </label>
    </div>
  );
  return checkboxes;
};

HappinessSurveyCheckbox.propTypes = propTypes;
export default HappinessSurveyCheckbox;
