import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputStyles from "./inputs.module.scss";

const propTypes = {
  isOpen: PropTypes.bool,
  isOptionsOnly: PropTypes.bool, // if the capsule always shows the options
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  typeAsText: PropTypes.string,
};

const CapsuleSelect = ({
  isOpen = false,
  isOptionsOnly = false,
  onClear = () => {},
  onClose = () => {},
  onOpen = () => {},
  onSelect = () => {},
  options,
  type,
  typeAsText,
}) => {
  const [selectedValue, setSelectedValue] = React.useState();
  const [showTitle, setShowTitle] = React.useState(!isOpen);

  React.useEffect(() => {
    setSelectedValue(options.find(value => value.selected));
  }, [options]);

  const handleOpen = () => {
    onOpen(type);
    setShowTitle(false);
  };

  const handleClear = () => {
    const selected = selectedValue;

    if (selected) {
      onClear(selected.value, type);
      setSelectedValue();
      setShowTitle(false);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    setShowTitle(true);
  };

  const handleClickTitle = () => {
    if (showTitle && selectedValue) {
      handleClear();
    } else {
      handleOpen();
    }
  };

  const handleClickTab = value => {
    onSelect(value, type);
    if (!isOptionsOnly) {
      handleClose();
    }
  };

  const titleClass = classNames(InputStyles["capsule-select__title"], {
    [InputStyles["selected"]]: !!selectedValue,
    [InputStyles["open"]]: showTitle,
    [InputStyles["hidden"]]: !showTitle,
  });

  const optionsInputClass = selected =>
    classNames(InputStyles["capsule-select__option-input"], {
      [InputStyles["options-only"]]: isOptionsOnly,
      [InputStyles["selected"]]: selected,
    });

  const optionsContainerClass = classNames(InputStyles["capsule-select__option-container"], {
    [InputStyles["open"]]: !showTitle || isOptionsOnly,
    [InputStyles["hidden"]]: !isOptionsOnly && showTitle,
  });

  return (
    <div className={InputStyles["capsule-select__container"]}>
      {!isOptionsOnly && (
        <button className={titleClass} onClick={handleClickTitle} data-test-id={`capsule-select-${type}-title`}>
          {typeAsText && <p>{typeAsText}</p>}
          {!!selectedValue && <p>{selectedValue.text}</p>}
        </button>
      )}
      <div className={optionsContainerClass}>
        {options.map(option => (
          <button
            key={`${option.text}-option`}
            data-test-id={`capsule-select-${type}-${option.value}`}
            className={optionsInputClass(option.selected)}
            onClick={() => handleClickTab(option.value)}>
            {option.text}
          </button>
        ))}
      </div>
    </div>
  );
};

CapsuleSelect.propTypes = propTypes;

export default CapsuleSelect;
