import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UserActions from "actions/user-actions";
import ActionLogger from "action-logger";
import Rating from "./rating";
import PhotoUploader from "./photo-uploader";
import LoadingSpinner from "components/source/new_taxonomy/loading-spinner";
import AtomSelectionButton from "components/source/atoms/atom-selection-button";
import { analytics, contact, dateFnsFormats, HS_REVIEW_LEGAL_DISCLAIMER } from "rtr-constants";
import AtomPill from "components/source/atoms/atom-pill";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import UserProfileFormTextInput from "components/source/profile/user_profile_form//text-input";
import UserProfileFormSelect from "components/source/profile/user_profile_form//select";
import { FIT_INPUT_STANDALONE_CONFIG } from "components/source/profile/user_profile_form/config.js";
import { formatYYYYMMDD } from "helpers/date-helpers";
import { validators, errorMessages } from "components/source/profile/user_profile_form/validations.js";
import { hasFileSizeError } from "actions/shared-actions";
import ProductImage from "../shared/product-image";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  mapLegacyProductImageBySizeToOrientation,
  ProductImageOrientation,
  ProductImageSize,
} from "../../../helpers/product-image-helpers";
import { MODAL_NAMES } from "rtr-constants/modalNames";
import { HELP } from "routes";
import { addCacheBusterToUrl } from "../../../helpers/location-helpers";

const DRESS_TYPE = "DRESS";
const SALEABLE_TYPE = "SALEABLEPRODUCT";

export const EVENT_TYPES = ["Date", "Everyday", "Formal Affair", "Party", "Vacation", "Wedding", "Work", "Other"];

export class ReviewUploadModal extends React.Component {
  static propTypes = {
    clearFileSizeError: PropTypes.func,
    hasFileSizeError: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    loading: PropTypes.bool,
    loadingPhotos: PropTypes.bool,
    loadingRotatedPhoto: PropTypes.bool,
    onDeletePhoto: PropTypes.func.isRequired, // photoId => {}
    onRotatePhoto: PropTypes.func.isRequired, // (photoId) => {}
    onSubmitReview: PropTypes.func.isRequired, // reviewData => {}
    onUploadPhoto: PropTypes.func.isRequired, // (styleName, photo) => {}
    pendingReview: PropTypes.shape({
      caption: PropTypes.string,
      content: PropTypes.string,
      eventType: PropTypes.string,
      fit: PropTypes.string,
      rating: PropTypes.number,
      sizeWorn: PropTypes.string,
      happinessSurveyId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      fitFeedbackMap: PropTypes.object,
    }), // a previously submitted review that may be edited
    product: PropTypes.shape({
      imgProductTeaserFrontUrl: PropTypes.string,
      sizes: PropTypes.arrayOf(PropTypes.string),
      styleName: PropTypes.string,
      type: PropTypes.string,
      displayName: PropTypes.string,
      designerName: PropTypes.string,
      imagesBySize: PropTypes.object,
    }),
    profile: PropTypes.shape({
      bodyType: PropTypes.string,
      bustSize: PropTypes.string,
      dateOfBirth: PropTypes.string,
      height: PropTypes.string,
      weight: PropTypes.string,
      profiles: PropTypes.shape({
        primarySize: PropTypes.string,
      }),
    }),
    updateProfile: PropTypes.func,
    fetchProfile: PropTypes.func,
    rentedSizes: PropTypes.arrayOf(PropTypes.string),
    reviewPhotoError: PropTypes.string,
    reviewPhotos: PropTypes.arrayOf(
      PropTypes.shape({
        photoId: PropTypes.number,
      })
    ),
  };

  static defaultProps = {
    reviewPhotos: [],
  };

  static MODAL_NAME = MODAL_NAMES.REVIEW_UPLOAD;

  state = {
    rating: null,
    ratingBlurred: false,
    eventType: null,
    eventTypeBlurred: false,
    title: null,
    titleBlurred: false,
    reviewText: null,
    reviewTextBlurred: false,
    fit: null,
    fitBlurred: false,
    sizeWorn: null,
    sizeWornBlurred: false,
    validate: false,
    user: {
      height: this.props?.profile?.height,
      dateOfBirth: this.formattedBirthday(),
      bustSize: this.props?.profile?.bustSize,
      bodyType: this.props?.profile?.bodyType,
      weight: this.props?.profile?.weight,
      primarySize: this.props?.profile?.profiles?.primarySize,
    },
    fitProfileUpdate: false,
  };

  LOW_REVIEW_THRESHOLD = 8;
  REVIEW_RATING_VALUES = [2, 4, 6, 8, 10];

  componentDidMount() {
    this.updateStateConditionally();
  }

  componentDidUpdate(prevProps) {
    // NW [EXPLANATION] 8/10/21: populate state from a previously saved review, so it can be edited
    if (prevProps.pendingReview === this.props.pendingReview) {
      return;
    } else {
      this.updateStateConditionally();
    }
  }

  updateStateConditionally() {
    if (this.isHSReviewWithFitFeedback(this.props.pendingReview)) {
      this.setState({
        rating: this.props.pendingReview?.rating,
        eventType: this.props.pendingReview?.eventType,
        title: this.props.pendingReview?.caption,
        reviewText: this.removeLegalDisclaimerInHSReview(this.props.pendingReview?.content),
        fit: this.props.pendingReview?.fit,
        sizeWorn: this.props.pendingReview?.sizeWorn,
      });
    } else if (!this.isHSReview(this.props.pendingReview)) {
      this.setState({
        rating: this.props.pendingReview?.rating,
        eventType: this.props.pendingReview?.eventType,
        title: this.props.pendingReview?.caption,
        reviewText: this.props.pendingReview?.content,
        fit: this.props.pendingReview?.fit,
        sizeWorn: this.props.pendingReview?.sizeWorn,
      });
    }
  }

  removeLegalDisclaimerInHSReview = reviewText => {
    if (!reviewText || typeof reviewText !== "string") return;
    return reviewText.replace(HS_REVIEW_LEGAL_DISCLAIMER, "");
  };

  formattedBirthday() {
    const { dateOfBirth = "" } = this.props.profile || {};
    // dateOfBirth can have a timestamp (ex: 1995-02-15T00:00:00.000Z)
    // so we need to clean it
    const timeStampIndex = dateOfBirth.indexOf("T") > 0 ? dateOfBirth.indexOf("T") : 10;
    const dateStringWithoutTimeStamp = dateOfBirth.slice(0, timeStampIndex);
    return formatYYYYMMDD(dateStringWithoutTimeStamp, dateFnsFormats.MM_DD_YYYY) || "";
  }

  onSelectStar = rating => {
    this.setState({
      rating,
    });
  };

  onFieldUpdated = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  onFieldBlur = fieldName => {
    this.setState({
      [`${fieldName}Blurred`]: true,
    });
  };

  getInvalidClassNameOld = fieldName => {
    return !this.state[`${fieldName}Blurred`] || this.state[fieldName] ? "" : "review-popup-error";
  };

  getInvalidClassName = fieldName => {
    return this.state.validate && !this.state[fieldName] ? "error" : "";
  };

  productIsDress() {
    return this.props.product?.type?.toUpperCase() === DRESS_TYPE;
  }

  productIsSaleable() {
    return this.props.product?.type?.toUpperCase() === SALEABLE_TYPE;
  }

  renderLowRatingMsg() {
    if (this.state.rating === null || this.state.rating >= this.LOW_REVIEW_THRESHOLD) {
      return null;
    }

    return (
      <div className="low-rating-msg">
        <p>
          If you’ve had quality issues with your item please contact us at{" "}
          <a className="universal-small--semibold underline" href={`mailto:${contact.help}`}>
            {contact.help}
          </a>
          .
        </p>
      </div>
    );
  }

  renderStarRating() {
    return (
      <>
        <fieldset>
          <legend className={`section-label ${this.getInvalidClassName("rating")}`}>What did you think?</legend>
          <Rating
            defaultValue={this.state.rating}
            values={this.REVIEW_RATING_VALUES}
            onSelectRating={rating => this.onSelectStar(rating)}
          />
        </fieldset>
        {this.renderLowRatingMsg()}
      </>
    );
  }

  renderDisplayImage() {
    const { product } = this.props;
    return (
      <div className="display-photo-container">
        <ProductImage
          altText={getProductImageAltText(product?.displayName, product?.designerName)}
          className="display-photo"
          imageGroup={getProductImageUrlsByOrientation(
            mapLegacyProductImageBySizeToOrientation(product?.imagesBySize),
            [
              ProductImageOrientation.FRONT.legacyPath,
              ProductImageOrientation.NO_MODEL.legacyPath,
              ProductImageOrientation.BACK.legacyPath,
              ProductImageOrientation.SIDE.legacyPath,
              ProductImageOrientation.EDITORIAL.legacyPath,
            ]
          )}
          imageSize={ProductImageSize.x183}
          fallbackSrc={product?.imgProductTeaserFrontUrl}
        />
        <p className="universal-medium--semibold">{product?.designerName}</p>
        <p>Size {this.props.rentedSizes?.[0]}</p>
      </div>
    );
  }

  renderSizeWorn() {
    const sizeOptions = this.props.rentedSizes?.length ? this.props.rentedSizes : this.props.product?.sizes;

    if (!sizeOptions || !sizeOptions.length) {
      return null;
    }

    if (sizeOptions.length === 1) {
      if (!this.state.sizeWorn) {
        this.setState({
          sizeWorn: sizeOptions[0],
        });
      }

      return null;
    }

    const sizeOptionsFull = sizeOptions.map(size => ({ value: size, label: `size ${size}`, formId: `size-${size}` }));

    return (
      <fieldset className={`sizeWorn ${this.getInvalidClassNameOld("sizeWorn")}`} data-test-id="sizeWorn">
        {sizeOptionsFull.length < 4 ? (
          <>
            <legend className={`section-label ${this.getInvalidClassName("sizeWorn")}`}>What size did you wear?</legend>
            <AtomSelectionButton
              selections={sizeOptionsFull}
              selected={this.state.sizeWorn}
              groupName="sizeWorn"
              onChange={e => this.onFieldUpdated("sizeWorn", e)}
            />
          </>
        ) : (
          <>
            <label htmlFor="size-worn-admin-view" className={`section-label ${this.getInvalidClassName("sizeWorn")}`}>
              What size did you wear?
            </label>
            <select id="size-worn-admin-view" onChange={e => this.onFieldUpdated("sizeWorn", e)}>
              <option value="" disabled selected hidden>
                Choose a Size
              </option>
              {sizeOptionsFull.map((option, i) => (
                <option value={option.value} key={i} selected={this.state.sizeWorn === option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </>
        )}
      </fieldset>
    );
  }

  renderFitRating() {
    const buttonOptions = [];
    if (this.productIsDress() || !this.productIsSaleable()) {
      buttonOptions[0] = { value: "Small", label: "too small", formId: "too-small" };
      buttonOptions[1] = { value: "True to Size", label: "just right", formId: "just-right" };
      buttonOptions[2] = { value: "Large", label: "too large", formId: "too-large" };
    } else {
      return null;
    }

    return (
      <fieldset className="fit-container">
        <legend className={`section-label ${this.getInvalidClassName("fit")}`}>How did it fit?</legend>
        <AtomSelectionButton
          selections={buttonOptions}
          groupName="fit-rating"
          selected={this.state.fit}
          onChange={e => this.onFieldUpdated("fit", e)}
        />
      </fieldset>
    );
  }

  renderEventWorn() {
    return (
      <fieldset>
        <legend className={`section-label ${this.getInvalidClassName("eventType")}`}>Where did you wear it?</legend>
        <span className="universal-small--secondary">Select one</span>
        <div className="event-worn-container" data-test-id="event-worn-container">
          {EVENT_TYPES.map((event, i) => (
            <div key={i}>
              <input
                type="radio"
                id={event}
                checked={this.state.eventType === event}
                name="event"
                value={event}
                onChange={() =>
                  this.setState({
                    eventType: event,
                  })
                }></input>
              <label htmlFor={event}>
                <AtomPill large={true}>{event}</AtomPill>
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    );
  }

  renderImageUpload() {
    return (
      <fieldset className="review-item-box">
        <legend className="section-label">Let’s see the look!</legend>
        <span className="universal-small--secondary">Photos help others discover what will fit them best.</span>

        <PhotoUploader
          deletePhoto={this.onDeletePhoto}
          loadingPhotos={this.props.loadingPhotos}
          loadingRotatedPhoto={this.props.loadingRotatedPhoto}
          maxPhotos={3}
          reviewPhotos={this.props.reviewPhotos}
          reviewPhotoError={this.props.reviewPhotoError}
          rotatePhoto={this.onRotatePhoto}
          submitPhoto={this.onUploadPhoto}
        />
      </fieldset>
    );
  }

  renderReviewText() {
    return (
      <fieldset className="review-text-container">
        <legend className="section-label">What should others know?</legend>
        <div className="title-container">
          <input
            maxLength="100"
            className={`review-title ${this.getInvalidClassName("title")}`}
            type="text"
            placeholder="Tell them the gist"
            value={this.state.title}
            onChange={e => this.onFieldUpdated("title", e)}></input>
          {this.renderTextRemainingCharacters(100, this.state.title, 50)}
        </div>
        <textarea
          maxLength="2000"
          className={`review-details ${this.getInvalidClassName("reviewText")}`}
          rows="4"
          value={this.state.reviewText}
          placeholder="Share hot tips on the fit, musings on the vibe, and any styling advice to note"
          onChange={e => this.onFieldUpdated("reviewText", e)}></textarea>
      </fieldset>
    );
  }

  renderProfileSection() {
    return (
      <fieldset className="fit-profile-container">
        <legend className="section-label">Your Fit Profile</legend>
        <div className="fit-profile" data-test-id="fit-profile">
          {FIT_INPUT_STANDALONE_CONFIG.map(config =>
            config.type === "text" ? (
              <UserProfileFormTextInput
                key={config.stateKey}
                config={config}
                value={this.state.user[config.stateKey]}
                onChange={event =>
                  this.setState({
                    user: { ...this.state.user, [config.stateKey]: event.target.value },
                    [`${config.stateKey}Error`]: "",
                    fitProfileUpdate: true,
                  })
                }
                showErrorMsg={false}
                error={this.state.validate && this.state[`${config.stateKey}Error`]}
              />
            ) : (
              <UserProfileFormSelect
                key={config.stateKey}
                config={config}
                value={this.state.user[config.stateKey]}
                onChange={event =>
                  this.setState({
                    user: { ...this.state.user, [config.stateKey]: event.target.value },
                    [`${config.stateKey}Error`]: "",
                    fitProfileUpdate: true,
                  })
                }
                error={this.state.validate && this.state[`${config.stateKey}Error`]}
                options={[["", ""], ...config.options]}
              />
            )
          )}
        </div>
      </fieldset>
    );
  }

  renderReviewUploadContent() {
    if (!this.props.loading) {
      return (
        <div className="content">
          <div className="top-section">
            {this.renderDisplayImage()}
            <div className="stars-photos-fit-section">
              {this.renderStarRating()}
              {this.renderSizeWorn()}
              {this.renderImageUpload()}
              {this.renderFitRating()}
            </div>
          </div>
          <div className="middle-section">{this.renderEventWorn()}</div>
          <div className="bottom-section">
            {this.renderReviewText()}
            {this.renderProfileSection()}
            <div className="submit-review-container">
              <AtomFullWidthButton
                buttonText="Submit"
                className="submit-review btn"
                onClick={this.onSubmit}
                disabled={this.props.isSubmitting}></AtomFullWidthButton>
            </div>
          </div>
        </div>
      );
    }
  }

  renderLowRatingCta() {
    if (this.state.rating === null || this.state.rating >= this.LOW_REVIEW_THRESHOLD) {
      return null;
    }

    return (
      <div className="overview-box__low-rating-cta">
        <p>
          If there was an issue with the quality of your item, please{" "}
          <a className="accent standalone-link" href={`${addCacheBusterToUrl(HELP.CONTACT)}`}>
            contact us
          </a>
          .
        </p>
        <p>In order for these reviews to be helpful to other women, please limit them to style and fit information.</p>
      </div>
    );
  }

  renderOverallRating() {
    return (
      <>
        <div className="overview-item-box">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Overall rating</label>
          <Rating
            className={this.getInvalidClassNameOld("rating")}
            defaultValue={this.state.rating}
            values={this.REVIEW_RATING_VALUES}
            onSelectRating={rating => this.onSelectStar(rating)}
          />
        </div>
        {this.renderLowRatingCta()}
      </>
    );
  }

  renderFitInput(value, text, id) {
    return (
      <>
        <input
          type="radio"
          name="fit"
          value={value}
          id={id}
          checked={this.state.fit === value}
          onChange={e => this.onFieldUpdated("fit", e)}
        />
        <label htmlFor={id}>{text}</label>
      </>
    );
  }

  renderLoadingIndicator() {
    if (this.props.loading) {
      return (
        <div className="spinner">
          <LoadingSpinner />
        </div>
      );
    }
  }

  onRotatePhoto = photoId => {
    return this.props
      .onRotatePhoto(photoId)
      .then(() => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: "rotate_photo",
          styleName: this.props.product?.styleName,
        });
      })
      .catch(response => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: analytics.ACTION_TYPE.ERROR,
          styleName: this.props.product?.styleName,
          errorMessage: "postRotationForImage failed: " + JSON.stringify(response),
        });
      });
  };

  onUploadPhoto = photo => {
    const { styleName } = this.props.product || {};

    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.REVIEWS,
      action: "click_upload_button",
      styleName,
    });

    return this.props
      .onUploadPhoto(styleName, photo)
      .then(() => {
        if (this.props.hasFileSizeError) {
          this.props.clearFileSizeError();
        }
      })
      .then(() => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: "receive_review_photo",
          styleName,
        });
      })
      .catch(() => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: analytics.ACTION_TYPE.ERROR,
          styleName,
          errorMessage: "Photo upload error",
        });
      });
  };

  formatInchesHeight(inches) {
    return Math.floor(inches / 12) + "' " + (inches % 12) + "''";
  }

  renderProfileField(name, value) {
    return (
      <tr>
        <td>{name}:</td>
        <td className="accent">{value}</td>
      </tr>
    );
  }

  validateProfileInfo() {
    const newStateObj = {};
    let isValid = true;

    Object.entries(this.state.user).forEach(([key, value]) => {
      const required = [...FIT_INPUT_STANDALONE_CONFIG].find(({ stateKey }) => stateKey === key)?.required;
      if ((required && !value) || (validators?.[key] && !validators[key]?.(value))) {
        newStateObj[`${key}Error`] = errorMessages[key];
        isValid = false;
      } else {
        newStateObj[`${key}Error`] = "";
      }
    });

    this.setState({ ...newStateObj });

    return isValid;
  }

  validateFields() {
    let valid = true;

    // NW [EXPLANATION] 8/5/21: set required fields as blurred so they show validation error styling
    if (!this.state.rating || !this.state.eventType || !this.state.title) {
      this.setState({
        ratingBlurred: true,
        eventTypeBlurred: true,
        titleBlurred: true,
      });
      valid = false;
    }

    if ((this.productIsDress() || !this.productIsSaleable()) && !this.state.fit) {
      this.setState({
        fitBlurred: true,
      });
      valid = false;
    }

    if (this.productIsDress() && !this.state.sizeWorn) {
      this.setState({
        sizeWornBlurred: true,
      });
      valid = false;
    }

    if (!this.validateProfileInfo()) {
      valid = false;
    }

    this.setState({ validate: true });

    return valid;
  }

  onDeletePhoto = (photo, e) => {
    e?.preventDefault();
    return this.props
      .onDeletePhoto(photo?.photoId)
      .then(() => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: "delete_review_photo",
          styleName: this.props.product?.styleName,
        });
      })
      .catch(response => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: analytics.ACTION_TYPE.ERROR,
          styleName: this.props.product?.styleName,
          errorMessage: "postDeleteImage failed: " + JSON.stringify(response),
        });
      });
  };

  onSubmit = () => {
    if (!this.validateFields()) {
      return null;
    }
    const { styleName } = this.props.product || {};

    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.REVIEWS,
      action: "submit_review",
      styleName,
    });

    if (this.state.fitProfileUpdate) {
      const data = {};
      [...FIT_INPUT_STANDALONE_CONFIG].forEach(input => {
        data[input.name] = this.state.user[input.stateKey];
      });

      this.props.updateProfile(data, this.props.fetchProfile);
    }

    if (this.props.hasFileSizeError) {
      this.props.clearFileSizeError();
    }

    return this.props
      .onSubmitReview({
        styleName,
        starRating: this.state.rating,
        eventType: this.state.eventType,
        title: this.state.title,
        reviewText: this.state.reviewText,
        fit: this.state.fit,
        sizeWorn: this.state.sizeWorn,
        photos: JSON.stringify(
          this.props.reviewPhotos.map(photo => {
            return {
              photoId: photo.photoId,
              tags: [], // NW [EXPLANATION] 8/5/21: although this is unused, it is required by the Reviews service
            };
          })
        ),
        bustSize: this.state.user.bustSize,
        heightInches: this.state.user.height,
        weightPounds: this.state.user.weight,
        bodyType: this.state.user.bodyType,
        usStandardSize: this.state.user.primarySize,
      })
      .catch(response => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: analytics.ACTION_TYPE.ERROR,
          styleName,
          errorMessage: "ajaxReviewPopupSubmitReview failed: " + JSON.stringify(response),
        });
      });
  };

  renderTextRemainingCharacters(maxCharCount, stateField, threshold) {
    const remainingCharacters = maxCharCount - (stateField?.length || 0);
    if (remainingCharacters <= threshold) {
      return (
        <span className="character-counter-text">
          <span className="character-count universal-small--secondary">{remainingCharacters}</span>{" "}
        </span>
      );
    }
  }

  renderAdditionalInfoForHSReview() {
    const { pendingReview } = this.props;

    if (!this.isHSReviewWithFitFeedback(pendingReview))
      return <p className="universal-medium">Help others out by sharing your experience.</p>;

    return (
      <p className="universal-small">
        We've started your review with the fit feedback you provided. Now, we'd love to hear more! Share your thoughts
        and add a photo to make it even better.
      </p>
    );
  }

  isHSReviewWithFitFeedback = pendingReview => {
    return this.isHSReview(pendingReview) && pendingReview?.fitFeedbackMap;
  };

  isHSReview = pendingReview => {
    return !!pendingReview?.happinessSurveyId;
  };

  renderHeading() {
    return (
      <>
        {!this.props.loading ? (
          <div className="header">
            {this.state.title && this.state.rating ? <h3>Edit your Review</h3> : <h3>Leave a Review</h3>}
            {this.renderAdditionalInfoForHSReview()}
          </div>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <div className="review-popup">
        <div className="header">{this.renderHeading()}</div>

        {this.renderLoadingIndicator()}
        {this.renderReviewUploadContent()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { hasFileSizeError } = state;

  return {
    hasFileSizeError,
  };
};

const mapDispatchToProps = dispatch => ({
  updateProfile: (data, callBack) => {
    dispatch(UserActions.updateProfile(data, callBack));
  },
  fetchProfile: () => {
    dispatch(UserActions.fetchProfile());
  },
  clearFileSizeError: () => {
    dispatch(hasFileSizeError(false));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReviewUploadModal);
