import React from "react";
import AtomButton from "components/source/atoms/atom-button";
import { assetBaseUrls, dateFnsFormats } from "rtr-constants";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { getLocalDate } from "helpers/date-helpers";
import { useSelector } from "react-redux";
import RtrImage from "../shared/rtr-image";

const propTypes = {
  onClose: PropTypes.func,
};
const HomePickupAlreadyScheduled = ({ onClose }) => {
  const pickupDate = useSelector(state => state.upcomingHomePickupDate);

  return (
    <div className="home-pickup-already-scheduled">
      <RtrImage
        className="check"
        alt="Calendar Check Mark"
        src={`${assetBaseUrls.DESIGNER_IMAGES}/111422_Reserve_Pickup_Confirmation_Calendar-Icon.svg`}
      />
      <h4 className="header">
        Your pickup is scheduled for {format(getLocalDate(new Date(pickupDate)), dateFnsFormats.monthDay)}
      </h4>
      <p className="copy">
        Need to adjust your pickup? Tap the link provided in your pickup confirmation email to cancel with UPS, then
        head back here to reschedule.
      </p>
      <AtomButton onClick={onClose} buttonText="Got It" />
    </div>
  );
};

HomePickupAlreadyScheduled.propTypes = propTypes;

export default HomePickupAlreadyScheduled;
