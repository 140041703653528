import React from "react";
import PropTypes from "prop-types";

/*
 * A non-sticky link that's styled as a button with 100% width.
 * 100% width is of course relative to parent container.
 *
 * Note that this uses an <a> element instead of <button>.
 * If you need an onclick function or need to submit a form,
 * check out <AtomFullWidthButton>.
 *
 * By default this has our primary button styling.
 * Pass buttonStyle="btn-secondary" for secondary button styling.
 */

// ...aTagProps is not a prop, it is the "rest of the props that are not buttonStyle or buttonText".
// Component accepts any props that A allows

const propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  buttonStyle: PropTypes.string,
};

const defaultProps = {
  buttonStyle: "btn",
};

export default class AtomFullWidthLinkButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { buttonStyle, buttonText, ...aTagProps } = this.props;

    return (
      <a className={`${buttonStyle} btn-full-width`} {...aTagProps}>
        {buttonText}
      </a>
    );
  }
}

AtomFullWidthLinkButton.propTypes = propTypes;
AtomFullWidthLinkButton.defaultProps = defaultProps;
