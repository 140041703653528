import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import userProblemActions from "actions/user-problems-actions.js";
import ActionLogger from "action-logger";
import Constants from "rtr-constants";

export default class ExchangeSurveyThanks extends React.Component {
  static propTypes = {
    selfServiceExchange: PropTypes.object,
    itemSurveys: PropTypes.array,
    dontCharge: PropTypes.bool,
    dispatch: PropTypes.func,
    group: PropTypes.object,
    currentPage: PropTypes.object,
  };

  isExchangeFree = () => {
    const feedbacks = _.flatten(_.map(this.props.itemSurveys, "surveyFeedbacks"));
    const nonChargeIssue = _.find(feedbacks, feedback => feedback.feedback.includes("QUALITY"));
    return nonChargeIssue || this.props.dontCharge;
  };

  numReplacements = () => {
    const minReplacements = _.size(this.props.itemSurveys);
    return minReplacements > 1 ? minReplacements : 2;
  };

  static get pageName() {
    return "ExchangeSurveyThanks";
  }

  static get baseClass() {
    return "exchange-survey-thanks";
  }

  createBagAndRedirectToGrid = () => {
    const {
      dispatch,
      group,
      selfServiceExchange: { addressId, newBagCutoff },
      itemSurveys,
    } = this.props;

    // get the problem bookings for the current group only
    const groupBookings = group?.bookings?.map(booking => booking.id);
    const problemBookings = itemSurveys
      ?.filter(survey => groupBookings?.includes(survey.bookingId.toString()))
      ?.map(booking => booking.bookingId);

    const newLPS = newBagCutoff || group.bagCutoffTime;

    ActionLogger.inferAction({
      object_type: Constants.analytics.SELF_SERVICE_EXCHANGES,
      action: "summary_complete",
    });

    userProblemActions.createExchangeBag(group, addressId, newLPS, problemBookings)(dispatch);
  };

  renderCopy() {
    // check whether we need to charge them for shipping
    const exchangeIsFree = this.isExchangeFree();
    const numReplacements = this.numReplacements();

    const freeCopy = exchangeIsFree ? "free " : "";
    const shippingCopy = exchangeIsFree
      ? "We’ll cover the shipping too."
      : "All you need to cover is $12.95 shipping + tax.";

    const hedCopy = `Let’s pick your ${freeCopy}replacements!`;
    const dekCopy = `Pick any ${numReplacements} styles. They’re on us.`;

    return (
      <div data-test-id="thanks-copy" className={`${this.constructor.baseClass}__copy`}>
        <h4>{hedCopy}</h4>
        <p>{dekCopy}</p>
        <p>{shippingCopy}</p>
      </div>
    );
  }

  willNotRender() {
    return this.props.currentPage !== ExchangeSurveyThanks.pageName;
  }

  render() {
    if (this.willNotRender()) {
      return null;
    }

    return (
      <div className={`${this.constructor.baseClass} ${this.constructor.baseClass}--flagged`}>
        <div className={`${this.constructor.baseClass}__garment-bag`} />
        {this.renderCopy()}
        <div className={`${this.constructor.baseClass}__cta`}>
          <button className="btn" onClick={this.createBagAndRedirectToGrid}>
            Pick Replacements
          </button>
        </div>
      </div>
    );
  }
}
