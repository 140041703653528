import React from "react";
import { displayModal } from "actions/shared-actions";
import ClosableModal from "components/source/shared/closable-modal";
import PropTypes from "prop-types";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import PDPPhotoReviewThumbnailCarousel from "components/source/pdp/pdp-photo-review-thumbnail-carousel.jsx";
import reviewsActions from "actions/reviews-actions";
import ProductDrawerHelper from "helpers/product-drawer-helper";
import { connect } from "react-redux";
import ActionLogger from "action-logger";
import ReviewerStats from "components/source/pdp/reviewer-stats.jsx";
import RtrImage from "../shared/rtr-image";

export class PDPPhotoReviewModal extends React.Component {
  static propTypes = {
    dismissModal: PropTypes.func,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    openSpecificReviewPhoto: PropTypes.number,
    openToSpecificReviewPhoto: PropTypes.func,
    reviewsWithPhotos: PropTypes.array,
    isUserSpecificReview: PropTypes.bool.isRequired,
    isMobileViewport: PropTypes.bool,
  };

  static defaultProps = {
    reviewsWithPhotos: [],
    openSpecificReviewPhoto: null,
  };

  static modalName = "REVIEW_PHOTOS";

  // Some users upload more then one photo per review
  // multipleImgIndex keeps track of toggling between those photos
  state = {
    multipleImgIndex: 0,
  };

  componentDidUpdate(prevProps) {
    if (!this.isModalOpen(prevProps.displayedModal) && this.isModalOpen(this.props.displayedModal)) {
      this.logAction(this.props.reviewsWithPhotos[0]);
    }
  }

  onDismissModal = () => {
    const { dismissModal, openToSpecificReviewPhoto } = this.props;
    this.changeLargeImg(0);
    openToSpecificReviewPhoto(null);
    dismissModal();
  };

  logAction(review) {
    if (!review) {
      return false;
    }

    const { openSpecificReviewPhoto, reviewsWithPhotos } = this.props;

    const object = {
      object_type: "node",
      cover_photo_id: review.moment?.photoIds?.[0],
      moment_id: review.moment?.reviewId,
      context: openSpecificReviewPhoto ? "cover_photo_carousel" : "featured_review",
      action: "view_moment",
      num_photos: review.moment?.photos?.length,
      num_moments: reviewsWithPhotos?.length,
      source: this.state.multipleImgIndex > 0 ? "click_other_photos" : "click_pdp_moment",
      style_name: review.moment?.styleName,
    };
    ActionLogger.logAction(object);
  }

  isModalOpen(displayedModal) {
    return displayedModal === this.constructor.modalName;
  }

  findIndexOfReviewId = id => {
    if (this.props.isUserSpecificReview) {
      return id ? this.props.reviewsWithPhotos.findIndex(review => String(review.reviewId) === String(id)) : 0;
    }
    return id ? this.props.reviewsWithPhotos.findIndex(review => String(review.moment.reviewId) === String(id)) : 0;
  };

  findReviewIdAtIndex = index => {
    return this.props.isUserSpecificReview
      ? this.props.reviewsWithPhotos[index]?.reviewId
      : this.props.reviewsWithPhotos[index]?.moment?.reviewId;
  };

  renderLargeImg(review) {
    const photos = this.props.isUserSpecificReview ? review.photos : review.moment.photos;
    return (
      <div className="review-image-container">
        <RtrImage
          data-test-id="pdp-photo-review-modal-main-image"
          className="review-image"
          src={photos?.[this.state.multipleImgIndex]?.urls.image}
          alt="customer review"
        />
        <div className="review-image-extras-container">
          {photos.length > 1 && (
            <div className="review-image-extras">
              {photos.map((photo, i) => (
                <button
                  key={i}
                  className="review-image-extra"
                  onClick={() => this.changeLargeImg(i)}
                  data-test-id={`pdp-photo-review-modal-large-image-toggle-${i}`}>
                  <RtrImage
                    src={
                      photo.urls?.imageCroppedThumb ||
                      photo.urls?.imageThumb ||
                      photo.urls?.imageLargeThumb ||
                      photo.urls?.image
                    }
                    alt="customer review thumbnail"
                  />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  changeLargeImg = i => {
    this.setState({
      multipleImgIndex: i,
    });
  };

  moveToReview = i => {
    const { openToSpecificReviewPhoto } = this.props;
    openToSpecificReviewPhoto(i);
    this.changeLargeImg(0);
  };

  renderReview = (review, id = 0) => {
    const reviewData = this.props.isUserSpecificReview ? review : review.moment;
    return (
      <div key={id} className="review-container" data-test-id="pdp-photo-review-modal-review-content">
        {this.renderLargeImg(review)}
        <div className="review">
          <div className={`rating reb-gold-stars-${reviewData.rating}`}></div>
          <div className="accessibility-rating-caption">
            ({Math.ceil((reviewData.rating / 2) * 100) / 100} out of 5 stars)
          </div>
          {reviewData?.caption?.trim()?.length > 2 && (
            <h3 className="review-caption">&quot;{reviewData.caption}&quot;</h3>
          )}
          <ReviewerStats review={review} isUserSpecificReview={this.props.isUserSpecificReview} />
          <p>{reviewData?.content}</p>
        </div>
      </div>
    );
  };

  renderContent() {
    const { reviewsWithPhotos, openSpecificReviewPhoto, isMobileViewport } = this.props;
    const indexOfNextReview = this.findIndexOfReviewId(openSpecificReviewPhoto);

    const moveBackward = () => {
      if (indexOfNextReview - 1 >= 0) this.moveToReview(this.findReviewIdAtIndex(indexOfNextReview - 1));
    };

    const moveForward = () => {
      if (indexOfNextReview + 1 < reviewsWithPhotos.length) {
        this.moveToReview(this.findReviewIdAtIndex(indexOfNextReview + 1));
      }
    };

    if (reviewsWithPhotos.length === 1) {
      return (
        <div>
          {this.renderReview(reviewsWithPhotos[0])}

          <PDPPhotoReviewThumbnailCarousel
            reviewsWithPhotos={reviewsWithPhotos}
            pageSize={5}
            title="How others wore it"
            onImgClick={this.moveToReview}
            className="how-others-wore-it"
            isUserSpecificReview={this.props.isUserSpecificReview}
          />
        </div>
      );
    } else if (reviewsWithPhotos.length > 1) {
      const reviewIndex = openSpecificReviewPhoto ? this.findIndexOfReviewId(openSpecificReviewPhoto) : 0;
      return (
        <div>
          {!isMobileViewport && (
            <button
              onClick={() => moveBackward()}
              className="review-photo-modal__button back"
              data-test-id="pdp-photo-review-modal-back-button">
              <div
                className={`swipeable-carousel__button swipeable-carousel__button--back ${
                  indexOfNextReview - 1 < 0 && "inactive"
                }`}>
                Back
              </div>
            </button>
          )}
          <SwipeableCarousel hideSideButtons={true} pageSize={1} mobilePageSize={1} setIndex={reviewIndex}>
            {reviewsWithPhotos.map(this.renderReview)}
          </SwipeableCarousel>
          <PDPPhotoReviewThumbnailCarousel
            reviewsWithPhotos={reviewsWithPhotos}
            pageSize={5}
            title="How others wore it"
            onImgClick={this.moveToReview}
            className="how-others-wore-it"
            isUserSpecificReview={this.props.isUserSpecificReview}
          />
          {!isMobileViewport && (
            <button
              onClick={() => moveForward()}
              className="review-photo-modal__button forward"
              data-test-id="pdp-photo-review-modal-forward-button">
              <div
                className={`swipeable-carousel__button swipeable-carousel__button--forward ${
                  indexOfNextReview + 1 >= reviewsWithPhotos.length && "inactive"
                }`}>
                Forward
              </div>
            </button>
          )}
        </div>
      );
    }
  }

  render() {
    const { reviewsWithPhotos, displayedModal } = this.props;
    const isOpen = this.isModalOpen(displayedModal);

    if (!reviewsWithPhotos || reviewsWithPhotos.length === 0) {
      return null;
    }

    return (
      <ClosableModal
        onRequestClose={this.onDismissModal}
        isOpen={isOpen}
        optionalClass="review-photo-modal"
        ctaText="Confirm"
        disabled={false}>
        {this.renderContent()}
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  const isUserSpecificReview = Array.isArray(state?.reviews?.moments);
  const reviewsWithPhotos = ProductDrawerHelper.getOnlyReviewsWithPhotos(
    state?.reviews || state?.productDrawer?.reviews,
    isUserSpecificReview
  );

  return {
    isMobileViewport: state?.browser?.isMobileViewport,
    displayedModal: state.displayedModal,
    openSpecificReviewPhoto: state.openSpecificReviewPhoto,
    reviewsWithPhotos,
    isUserSpecificReview: isUserSpecificReview || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dismissModal: () => {
      dispatch(displayModal(false));
    },
    openToSpecificReviewPhoto: i => {
      dispatch(reviewsActions.openSpecificReviewPhoto(i));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PDPPhotoReviewModal);
