export const ACCOUNT_INPUT_CONFIG = [
  {
    label: "First name",
    stateKey: "firstName",
    id: "user-first-name",
    name: "user[first_name]",
    type: "text",
    placeholder: "",
  },
  {
    label: "Last name",
    stateKey: "lastName",
    id: "user-last-name",
    name: "user[last_name]",
    type: "text",
    placeholder: "",
  },
  {
    label: "Date of birth",
    stateKey: "dateOfBirth",
    id: "user-date-of-birth",
    name: "user[date_of_birth]",
    type: "text",
    placeholder: "Birthday",
  },
  {
    label: "Zip code",
    stateKey: "zipCode",
    id: "user-zip-code",
    name: "user[zip_code]",
    type: "text",
    placeholder: "",
  },
  {
    label: "Phone number",
    stateKey: "cellPhoneNumber",
    id: "user-cell-phone-number",
    name: "user[cell_phone_number]",
    type: "text",
    placeholder: "",
  },
];

export const FIT_INPUT_CONFIG = [
  {
    label: "Height",
    stateKey: "height",
    id: "user-height",
    name: "user[height]",
    type: "select",
    optionsKey: "height",
  },
  {
    label: "Weight (lbs.)",
    stateKey: "weight",
    id: "user-weight",
    name: "user[weight]",
    type: "text",
    placeholder: "",
  },
  {
    label: "Bust size",
    stateKey: "bustSize",
    id: "user-bust-size",
    name: "user[bust_size]",
    type: "select",
    optionsKey: "bustSize",
  },
  {
    label: "Body Type",
    stateKey: "bodyType",
    id: "user-body-type",
    name: "user[body_type]",
    type: "select",
    optionsKey: "bodyType",
  },
  {
    label: "Primary dress size",
    stateKey: "primarySize",
    id: "user-profile-primary-size",
    name: "user[profile][primary_size]",
    type: "select",
    optionsKey: "dressSize",
  },
  {
    label: "Backup dress size",
    stateKey: "backupSize",
    id: "user-profile-backup-size",
    name: "user[profile][backup_size]",
    type: "select",
    optionsKey: "dressSize",
  },
  {
    label: "Typical jean size",
    stateKey: "pantSize",
    id: "user-profile-pant-size",
    name: "user[profile][pant_size]",
    type: "select",
    optionsKey: "pantSize",
  },
];

const formatInchesHeight = inches => {
  return Math.floor(inches / 12) + "' " + (inches % 12) + "''";
};

const formatBustSize = bands => {
  const size = ["AA", "A", "B", "C", "D", "DD", "DDD/E", "F", "G", "H", "I", "J"];
  const finalArray = [];
  bands.forEach(band => {
    size.forEach(size => {
      finalArray.push(`${band}${size}`);
    });
  });

  return finalArray;
};

export const FIT_INPUT_STANDALONE_CONFIG = [
  {
    label: "Date of Birth*",
    stateKey: "dateOfBirth",
    id: "user-date-of-birth",
    name: "user[date_of_birth]",
    type: "text",
    required: true,
    placeholder: "MM/DD/YYYY",
  },
  {
    label: "Height*",
    stateKey: "height",
    id: "user-height",
    name: "user[height]",
    type: "select",
    optionsKey: "height",
    options: [...Array(78 - 54 + 1).keys()].map(x => [x + 54, formatInchesHeight(x + 54)]),
    required: true,
  },
  {
    label: "Primary Size*",
    stateKey: "primarySize",
    id: "user-profile-primary-size",
    name: "user[profile][primary_size]",
    type: "select",
    optionsKey: "dressSize",
    options: [...Array(11 + 1).keys()].map(x => `${(x + 0) * 2}`),
    required: true,
  },
  {
    label: "Body Type*",
    stateKey: "bodyType",
    id: "user-body-type",
    name: "user[body_type]",
    type: "select",
    optionsKey: "bodyType",
    options: ["Apple", "Athletic", "Full Bust", "Hourglass", "Pear", "Petite", "Straight & narrow"],
    required: true,
  },
  {
    label: "Bust Size",
    stateKey: "bustSize",
    id: "user-bust-size",
    name: "user[bust_size]",
    type: "select",
    optionsKey: "bustSize",
    options: formatBustSize([...Array((48 - 28 + 2) / 2).keys()].map(x => x * 2 + 28)),
    required: false,
  },
  {
    label: "Weight (lbs)",
    stateKey: "weight",
    id: "user-weight",
    name: "user[weight]",
    type: "text",
    placeholder: "",
    required: false,
  },
];
