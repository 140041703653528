import PropTypes from "prop-types";
import classNames from "classnames";
import LeaveOnDoorStepIcon from "images/icon-doorstep-SF.svg";
import LiveSwapHandOff from "images/icon-handoff-SF.svg";
import DirectHandoff from "images/icon-direct-handoff-SF.svg";
import { HomePickupMethod } from "rtr-constants";
import AdditionalInstructionsTextArea from "./home-pickup-additional-instructions";

const propTypes = {
  isLiveSwap: PropTypes.bool,
  isReworkedScheduler: PropTypes.bool,
  pickupMethod: PropTypes.string,
  setPickupMethod: PropTypes.func.isRequired,
  setSpecialInstructions: PropTypes.func.isRequired,
  specialInstructions: PropTypes.string,
};

const MethodPicker = ({
  pickupMethod,
  isLiveSwap,
  isReworkedScheduler,
  setSpecialInstructions,
  specialInstructions,
  setPickupMethod,
}) => {
  const pickupMethodButtonClass = givenPickupMethod =>
    classNames("method-button", {
      active: pickupMethod === givenPickupMethod,
    });
  const directHandoffIcon = isLiveSwap ? <LiveSwapHandOff /> : <DirectHandoff />;

  return (
    <div className="pickup-method">
      {isReworkedScheduler && (
        <>
          <h2 className="section-title universal-medium">Pickup Method</h2>
          <h3 className="section-sub-title universal-xsmall">Select One</h3>
        </>
      )}
      <button
        className={pickupMethodButtonClass(HomePickupMethod.LEAVE_ON_DOORSTEP)}
        onClick={() => setPickupMethod(HomePickupMethod.LEAVE_ON_DOORSTEP)}>
        <div className="method-icon">
          <LeaveOnDoorStepIcon />
        </div>
        <div>
          <span className="method-title universal-small--semibold">Contactless</span>
          <span className="method-copy">
            {isReworkedScheduler
              ? "Put returns in an accessible spot "
              : "I&rsquo;ll put my returns in an accessible pickup spot"}
          </span>
        </div>
      </button>
      <AdditionalInstructionsTextArea
        isOpen={pickupMethod === HomePickupMethod.LEAVE_ON_DOORSTEP}
        setSpecialInstructions={setSpecialInstructions}
        specialInstructions={specialInstructions}
      />
      <button
        className={pickupMethodButtonClass(HomePickupMethod.DIRECT_HANDOFF)}
        onClick={() => setPickupMethod(HomePickupMethod.DIRECT_HANDOFF)}>
        <div className="method-icon">{directHandoffIcon}</div>
        <div>
          <span className="method-title universal-small--semibold">Direct Handoff</span>
          <span className="method-copy universal-small">
            {isReworkedScheduler ? "Hand returns to the courier" : "I&rsquo;ll hand my returns to the courier"}
          </span>
        </div>
      </button>
    </div>
  );
};

MethodPicker.propTypes = propTypes;
export default MethodPicker;
