import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";

class Apology extends React.Component {
  static propTypes = {
    goToPage: PropTypes.func.isRequired,
    nextPage: PropTypes.string.isRequired,
    currentPage: PropTypes.string.isRequired,
  };

  static pageName = "Apology";

  static defaultProps = {
    handleClickNext: _.noop,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // NW [EXPLANATION] 4/17/19: the user has 4 seconds to click Next, otherwise we move them to the next page.
    if (_.isEqual(nextProps.currentPage, this.constructor.pageName)) {
      _.delay(this.handleClickNext, 4000);
    }
  }

  handleClickNext = () => {
    if (this.willRender()) {
      this.props.goToPage(this.props.nextPage);
    }
  };

  willRender = () => {
    return _.isEqual(this.props.currentPage, this.constructor.pageName);
  };

  render() {
    if (!this.willRender()) {
      return null;
    }

    return (
      <div className="apology">
        <div className="apology__copy h4">
          We’re so sorry to hear this. Your feedback is extremely important to us and helps us make your experience
          better.
        </div>

        <div className="apology__next">
          <button onClick={this.handleClickNext} className="btn">
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default Apology;
