import React from "react";
import PropTypes from "prop-types";

class AtomPanelCheckbox extends React.Component {
  static propTypes = {
    displayText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  handleClick = () => {
    this.props.onClick(this.props.value);
  };

  renderCheck = () => {
    if (this.props.checked) {
      return <div className="atom-panel-checkbox__check" />;
    }
  };

  renderClassNames = () => {
    var baseClass = "title atom-panel-checkbox",
      modifierClass = "atom-panel-checkbox--checked";

    if (this.props.checked) {
      return [baseClass, modifierClass].join(" ");
    }

    return baseClass;
  };

  render() {
    return (
      <div onClick={this.handleClick} className={this.renderClassNames()}>
        <span className="atom-panel-checkbox__display-text">{this.props.displayText}</span>
        {this.renderCheck()}
      </div>
    );
  }
}

export default AtomPanelCheckbox;
