import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { displayModal, loadCMSContent } from "actions/shared-actions";
import { returnPolicyModalCMSPath } from "rtr-constants";
import ClosableModal from "components/source/shared/closable-modal";
import AtomFullWidthLinkButton from "components/source/atoms/atom-full-width-link-button";

const modalName = "keep-it-forever-returns-modal";

class KeepItForeverReturnsModal extends React.Component {
  static modalName = modalName;

  static propTypes = {
    dismissModal: PropTypes.func,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    fetchCMSContent: PropTypes.func,
    isMobileViewport: PropTypes.bool,
    returnPolicyModal: PropTypes.shape({
      body: PropTypes.string,
      cta_title: PropTypes.string,
      cta_url: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  componentDidMount() {
    const { fetchCMSContent } = this.props;
    fetchCMSContent();
  }

  onCloseModal = () => {
    this.props.dismissModal();
  };

  render() {
    const baseClass = "kif-returns-modal";
    const { displayedModal, isMobileViewport, returnPolicyModal } = this.props;

    const additionalStyles = {
      content: {
        bottom: 0,
      },
    };

    return (
      <ClosableModal
        isCloseHidden={false}
        isOpen={displayedModal === modalName}
        onRequestClose={this.onCloseModal}
        additionalStyles={isMobileViewport ? additionalStyles : {}}>
        <div className={`${baseClass}`}>
          <div className={`${baseClass}__body`}>
            <h3 className={`${baseClass}__heading`}>{returnPolicyModal.title}</h3>
            <p className={`${baseClass}__description proxima-body-copy`}>{returnPolicyModal.body}</p>
          </div>
          <div className={`${baseClass}__cta`}>
            <AtomFullWidthLinkButton
              buttonText={returnPolicyModal.cta_title}
              rel="noopener noreferrer"
              target="_blank"
              href={returnPolicyModal.cta_url}
            />
          </div>
        </div>
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  const { browser = {}, cmsContent = {} } = state;
  const { isMobileViewport = false } = browser;
  const { returnPolicyModal } = cmsContent;

  return {
    isMobileViewport,
    displayedModal: state.displayedModal,
    returnPolicyModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dismissModal: () => {
      dispatch(displayModal(false));
    },
    fetchCMSContent: () => {
      const cmsPath = returnPolicyModalCMSPath.CMS_PATH;
      const stateKey = returnPolicyModalCMSPath.STATE_KEY;

      dispatch(loadCMSContent(cmsPath, stateKey));
    },
  };
};

KeepItForeverReturnsModal.defaultProps = {
  returnPolicyModal: {
    title: "Return Policy",
    body:
      "If you purchased this item without renting it first and are not completely satisfied, you may return this item for a refund within 14 days of receipt. In order to ensure the highest cleanliness standards, returns are subject to a - $5.95 cleaning fee per item.",
    cta_title: "Details",
    cta_url: "/returnpolicy",
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(KeepItForeverReturnsModal);
