import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import CapsuleTab from "components/source/atoms/atom-capsule-tab";

class CapsuleSelect extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    typeAsText: PropTypes.string.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    values: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  currentlySelectedValue = () => {
    return _.find(this.props.values, function (value) {
      return value.selected;
    });
  };

  handleOpen = () => {
    this.props.onOpen(this.props.type);
  };

  handleClear = () => {
    var selected = this.currentlySelectedValue();

    if (selected) {
      this.props.onClear(selected.value, this.props.type);
    } else {
      this.props.onClose();
    }
  };

  handleClick = () => {
    if (this.props.isOpen) {
      this.handleClear();
    } else {
      this.handleOpen();
    }
  };

  handleClickTab = value => {
    this.props.onSelect(value, this.props.type);
    this.props.onClose();
  };

  renderTypeAsText = () => {
    return <div className="capsule-select__key title">{this.props.typeAsText}</div>;
  };

  renderSelectedValue = () => {
    var selected = this.currentlySelectedValue();

    if (selected) {
      return <div className="capsule-select__value accent title">{selected.text}</div>;
    }
  };

  renderCapsuleBaseClass = () => {
    var selectionMade,
      baseClass = "capsule-select__base",
      selectedClass = "capsule-select__base--selected",
      openClass = "capsule-select__base--open";

    selectionMade = _.contains(_.pluck(this.props.values, "selected"), true);

    if (selectionMade) {
      return [baseClass, selectedClass].join(" ");
    }

    if (this.props.isOpen) {
      return [baseClass, selectedClass, openClass].join(" ");
    }

    return baseClass;
  };

  renderOptionsClass = () => {
    var baseClass = "capsule-select__options",
      modifierClass = "capsule-select__options--open";

    if (this.props.isOpen) {
      return [baseClass, modifierClass].join(" ");
    }

    return baseClass;
  };

  render() {
    return (
      <div className="capsule-select">
        <div className={this.renderCapsuleBaseClass()} onClick={this.handleClick}>
          {this.renderTypeAsText()}
          {this.renderSelectedValue()}
        </div>
        <div className={this.renderOptionsClass()}>
          {_.map(
            this.props.values,
            function (value) {
              return <CapsuleTab onClick={this.handleClickTab} value={value} />;
            },
            this
          )}
        </div>
      </div>
    );
  }
}

export default CapsuleSelect;
