import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import InputsStyles from "./inputs.module.scss";

const propTypes = {
  additionalClassName: PropTypes.object,
  ctaElement: PropTypes.object,
  headerText: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  subHeaderText: PropTypes.string,
};

function InputsLabel({ additionalClassName, ctaElement, headerText, isRequired, subHeaderText }) {
  const containerClassName = classNames(InputsStyles["label-container"], {
    [additionalClassName?.container]: additionalClassName?.container,
  });
  const headerTextClassName = classNames(InputsStyles["header-label"], {
    [additionalClassName?.header]: additionalClassName?.header,
  });
  const subHeaderTextClassName = classNames(InputsStyles["sub-header-label"], {
    [additionalClassName?.subHeader]: additionalClassName?.subHeader,
  });

  return (
    <div className={containerClassName} data-test-id="input-label-container">
      <div className={InputsStyles["header-label-container"]}>
        <p className={headerTextClassName}>
          {headerText}
          {isRequired ? "*" : ""}
        </p>
        {!!ctaElement && ctaElement}
      </div>
      {subHeaderText && <div className={subHeaderTextClassName}>{subHeaderText}</div>}
    </div>
  );
}
InputsLabel.propTypes = propTypes;
export default InputsLabel;
