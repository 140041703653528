import React from "react";
import PropTypes from "prop-types";

class CapsuleTab extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  };

  handleClick = () => {
    this.props.onClick(this.props.value.value);
  };

  render() {
    return (
      <div className="capsule-select__input title" onClick={this.handleClick}>
        {this.props.value.text}
      </div>
    );
  }
}

export default CapsuleTab;
