import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Star from "images/star.svg";
import { childrenPropType } from "components/propTypes";

export class StarRatingLabel extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: childrenPropType.isRequired,
  };

  filledStar = ratingNumber => {
    return this.props.value <= ratingNumber;
  };

  render() {
    const ratingNumber = parseInt(this.props.rating);

    const labelClass = classNames("star-rating-label", {
      star_full: this.filledStar(ratingNumber),
    });

    return (
      <label htmlFor={"starRating-" + this.props.value} className={labelClass} data-test-id="star-rating-label">
        <span className="sr-only">
          {typeof ratingNumber === "number" && ratingNumber > 0 ? ratingNumber / 2 : "rate"} stars
        </span>
        {this.props.children}
        <Star />
      </label>
    );
  }
}

export default StarRatingLabel;
