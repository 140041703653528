import { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import AuthActions from "actions/auth-actions";
import { initializeGoogleOneTap } from "helpers/auth-helpers";
import { appendScript } from "helpers/dom-helpers";

export default function GoogleOneTap() {
  const dispatch = useDispatch();
  const { clientId } = useSelector(state => ({ clientId: state.oneTap?.clientId }), shallowEqual);
  const createGoogleOneTapAuthenticationAttempt = response =>
    dispatch(AuthActions.createGoogleOneTapAuthenticationAttempt(response));

  useEffect(() => {
    let scriptTag = null;
    // @see {https://developers.google.com/identity/gsi/web/reference/js-reference?hl=en#onGoogleLibraryLoad}
    window.onGoogleLibraryLoad = () => initializeGoogleOneTap(clientId, createGoogleOneTapAuthenticationAttempt);
    scriptTag = appendScript({
      src: "https://accounts.google.com/gsi/client",
      async: true,
      defer: true,
    });

    return () => {
      if (scriptTag) {
        document.body.removeChild(scriptTag);
      }
    };
  }, []);

  return null;
}
