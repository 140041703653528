import React from "react";
import PropTypes from "prop-types";
import AtomButton from "components/source/atoms/atom-button";
import HomePickUpOrderReturnCard from "./home-pickup-order-return-card";
import { HomePickupOrderType } from "rtr-constants";

const propTypes = {
  onSelectedOrder: PropTypes.func,
  orders: PropTypes.arrayOf(PropTypes.object),
  returnItemsCTA: PropTypes.func.isRequired,
  selectedOrder: PropTypes.object,
};

const HomePickUpOrderReturnContainer = ({ onSelectedOrder, orders, returnItemsCTA, selectedOrder }) => {
  const [disableCTA, setDisableCTA] = React.useState(false);

  // disable CTA buton
  React.useEffect(() => {
    setDisableCTA(selectedOrder?.id ? false : true);
  }, [selectedOrder]);

  const multiOrderFlow = orders?.length > 1;

  const renderSubHeaderText = () => {
    if (orders?.length === 1 && orders[0].pickupOrderType === HomePickupOrderType.RESERVE) {
      return "If your order came in multiple packages, feel free to pack all the items in one garment bag.";
    } else if (orders?.length > 1) {
      return `Pickups for separate orders must be\nscheduled one at a time.`; // inserting line break instead of changing width of sub header component
    } else {
      return "";
    }
  };

  const subHeaderText = renderSubHeaderText();

  return (
    <div className="home-pickup-order-return-container">
      <div className="copy">
        <h4 className="header">
          {multiOrderFlow ? "Which items are you returning?" : "Here's what we'll \n be picking up"}
        </h4>
        {subHeaderText && <p className="sub-header">{subHeaderText}</p>}
      </div>
      {orders?.length > 0 && (
        <div className="order-radio-container">
          <div className="order-radio">
            {multiOrderFlow ? (
              <React.Fragment>
                <span className="radio-header">Select one</span>
                {orders.map(order => (
                  <React.Fragment key={order.id}>
                    <div className="separator" />
                    <HomePickUpOrderReturnCard
                      order={order}
                      onSelectedOrder={onSelectedOrder}
                      checked={selectedOrder === order}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              <HomePickUpOrderReturnCard order={orders[0]} disabled />
            )}
          </div>
        </div>
      )}
      <div className="footer">
        <AtomButton
          onClick={e => {
            e.preventDefault();
            returnItemsCTA();
          }}
          disabled={disableCTA}
          buttonText="Next"
        />
      </div>
    </div>
  );
};

HomePickUpOrderReturnContainer.propTypes = propTypes;

export default HomePickUpOrderReturnContainer;
