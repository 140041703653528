import React from "react";
import { happinessSurveyPropType, itemSurveyPropType } from "components/propTypes";
import { status } from "constants/happiness-survey-values";
import ProductImage from "components/source/shared/product-image";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageOrientation,
  ProductImageSize,
} from "helpers/product-image-helpers";
import ProductImagePath from "helpers/product-image-path";
import SurveyControlsStyles from "./happiness-survey.module.scss";

const propTypes = {
  currentSurvey: itemSurveyPropType.isRequired,
  happinessSurvey: happinessSurveyPropType.isRequired,
};

const SurveyItemBanner = ({ currentSurvey, happinessSurvey }) => {
  const { product, size } = currentSurvey;
  const fallBackThumbnailImgSrc = gridItem =>
    gridItem?.images?.[ProductImageOrientation.NO_MODEL.path]
      ? ProductImagePath + gridItem?.images[ProductImageOrientation.NO_MODEL.path]?.[ProductImageSize.x480.path]
      : ProductImagePath + gridItem?.images?.[ProductImageOrientation.FRONT.path]?.[ProductImageSize.x480.path];

  const remainingSurveys = happinessSurvey?.itemSurveys?.filter(s => s.status === status.INITIALIZED) || [];
  const totalNumberOfSurveys = happinessSurvey?.itemSurveys?.length || 0;

  return (
    <div className={SurveyControlsStyles["survey-item-banner__container"]} data-test-id="happiness-survey-item-banner">
      <ProductImage
        className={SurveyControlsStyles["survey-item-banner__thumbnail"]}
        altText={getProductImageAltText(product?.displayName, product?.designer.displayName)}
        imageGroup={getProductImageUrlsByOrientation(product?.images, [ProductImageOrientation.NO_MODEL.path])}
        imageSize={ProductImageSize.x480}
        fallbackSrc={fallBackThumbnailImgSrc()}
      />
      <div className={SurveyControlsStyles["survey-item-banner__content"]}>
        <div>
          <p className={SurveyControlsStyles["label"]}>{product?.designer.displayName}</p>
          <p className={SurveyControlsStyles["value"]}>{product?.displayName}</p>
        </div>
        <div>
          <p className={SurveyControlsStyles["label"]}>Size Worn</p>
          <p className={SurveyControlsStyles["value"]}>{size}</p>
        </div>
        <p className={SurveyControlsStyles["eyebrow"]}>{`Item ${
          totalNumberOfSurveys - remainingSurveys.length + 1
        } of ${totalNumberOfSurveys}`}</p>
      </div>
    </div>
  );
};

SurveyItemBanner.propTypes = propTypes;
export default SurveyItemBanner;
