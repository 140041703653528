import PropTypes from "prop-types";
import classNames from "classnames";
import HappinessSurveyGridItem from "./happiness-survey-grid-item";

import InputsStyles from "./inputs.module.scss";

const propTypes = {
  itemSurveys: PropTypes.array,
  selectedItems: PropTypes.array,
  onClickItem: PropTypes.func,
  isSelectNoneChecked: PropTypes.bool,
  isHappinessSurveySkipFlagEnabled: PropTypes.bool,
};

const HappinessSurveyProductGrid = ({
  itemSurveys,
  selectedItems = [],
  onClickItem = () => {},
  isSelectNoneChecked,
  isHappinessSurveySkipFlagEnabled,
}) => {
  if (!Array.isArray(itemSurveys) || itemSurveys.length === 0) {
    return null;
  }

  const handleClickItem = bookingId => {
    onClickItem(bookingId);
  };

  //if HS skip flag is enabled, we need remove the margin at the top of the product grid
  const doubleCheckboxClass = isHappinessSurveySkipFlagEnabled
    ? InputsStyles["product-grid-double-checkbox"]
    : InputsStyles["product-grid"];

  const gridClassNames = classNames(doubleCheckboxClass, {
    [InputsStyles["smaller-grid"]]: itemSurveys.length > 6,
  });

  return (
    <div className={gridClassNames} data-test-id="happiness-survey-product-grid">
      {itemSurveys.map(item => (
        <HappinessSurveyGridItem
          productData={item.product}
          bookingId={item.bookingId}
          key={item.bookingId}
          onClick={handleClickItem}
          isSelected={selectedItems.includes(item.bookingId)}
          disabled={isSelectNoneChecked}
        />
      ))}
    </div>
  );
};

HappinessSurveyProductGrid.propTypes = propTypes;
export default HappinessSurveyProductGrid;
