import React from "react";
import PropTypes from "prop-types";
import ProgressImage from "./progress-image";

class ProgressImages extends React.Component {
  static propTypes = {
    altImageSource: PropTypes.string,
    willShowProgressImages: PropTypes.bool.isRequired,
    itemSurveys: PropTypes.array.isRequired,
    currentSurvey: PropTypes.object.isRequired,
  };

  static defaultProps = {
    currentSurvey: {},
  };

  willRender = () => {
    return this.props.willShowProgressImages;
  };

  render() {
    if (!this.willRender()) {
      return null;
    }
    const surveys = this.props?.altImageSource ?? this.props.itemSurveys;

    return (
      <div className="progress-images" data-test-id="progress-images">
        <div className="progress-images__container">
          {surveys.map(survey => {
            return <ProgressImage key={survey?.id} survey={survey} currentSurvey={this.props.currentSurvey} />;
          }, this)}
        </div>
      </div>
    );
  }
}

export default ProgressImages;
