import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      formId: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
  groupName: PropTypes.string,
  selected: PropTypes.bool,
};

export default class AtomSelectionButton extends React.Component {
  state = {
    selected: this.props.selected,
  };

  onChange = e => {
    const { onChange } = this.props;
    this.setState({ selected: e.target.value });

    if (onChange && typeof onChange === "function") {
      onChange(e);
    }
  };

  render() {
    const { groupName, selections = [] } = this.props;

    if (Array.isArray(selections) && selections.length > 0) {
      return (
        <div className="atom-selection-button">
          {selections.map((selection, i) => (
            <div className="selection-button" key={i}>
              <input
                type="radio"
                id={selection.formId}
                name={groupName}
                value={selection.value}
                checked={selection.value === this.state.selected}
                onChange={e => this.onChange(e)}
              />
              <label htmlFor={selection.formId}>{selection.label}</label>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}

AtomSelectionButton.propTypes = propTypes;
