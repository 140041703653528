import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import HappinessSurveyHelpers from "helpers/happiness-survey-helpers";
import { getProductImageAltText, ProductImageSize, selectMainImageGroup } from "helpers/product-image-helpers";
import ProductImage from "components/source/shared/product-image";

class ProgressImage extends React.Component {
  static propTypes = {
    survey: PropTypes.object.isRequired,
    currentSurvey: PropTypes.object.isRequired,
  };

  renderClasses = () => {
    const currentBookingId = this.props?.currentSurvey?.bookingId;
    const bookingId = this.props.survey.bookingId;
    const status = this.props.survey.status;
    const baseClass = "progress-images__image-container";
    const activeModifier = "progress-images__image-container--active";
    const completedModifier = "progress-images__image-container--completed";

    if (status === "COMPLETE") {
      return classNames(baseClass, completedModifier);
    }

    if (currentBookingId === bookingId) {
      return classNames(baseClass, activeModifier);
    }

    return baseClass;
  };

  renderCheck = () => {
    if (this.props.survey.status === "COMPLETE") {
      return (
        <div className="progress-images__image-overlay">
          <div className="progress-images__image-overlay-check" />
        </div>
      );
    }
  };

  render() {
    const { survey } = this.props;
    const product = survey?.product;
    return (
      <div className={this.renderClasses()}>
        {this.renderCheck()}
        <ProductImage
          altText={`Survey progress image - ${getProductImageAltText(
            product?.displayName,
            product?.designer?.displayName
          )}`}
          imageGroup={selectMainImageGroup(product?.images || {})}
          imageSize={ProductImageSize.x70}
          fallbackSrc={HappinessSurveyHelpers.getImageUrl(survey, ProductImageSize.x70.path)}
        />
      </div>
    );
  }
}

export default ProgressImage;
