import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionLogger from "action-logger";
import classNames from "classnames";
import { analytics } from "rtr-constants";
import { productPropType } from "components/propTypes";

import RtrImage from "components/source/shared/rtr-image";
import IconPhoto from "images/sprites/reb/svg/icon_photo.svg";

import { hasFileSizeError as hasFileSizeErrorAction } from "actions/shared-actions";
import reviewPhotoActions from "actions/review-photo-actions";

import InputStyles from "./inputs.module.scss";

const propTypes = {
  product: productPropType,
};

const maxPhotosLength = 3;
function PhotoUploaderInput({ product }) {
  const dispatch = useDispatch();
  const [rotatedPhotos, setRotatedPhotos] = React.useState({});
  const photoUploader = React.createRef();
  const hasFileSizeError = useSelector(state => state.hasFileSizeError) || false;
  const loadingPhotos = useSelector(state => state.loadingPhotos) || false;
  const loadingRotatedPhoto = useSelector(state => state.loadingRotatedPhoto) || false;
  const reviewPhotoError = useSelector(state => state.reviewPhotoError) || false;
  const reviewPhotos = useSelector(state => state.reviewPhotos) || [];

  const uploadPhotoHandler = e => {
    const photoCount = reviewPhotos ? reviewPhotos.length : 0;
    const remainingPhotoCount = Math.max(0, maxPhotosLength - photoCount);
    e.preventDefault();
    dispatch(reviewPhotoActions.updateReviewPhotoFailure(null));
    dispatch(hasFileSizeErrorAction(false));

    const processPhoto = photo => {
      const image = new Image();
      const imageLoad = () => {
        ActionLogger.logAction({
          object_type: "review_your_item",
          action: "add_photo",
        });
        dispatch(reviewPhotoActions.submitReviewPhoto(product.id, photo));
      };

      image.onload = imageLoad;
      image.src = photo.data;
    };

    const readPhoto = photo => {
      const reader = new FileReader();
      const photoSizeLimitInMB = 5242800;
      const readerLoadEnd = () => {
        if (photo.size < photoSizeLimitInMB) {
          processPhoto({ data: reader.result, filename: photo.name, type: photo.type });
        } else {
          dispatch(hasFileSizeErrorAction(true));
        }
      };

      reader.onloadend = readerLoadEnd;
      reader.readAsDataURL(photo);
    };

    // for all given files in e.target.files
    // take the first $remainingPhotoCount photos
    // and apply readPhoto
    for (let x = 0; x < remainingPhotoCount; x++) {
      if (e.target.files[x]) {
        readPhoto(e.target.files[x]);
      }
    }

    photoUploader.current.value = "";
  };

  const deletePhotoHandler = (photo, e) => {
    e?.preventDefault();
    dispatch(reviewPhotoActions.setLoadingPhotos(true));
    dispatch(reviewPhotoActions.updateReviewPhotoFailure(null));

    const onSuccess = () => {
      dispatch(reviewPhotoActions.setLoadingPhotos(false));
      ActionLogger.logAction({
        object_type: analytics.OBJECT_TYPE.REVIEWS,
        action: "delete_review_photo",
        styleName: product?.styleName,
      });
    };

    const onError = response => {
      dispatch(reviewPhotoActions.setLoadingPhotos(false));
      ActionLogger.logAction({
        object_type: analytics.OBJECT_TYPE.REVIEWS,
        action: analytics.ACTION_TYPE.ERROR,
        styleName: product?.styleName,
        errorMessage: "postDeleteImage failed: " + JSON.stringify(response),
      });
    };
    return dispatch(reviewPhotoActions.deleteReviewPhoto(photo.photoId, onSuccess, onError));
  };

  const onRotatePhoto = (photoId, e) => {
    e?.preventDefault();
    dispatch(reviewPhotoActions.rotateReviewPhoto(photoId));

    setRotatedPhotos(oldRotatedPhotos => {
      // NW [EXPLANATION] 8/4/21: thumbnails are not re-generated for photos that are successfully rotated. so we are using component state and css for visual feedback
      const currentRotation = oldRotatedPhotos[photoId];
      const newRotation = currentRotation ? (currentRotation + 90) % 360 : 90;
      return {
        ...oldRotatedPhotos,
        [`${photoId}`]: newRotation,
      };
    });
  };

  const renderAddPhotoButton = () => {
    return (
      <label
        htmlFor="photo-uploader__input"
        className={InputStyles["photo-uploader-button-container"]}
        data-test-id="photo-uploader__input_label">
        <IconPhoto />
      </label>
    );
  };

  return (
    <div className={InputStyles["photo-uploader-container"]} data-test-id="photo-uploader">
      <input
        onChange={uploadPhotoHandler}
        type="file"
        name="photos"
        ref={photoUploader}
        id="photo-uploader__input"
        className={InputStyles["photo-uploader-input"]}
        accept="image/*"
        multiple
        data-test-id="photo-uploader__input"
      />
      {(reviewPhotos?.length || loadingPhotos) && (
        <div
          className={`${InputStyles["added-photos-container"]} ${InputStyles["hidden"]}`}
          data-test-id="added-photos-container">
          {reviewPhotos?.length > 0 &&
            reviewPhotos.map(photo => {
              const loading = loadingRotatedPhoto === photo.photoId;

              const imageClassName = classNames(InputStyles["photo-image"], {
                [InputStyles["loading"]]: loading,
                [InputStyles[`rotate-${rotatedPhotos[photo.photoId]}`]]: rotatedPhotos[photo.photoId],
              });

              return (
                <div className={InputStyles["photo-image-container"]} key={"photo-" + photo.photoId}>
                  <button
                    type="button"
                    className={[InputStyles["rotate-button"]]}
                    onClick={e => onRotatePhoto(photo.photoId, e)}
                    data-test-id={`rotate-button-${photo.photoId}`}>
                    ↻
                  </button>
                  <div className={imageClassName} data-test-id={`image-wrapper-${photo.photoId}`}>
                    <RtrImage
                      src={photo.photoUrl}
                      alt={"review-photo-" + photo.photoId}
                      key={"photo-img-" + photo.photoId}
                    />
                  </div>
                  <button
                    type="button"
                    className={[InputStyles["remove-button"]]}
                    data-test-id={`back-button-${photo.photoId}`}
                    onClick={e => deletePhotoHandler(photo, e)}>
                    ✕
                  </button>
                </div>
              );
            })}
          {loadingPhotos && (
            <div className={InputStyles["loading-thumbnail"]} data-test-id="photo-uploader-loading-thumbnail">
              <RtrImage src="//sf-p.rtrcdn.com/images/loading.gif" />
            </div>
          )}
        </div>
      )}
      <div>
        {reviewPhotos?.length < maxPhotosLength && renderAddPhotoButton()}
        <span className={InputStyles["sub-header-label"]}>Max. {maxPhotosLength} images</span>
      </div>
      {(hasFileSizeError || reviewPhotoError) && (
        <div className={[InputStyles["file-size-error-container"]]}>
          {hasFileSizeError && (
            <>
              <p className={[InputStyles["heading"]]}>Your photo is too big</p>
              <p className={[InputStyles["subtext"]]}>
                Upload a new photo that does not exceed <br />
                the 5MB limit.
              </p>
            </>
          )}
          {reviewPhotoError && <div className={InputStyles["subtext"]}>{reviewPhotoError}</div>}
        </div>
      )}
    </div>
  );
}
PhotoUploaderInput.propTypes = propTypes;
export default PhotoUploaderInput;
