export function happinessSurveyContainsFeedback(currentHappinessSurvey, values) {
  if (!values || !currentHappinessSurvey) {
    return;
  }

  const feedbackValues = Array.isArray(values) ? values : [values];

  return (currentHappinessSurvey?.surveyFeedbacks ?? []).some(feedbackItems => {
    return feedbackValues.includes(feedbackItems.feedback);
  });
}
