import React from "react";
import PropTypes from "prop-types";
import SurveyControlsStyles from "../happiness-survey.module.scss";

const propTypes = {
  onClickBack: PropTypes.func,
  onClickSkip: PropTypes.func,
};

const SurveyControls = ({ onClickBack, onClickSkip }) => {
  return (
    <div className={SurveyControlsStyles["survey-controls__container"]}>
      {typeof onClickBack === "function" && (
        <button
          onClick={onClickBack}
          className={SurveyControlsStyles["survey-controls__back"]}
          data-test-id="survey-controls-back">
          <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.44531 15.3999L1.44531 8.03564L9.44532 0.999903"
              stroke="#333333"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
      <h4 className={SurveyControlsStyles["survey-controls__header"]}>Rate your items</h4>
      {typeof onClickSkip === "function" && (
        <button
          onClick={onClickSkip}
          className={SurveyControlsStyles["survey-controls__skip"]}
          data-test-id="survey-controls-skip">
          Skip
        </button>
      )}
    </div>
  );
};

SurveyControls.propTypes = propTypes;
export default SurveyControls;
