import React from "react";
import { PropTypes } from "prop-types";

const UserProfileFormSelect = ({ config, value, onChange, options, error }) => {
  const renderOption = option => {
    // convert option to [value, label] if not an array
    const arrayOption = Array.isArray(option) ? option : [option, option];
    // use replace instead of dangerouslySetInnerHTML on label to avoid XSS attack
    arrayOption[1] = arrayOption[1].replace(`&apos;`, `'`).replace(`&quot;`, `"`);

    return (
      <option key={arrayOption[0]} value={arrayOption[0]}>
        {arrayOption[1]}
      </option>
    );
  };

  return (
    <div className={`input-wrapper item ${error ? "error" : ""}`} data-heap-redact-attributes="value">
      <label htmlFor={config.id}>{config.label}</label>
      <select
        id={config.id}
        data-test-id={config.id}
        name={config.name}
        type="select"
        value={value}
        onChange={onChange}
        data-heap-redact-text="true">
        {options.map(option => renderOption(option))}
      </select>
    </div>
  );
};

UserProfileFormSelect.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default UserProfileFormSelect;
