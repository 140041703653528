import React from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { pages, status, heapEventScreens } from "constants/happiness-survey-values";
import { happinessSurveyPropType, membershipStatePropType } from "components/propTypes";

import { fetchProfile } from "actions/user-actions";
import { completeSurvey, setItemInProgress, removeAllFeedback } from "actions/happiness-survey-actions";
import { isLastSurvey } from "helpers/happiness-survey-helpers";
import HeapHelpers from "helpers/heap-helpers";
import { createSelectedSku } from "helpers/sku-helpers";

import DidntWear from "./didnt-wear";
import FinalScreen from "./final-screen";
import DidntFitScreen from "components/source/happiness_survey/hs_reviews_combined/didnt-fit";
import RateItScreen from "components/source/happiness_survey/hs_reviews_combined/rate-it";
import Modal from "components/source/shared/modal";
import QualityIssues from "./quality-issues";
import ReviewScreen from "components/source/happiness_survey/hs_reviews_combined/review";
import StartScreen from "components/source/happiness_survey/hs_reviews_combined/start-screen";

import HappinessSurveyStyles from "./happiness-survey.module.scss";
import httpClient from "clients/RawClient";

const propTypes = {
  closeHappinessSurvey: PropTypes.func,
  handleRequestClose: PropTypes.func,
  happinessSurvey: happinessSurveyPropType,
  isModalOpen: PropTypes.bool,
  membershipState: membershipStatePropType,
  isMasquerading: PropTypes.bool,
  isHappinessSurveySkipFlagEnabled: PropTypes.bool,
};

function HSReviewsCombined({
  closeHappinessSurvey,
  handleRequestClose,
  happinessSurvey,
  isModalOpen,
  membershipState,
  isMasquerading,
  isHappinessSurveySkipFlagEnabled,
}) {
  if (isMasquerading) {
    return null;
  }

  const dispatch = useDispatch();

  const [currentScreen, setCurrentScreen] = React.useState(pages.START);
  const [prevScreen, setPrevScreen] = React.useState(pages.START);
  const [hasSubmittedFirstSurvey, setHasSubmittedFirstSurvey] = React.useState(false);
  const [currentSurvey, setCurrentSurvey] = React.useState();
  const [wornItemsBookingIds, setWornItemsBookingIds] = React.useState([]);
  const [hasClickedSelectNone, setHasClickedSelectNone] = React.useState(false);
  const [hasClickedSelectAll, setHasClickedSelectAll] = React.useState(false);
  const [previouslyReviewedStyles, setPreviouslyReviewedStyles] = React.useState(null);

  React.useEffect(() => {
    if (isModalOpen) {
      dispatch(fetchProfile());
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    if (currentSurvey?.bookingId !== wornItemsBookingIds[0]) {
      setCurrentSurveyItem(wornItemsBookingIds[0]);
    }
  }, [wornItemsBookingIds]);

  React.useEffect(() => {
    const itemSurveys = happinessSurvey?.itemSurveys;
    setCurrentSurvey(itemSurveys.find(s => s.inProgress === true));

    // fetch previously reviewed styles once surveys are loaded
    if (happinessSurvey && !previouslyReviewedStyles) {
      fetchPreviouslyReviewedStyles(happinessSurvey.itemSurveys.map(item => item.product.id));
    }
  }, [happinessSurvey]);

  React.useEffect(() => {
    if (wornItemsBookingIds.length === happinessSurvey.itemSurveys.length) {
      setHasClickedSelectAll(true);
    } else {
      setHasClickedSelectAll(false);
    }
  }, [wornItemsBookingIds]);
  const fetchPreviouslyReviewedStyles = styles => {
    const params = new URLSearchParams();
    params.append("status", "approved");
    styles.map(style => params.append("styleName", style));

    httpClient
      .get("/api/reviews/user?" + params.toString())
      .then(res => {
        if (res?.moments) {
          setPreviouslyReviewedStyles(res.moments.map(moment => moment.styleName));
        } else {
          setPreviouslyReviewedStyles([]);
        }
      })
      .catch(() => {
        setPreviouslyReviewedStyles([]);
      });
  };

  const setCurrentSurveyItem = bookingId => {
    if (!bookingId) {
      return;
    }
    dispatch(setItemInProgress(bookingId));
  };

  const unwornItemBookingIds = happinessSurvey.itemSurveys
    .map(is => is.bookingId)
    .filter(b => !wornItemsBookingIds.includes(b));

  const allItemsSelected = wornItemsBookingIds.length === happinessSurvey.itemSurveys.length;

  const handleGoToPage = screen => {
    setCurrentScreen(prevPage => {
      setPrevScreen(prevPage);
      return screen;
    });
  };

  const handleStartScreenItemTapped = bookingId => {
    //  unselecting an item
    setHasClickedSelectNone(false);
    if (Array.isArray(wornItemsBookingIds) && wornItemsBookingIds.includes(bookingId)) {
      setWornItemsBookingIds(prevIds => prevIds.filter(item => item !== bookingId));
    }
    //  selecting an item
    else {
      setWornItemsBookingIds(prevIds => [...prevIds, bookingId]);
    }
  };

  const handleStartScreenSelectAllTapped = () => {
    setHasClickedSelectNone(false);

    if (allItemsSelected) {
      setWornItemsBookingIds([]);
      setHasClickedSelectAll(false);
    } else {
      setWornItemsBookingIds(happinessSurvey?.itemSurveys?.map(item => item.bookingId));
      setHasClickedSelectAll(true);
    }
  };

  const handleStartScreenSelectNoneTapped = () => {
    setWornItemsBookingIds([]);
    setHasClickedSelectNone(!hasClickedSelectNone);
  };

  const handleCompleteSurvey = (options = {}) => {
    dispatch(
      completeSurvey(
        currentSurvey.bookingId,
        (getState, completedSurvey) => {
          const eventData = {
            style_name: currentSurvey?.product?.id,
            sku: createSelectedSku(currentSurvey?.product?.id, currentSurvey?.size, currentSurvey?.product?.skus)?.id,
            rating: completedSurvey.rating,
            number_of_photos: completedSurvey.photoIds.length,
            has_review_headline: completedSurvey.caption?.length > 0,
            has_review_description: completedSurvey.reviewContent?.length > 0,
            survey_feedback: JSON.stringify(completedSurvey.surveyFeedbacks.map(feedback => feedback.feedback)),
          };
          HeapHelpers.fireHeapEvent("sf-happiness-survey-submit-review-of-one-item", eventData);
          const order = { true: 1, false: 2 };
          const checkWornBookingId = survey => order[wornItemsBookingIds.includes(survey.bookingId)];
          const remainingSurveys = getState() //get the happiness surveys that are initialized, and place worn items first
            .happinessSurvey.itemSurveys.filter(s => s.status === status.INITIALIZED)
            .sort((a, b) => checkWornBookingId(a) - checkWornBookingId(b));
          setHasSubmittedFirstSurvey(true);
          if (remainingSurveys.length === 0) {
            handleGoToPage(pages.FINAL);
          } else {
            const bookingId = remainingSurveys[0].bookingId;
            setCurrentSurveyItem(bookingId);
            if (wornItemsBookingIds.includes(bookingId)) {
              dispatch(removeAllFeedback(currentSurvey.bookingId)); // clear feedback from previous item
              handleGoToPage(pages.RATE_IT);
            } else {
              handleGoToPage(pages.DIDNT_WEAR);
            }
          }
        },
        options
      )
    );
  };

  const handleHeapEventScreenTriggers = screen => {
    const eventData = { screen: heapEventScreens[screen] };
    if (screen === pages.START) {
      eventData.number_of_items_to_be_reviewed = happinessSurvey?.itemSurveys?.length || 0;
    }
    eventData.sku = createSelectedSku(
      currentSurvey?.product?.id,
      currentSurvey?.size,
      currentSurvey?.product?.skus
    )?.id;

    HeapHelpers.fireHeapEvent("sf-happiness-survey-view-screen", eventData);
  };

  const startScreenProps = isHappinessSurveySkipFlagEnabled
    ? {
        unwornItemBookingIds,
        closeHappinessSurvey: closeHappinessSurvey,
        onSelectNoneTapped: handleStartScreenSelectNoneTapped,
        isSelectNoneChecked: hasClickedSelectNone,
      }
    : {};

  const renderScreen = screen => {
    switch (screen) {
      case pages.START:
        handleHeapEventScreenTriggers(screen);
        return (
          <StartScreen
            goToPage={handleGoToPage}
            happinessSurvey={happinessSurvey}
            onItemTapped={handleStartScreenItemTapped}
            onSelectAllTapped={handleStartScreenSelectAllTapped}
            isSelectAllTapped={hasClickedSelectAll}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
            wornItemsBookingIds={wornItemsBookingIds}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...startScreenProps}
          />
        );
      case pages.RATE_IT:
        handleHeapEventScreenTriggers(screen);
        return (
          <RateItScreen
            currentSurvey={currentSurvey}
            goToPage={handleGoToPage}
            happinessSurvey={happinessSurvey}
            handleCompleteSurvey={handleCompleteSurvey}
            hasSubmittedFirstSurvey={hasSubmittedFirstSurvey}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
            closeHappinessSurvey={closeHappinessSurvey}
            isLastSurvey={isLastSurvey(happinessSurvey)}
            isPreviouslyReviewed={previouslyReviewedStyles.includes(currentSurvey?.product?.id)}
          />
        );
      case pages.DIDNT_WEAR:
        handleHeapEventScreenTriggers(screen);
        return (
          <DidntWear
            completeSurvey={handleCompleteSurvey}
            currentSurvey={currentSurvey}
            goToPage={handleGoToPage}
            happinessSurvey={happinessSurvey}
            hasSubmittedFirstSurvey={hasSubmittedFirstSurvey}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
            closeHappinessSurvey={closeHappinessSurvey}
          />
        );
      case pages.DIDNT_FIT:
        handleHeapEventScreenTriggers(screen);
        return (
          <DidntFitScreen
            completeSurvey={handleCompleteSurvey}
            currentSurvey={currentSurvey}
            goToPage={handleGoToPage}
            happinessSurvey={happinessSurvey}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
            closeHappinessSurvey={closeHappinessSurvey}
          />
        );

      case pages.QUALITY_ISSUES:
        handleHeapEventScreenTriggers(screen);
        return (
          <QualityIssues
            completeSurvey={handleCompleteSurvey}
            currentSurvey={currentSurvey}
            goToPage={handleGoToPage}
            happinessSurvey={happinessSurvey}
            prevPage={prevScreen}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
            closeHappinessSurvey={closeHappinessSurvey}
          />
        );
      case pages.REVIEW:
        handleHeapEventScreenTriggers(screen);
        return (
          <ReviewScreen
            currentSurvey={currentSurvey}
            goToPage={handleGoToPage}
            handleCompleteSurvey={handleCompleteSurvey}
            happinessSurvey={happinessSurvey}
            isLastSurvey={isLastSurvey(happinessSurvey)}
            isWornItem={wornItemsBookingIds.includes(currentSurvey?.bookingId)}
            prevPage={prevScreen}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
            closeHappinessSurvey={closeHappinessSurvey}
          />
        );
      case pages.FINAL:
        handleHeapEventScreenTriggers(screen);
        return (
          <FinalScreen
            closeHappinessSurvey={closeHappinessSurvey}
            happinessSurvey={happinessSurvey}
            membershipState={membershipState}
          />
        );
      default:
        return (
          <StartScreen
            goToPage={handleGoToPage}
            happinessSurvey={happinessSurvey}
            onItemTapped={handleStartScreenItemTapped}
            onSelectAllTapped={handleStartScreenSelectAllTapped}
            wornItemsBookingIds={wornItemsBookingIds}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
          />
        );
    }
  };
  const vh = window.innerHeight * 0.01;
  const additionalStyles = {
    overlay: {
      "--vh": vh,
    },
  };
  return (
    <Modal
      additionalStyles={additionalStyles}
      className={HappinessSurveyStyles["happiness-survey__modal"]}
      isOpen={isModalOpen}
      onRequestClose={handleRequestClose}>
      {renderScreen(currentScreen)}
    </Modal>
  );
}

const mapStateToProps = state => ({
  happinessSurvey: state.happinessSurvey,
  membershipState: state.membershipState,
  isMasquerading: state.userData?.isMasquerading,
});

HSReviewsCombined.propTypes = propTypes;
export default connect(mapStateToProps)(HSReviewsCombined);
