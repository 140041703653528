import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { childrenPropType } from "components/propTypes";
import InputsStyles from "./inputs.module.scss";
import InputsLabel from "./input-label";

const propTypes = {
  additionalClassName: PropTypes.string,
  children: childrenPropType, // when using InputContainer, wrap it around the input you want
  isInlineInput: PropTypes.bool,
  labelProps: PropTypes.shape({
    additionalClassName: PropTypes.object,
    headerText: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    subHeaderText: PropTypes.string,
  }),
};

const InputContainer = ({ additionalClassName, children, isInlineInput, labelProps }) => {
  const containerClassName = classNames(InputsStyles["input-container"], {
    [additionalClassName]: additionalClassName,
    [InputsStyles["inline"]]: isInlineInput,
  });

  return (
    <div className={containerClassName}>
      <InputsLabel
        additionalClassName={labelProps.additionalClassName}
        headerText={labelProps?.headerText || ""}
        isRequired={labelProps.isRequired}
        subHeaderText={labelProps?.subHeaderText}
      />
      {children}
    </div>
  );
};

InputContainer.propTypes = propTypes;
export default InputContainer;
