import React from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames";

const UserProfileFormTextInput = ({ config, value, onChange, error, showErrorMsg = true }) => {
  const inputClass = classNames("input-wrapper", "item", { error: !!error });

  return (
    <div className={inputClass}>
      <label htmlFor={config.id}>{config.label}</label>
      <input
        id={config.id}
        data-test-id={config.id}
        name={config.name}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={config.placeholder}
        data-heap-redact-text="true"
      />
      {showErrorMsg && !!error && <p className="error">{error}</p>}
    </div>
  );
};

UserProfileFormTextInput.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showErrorMsg: PropTypes.bool,
};

export default UserProfileFormTextInput;
