import React from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames";
import InputStyles from "./inputs.module.scss";

const propTypes = {
  additionalClassName: PropTypes.string,
  id: PropTypes.string,
  firstOptionProps: PropTypes.shape({
    additionalInputClassName: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.string,
  }),
  secondOptionProps: PropTypes.shape({
    additionalInputClassName: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.string,
  }),
};

const DoubleDropdownFormSelect = ({ additionalClassName, id, firstOptionProps, secondOptionProps }) => {
  const renderOption = option => {
    // convert option to [value, label] if not an array
    const arrayOption = Array.isArray(option) ? option : [option, option];
    // use replace instead of dangerouslySetInnerHTML on label to avoid XSS attack
    arrayOption[1] =
      typeof arrayOption[1] === "string"
        ? arrayOption[1].replace(`&apos;`, `'`).replace(`&quot;`, `"`)
        : arrayOption[1];

    return (
      <option key={arrayOption[0]} value={arrayOption[0]}>
        {arrayOption[1]}
      </option>
    );
  };

  const isErrorPresent = firstOptionProps.error || secondOptionProps.error;

  const className = classNames(`${InputStyles["dropdown-select"]} ${[InputStyles["double-input"]]}`, {
    [additionalClassName]: additionalClassName,
    error: isErrorPresent,
  });

  const firstInputClassName = classNames(InputStyles["input"], {
    [firstOptionProps.additionalInputClassName]: firstOptionProps.additionalInputClassName,
    error: firstOptionProps.error,
  });

  const secondInputClassName = classNames(InputStyles["input"], {
    [secondOptionProps.additionalInputClassName]: secondOptionProps.additionalInputClassName,
    error: secondOptionProps.error,
  });

  return (
    <div className={className}>
      <select
        className={firstInputClassName}
        id={firstOptionProps.name ? firstOptionProps.name : id}
        data-test-id={firstOptionProps.name ? firstOptionProps.name : id}
        name={firstOptionProps.name}
        type="select"
        value={firstOptionProps.value}
        onChange={firstOptionProps.onChange}
        data-heap-redact-text="true">
        {firstOptionProps.options.map(option => renderOption(option))}
      </select>
      <select
        className={secondInputClassName}
        id={secondOptionProps.name ? secondOptionProps.name : id}
        data-test-id={secondOptionProps.name ? secondOptionProps.name : id}
        name={secondOptionProps.name}
        type="select"
        value={secondOptionProps.value}
        onChange={secondOptionProps.onChange}
        data-heap-redact-text="true">
        {secondOptionProps.options.map(option => renderOption(option))}
      </select>
    </div>
  );
};

DoubleDropdownFormSelect.propTypes = propTypes;
export default DoubleDropdownFormSelect;
