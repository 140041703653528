import PropTypes from "prop-types";

import { useDispatch } from "react-redux";

import { addFeedback, removeFeedback, addSurveyComment } from "actions/happiness-survey-actions";
import { happinessSurveyPropType, itemSurveyPropType } from "components/propTypes";
import { pages, qualityIssueOptionsWithCopy } from "constants/happiness-survey-values";

import SurveyControls from "./survey-controls/survey-controls";
import SurveyItemBanner from "./survey-item-banner";
import HappinessSurveyFooter from "./happiness-survey-footer";
import InputContainer from "./inputs/input-container";
import ButtonContainer from "./inputs/button-container";

import HappinessSurveyStyles from "./happiness-survey.module.scss";

const propTypes = {
  completeSurvey: PropTypes.func,
  currentSurvey: itemSurveyPropType,
  goToPage: PropTypes.func,
  happinessSurvey: happinessSurveyPropType,
  prevPage: PropTypes.string,
  isHappinessSurveySkipFlagEnabled: PropTypes.bool,
  closeHappinessSurvey: PropTypes.func,
};

const QualityIssues = ({
  completeSurvey,
  currentSurvey,
  goToPage = () => {},
  happinessSurvey,
  prevPage,
  isHappinessSurveySkipFlagEnabled = false,
  closeHappinessSurvey = () => {},
}) => {
  if (!currentSurvey) {
    return null;
  }

  const [comment, setComment] = React.useState("");
  const dispatch = useDispatch();

  const commentLabelProps = {
    headerText: "Anything else we should know?",
  };
  const optionsLabelProps = {
    headerText: "What was wrong with this item?",
    subHeaderText: "Select all that apply",
    isRequired: true,
  };

  const handleClickBack = () => {
    goToPage(prevPage);
  };

  const selectedButtons = new Set();

  currentSurvey?.surveyFeedbacks?.forEach(fbEntry => {
    if (fbEntry.feedback in qualityIssueOptionsWithCopy) {
      selectedButtons.add(fbEntry.feedback);
    }
  });

  const handleClickNext = () => {
    if (comment) {
      dispatch(addSurveyComment(comment, currentSurvey.bookingId));
    }

    completeSurvey();
  };

  const handleClickSelectionButton = data => {
    if (!selectedButtons.has(data)) {
      dispatch(addFeedback(data, currentSurvey.bookingId));
    } else {
      dispatch(removeFeedback(data, currentSurvey.bookingId));
    }
  };

  const getButtons = () => {
    return (
      <ButtonContainer
        selectedButtons={selectedButtons}
        buttons={qualityIssueOptionsWithCopy}
        hasFullWidthButtons={false}
        onClickSelectionButton={data => handleClickSelectionButton(data)}
      />
    );
  };

  const footerProps = isHappinessSurveySkipFlagEnabled
    ? {
        showSecondaryCta: true,
        secondaryCtaText: "Save For Later",
        secondaryCtaDisabled: false,
        onClickSecondaryCta: closeHappinessSurvey,
      }
    : {};

  const footer = (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HappinessSurveyFooter onClick={handleClickNext} disabled={selectedButtons.size === 0} {...footerProps} />
  );

  return (
    <div className={HappinessSurveyStyles["happiness-survey__container"]}>
      <SurveyControls onClickBack={handleClickBack} />
      <div
        className={HappinessSurveyStyles["happiness-survey__body"]}
        data-test-id="happiness-survey-quality-issues-screen">
        <SurveyItemBanner currentSurvey={currentSurvey} happinessSurvey={happinessSurvey} />

        <div className={HappinessSurveyStyles["happiness-survey__content"]}>
          <InputContainer labelProps={optionsLabelProps}>{getButtons()}</InputContainer>

          <InputContainer labelProps={commentLabelProps}>
            <textarea
              maxLength="2000"
              value={comment}
              placeholder="Leave a comment for the RTR team"
              onChange={e => setComment(e.target.value)}
            />
          </InputContainer>
        </div>
      </div>
      {footer}
    </div>
  );
};

QualityIssues.pageName = pages.QUALITY_ISSUES;
QualityIssues.propTypes = propTypes;

export default QualityIssues;
