import { useDispatch } from "react-redux";
import { analytics, membershipItemStates } from "rtr-constants";
import { happinessSurveyCTACopy } from "constants/happiness-survey-values";
import { formatDateMonthDay } from "../../../../helpers/date-helpers";
import { happinessSurveyPropType, membershipStatePropType } from "../../../propTypes";
import { redirectToRoute } from "actions/action-utils";
import Routes from "routes";

import ActionLogger from "action-logger";
import HomePickupActions from "actions/home-pickup-actions";
import PropTypes from "prop-types";

import HappinessSurveyFooter from "./happiness-survey-footer";
import HappinessSurveyTitle from "./happiness-survey-title";
import HappinessSurveyStyles from "./happiness-survey.module.scss";

const propTypes = {
  happinessSurvey: happinessSurveyPropType,
  membershipState: membershipStatePropType,
  closeHappinessSurvey: PropTypes.func,
};

const FinalScreen = ({ happinessSurvey, membershipState, closeHappinessSurvey = () => {} }) => {
  const dispatch = useDispatch();
  const headerText = "You\u2019re all set!";
  const subheaderText = "Your shipment is confirmed.";

  const getReturnMethodAndDate = () => {
    const returningBookingIds = new Set();
    happinessSurvey?.itemSurveys?.forEach(item => {
      returningBookingIds.add(item.bookingId);
    });

    let isHPU = false;
    let returnDate = "";

    if (membershipState && Array.isArray(membershipState?.membershipItems)) {
      for (const item of membershipState.membershipItems) {
        if (returningBookingIds.has(item.bookingId)) {
          if (item.state === membershipItemStates.HOME_PICKUP_SCHEDULED) {
            isHPU = true;
            returnDate = item.pickupScheduledFor;
            break;
          } else {
            returnDate = item.afterCrossShipReturnByDate;
            break;
          }
        }
      }
    }
    return {
      method: isHPU ? "home_pickup" : "drop_off",
      returnDate: returnDate,
    };
  };

  const onClickHomePickupButton = () => {
    closeHappinessSurvey();
    dispatch(HomePickupActions.displayHomePickupOverlay(true));
  };

  const onClickUpsButton = () => {
    const logData = {
      object_type: analytics.OBJECT_TYPE.CROSS_SHIP_CONFIRMATION_MODAL,
      action: analytics.ACTION_TYPE.CLICK_DROPOFF_FINDER,
    };
    ActionLogger.logAction(logData);
    redirectToRoute(Routes.UPS);
  };

  const renderReturnHelpBlock = () => {
    return (
      <div className={HappinessSurveyStyles["return-help"]}>
        <button className={HappinessSurveyStyles["hpu-button"]} onClick={() => onClickHomePickupButton()}>
          Schedule A Home Pickup
        </button>
        <button className={HappinessSurveyStyles["ups-button"]} onClick={() => onClickUpsButton()}>
          Find A UPS Dropoff
        </button>
      </div>
    );
  };

  const renderMoreToShare = () => {
    return (
      <>
        <h2>Have more to share?</h2>
        <p>Reviewing your items helps you and the entire RTR community find the perfect fit.</p>
        <a className={HappinessSurveyStyles["review-link"]} href={Routes.User.OrderHistory}>
          Review Past Items
        </a>
      </>
    );
  };

  const methodAndDate = getReturnMethodAndDate();
  const returnDate = formatDateMonthDay(methodAndDate?.returnDate);
  const isHPU = methodAndDate?.method === "home_pickup";
  const returnText = isHPU ? "Your home pickup is scheduled for " : "Drop off your returns by ";

  return (
    <div className={HappinessSurveyStyles["happiness-survey__container"]}>
      <div
        className={HappinessSurveyStyles["happiness-survey__final-screen"]}
        data-test-id="happiness-survey-final-screen">
        <HappinessSurveyTitle
          headerText={headerText}
          subheaderText={subheaderText}
          additionalClassName={HappinessSurveyStyles["final-screen"]}
        />
        <div className={HappinessSurveyStyles["return-info"]}>
          {returnText}
          <span className={HappinessSurveyStyles["inner-date"]}>{`${returnDate}`}</span>
        </div>
        {!isHPU && renderReturnHelpBlock()}
        {renderMoreToShare()}
      </div>
      <HappinessSurveyFooter
        onClick={() => closeHappinessSurvey()}
        disabled={false}
        primaryCtaText={happinessSurveyCTACopy.FINISH_HAPPINESS_SURVEY}
        showSecondaryCta={false}
      />
    </div>
  );
};

FinalScreen.propTypes = propTypes;
export default FinalScreen;
