import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { formatDateFullMonthDay } from "helpers/date-helpers";
import ProductImageWrapper from "components/source/shared/product-image-wrapper";
import { HomePickupOrderType } from "rtr-constants";

const propTypes = {
  order: PropTypes.object.isRequired,
  onSelectedOrder: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

const HomePickUpOrderReturnCard = ({ order, onSelectedOrder, checked, disabled, error }) => {
  const { pickupOrderType, items, orderId, returnByDate } = order;

  const images = items?.map(item => ({
    product: item?.product,
    altName: `${item?.product?.displayName} by ${item?.product?.designer?.displayName}`,
    bookingId: item?.bookingId,
  }));

  const handleClick = () => (typeof onSelectedOrder === "function" ? onSelectedOrder(order) : null);

  const orderHeader = pickupOrderType === HomePickupOrderType.STANDALONE_MEMBERSHIP ? "Returning" : `Order #${orderId}`;
  const orderSubHeader =
    pickupOrderType === HomePickupOrderType.STANDALONE_MEMBERSHIP ? "Membership" : "One-Time Rental";

  const orderHeaderClassName = classNames("order-header", {
    ["error-alert"]: error,
  });
  const checkBoxClassName = classNames("checkbox", {
    active: checked,
    ["error-alert"]: error,
  });

  return (
    <button
      role="checkbox"
      className="home-pickup-order-return-card"
      onClick={handleClick}
      disabled={disabled}
      aria-checked={checked}>
      <div className="order-details">
        <div className="details">
          <span className={orderHeaderClassName}>{orderHeader}</span>
          <span className="order-sub-header">{orderSubHeader}</span>
          <span className="order-return-date">
            Return by <b>{formatDateFullMonthDay(returnByDate)}</b>
          </span>
        </div>
        {onSelectedOrder && (
          <svg
            className={checkBoxClassName}
            // This element is purely decorative so
            // we hide it for screen readers
            aria-hidden="true"
            viewBox="0 0 15 11"
            fill="none">
            <path d="M1 4.5L5 9L14 1" strokeWidth="2" stroke={checked ? "#fff" : "none"} />
          </svg>
        )}
      </div>

      <div className="thumbnails">
        {images?.map(image => (
          <ProductImageWrapper
            wrapperClassName="product-wrapper"
            key={image.bookingId}
            productImageClassName="product_image"
            product={image.product}
            alt={image.altName}
          />
        ))}
      </div>
    </button>
  );
};

HomePickUpOrderReturnCard.propTypes = propTypes;

export default HomePickUpOrderReturnCard;
