import React from "react";
import PropTypes from "prop-types";
import ThumbsUp from "images/thumbsUp.svg";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import { MODAL_NAMES } from "rtr-constants/modalNames";

export class ReviewPopupSubmitSuccess extends React.Component {
  static propTypes = {
    closeAction: PropTypes.func.isRequired,
  };

  static MODAL_NAME = MODAL_NAMES.REVIEW_SUBMIT_SUCCESS;

  render() {
    return (
      <div className="review-confirmation-success">
        <div className="thumbs-up-background">
          <ThumbsUp className="thumbs-up-icon" />
        </div>
        <div className="text-content">
          <h2 className="text-heading">Thanks for sharing!</h2>
          <p className="subtext universal-small">
            Your reviews help others find the best
            <br />
            looks for them. Keep the tips coming!
          </p>
        </div>
        <div className="btn-container">
          <AtomFullWidthButton buttonText="done" onClick={this.props.closeAction} />
          <p className="universal-xsmall--secondary">Reviews may take up to 24 hours to publish</p>
        </div>
      </div>
    );
  }
}

export default ReviewPopupSubmitSuccess;
