import PropTypes from "prop-types";
import { happinessSurveyPropType } from "../../../propTypes";
import { pages } from "constants/happiness-survey-values";
import HappinessSurveyFooter from "./happiness-survey-footer";
import HappinessSurveyCheckbox from "./inputs/happiness-survey-checkbox";
import HappinessSurveyProductGrid from "./inputs/happiness-survey-product-grid";
import HappinessSurveyStyles from "./happiness-survey.module.scss";
import HappinessSurveyTitle from "./happiness-survey-title";
import classNames from "classnames";
import React from "react";

const propTypes = {
  goToPage: PropTypes.func,
  happinessSurvey: happinessSurveyPropType,
  onItemTapped: PropTypes.func,
  onSelectAllTapped: PropTypes.func,
  onSelectNoneTapped: PropTypes.func,
  wornItemsBookingIds: PropTypes.array,
  isHappinessSurveySkipFlagEnabled: PropTypes.bool,
  closeHappinessSurvey: PropTypes.func,
  isSelectNoneChecked: PropTypes.bool,
  isSelectAllTapped: PropTypes.bool,
};

const StartScreen = ({
  goToPage = () => {},
  happinessSurvey,
  onItemTapped = () => {},
  onSelectAllTapped = () => {},
  onSelectNoneTapped = () => {},
  wornItemsBookingIds = [],
  isHappinessSurveySkipFlagEnabled = false,
  closeHappinessSurvey = () => {},
  isSelectNoneChecked = false,
  isSelectAllTapped = false,
}) => {
  if (typeof isHappinessSurveySkipFlagEnabled !== "boolean") {
    return null;
  }
  const handleClickNext = () => {
    if (isHappinessSurveySkipFlagEnabled && isSelectNoneChecked) {
      goToPage(pages.DIDNT_WEAR);
    } else {
      goToPage(pages.RATE_IT);
    }
  };

  const handleClickDidntWear = () => {
    goToPage(pages.DIDNT_WEAR);
  };

  const headerText = "How did it go?";
  const subheaderText = "Rating items helps you (and others) find the right fit.";

  const selectionText = "To start, select the items you wore.";

  const woreEverythingCopy = isHappinessSurveySkipFlagEnabled ? "I Wore Everything" : "Select all";

  const labelAndCheckbox = classNames({
    "double-checkbox": isHappinessSurveySkipFlagEnabled,
    "grid-label-and-checkbox": !isHappinessSurveySkipFlagEnabled,
  });

  const titleScreen = classNames({
    "happiness-survey__title-screen-checkboxes": isHappinessSurveySkipFlagEnabled,
    "happiness-survey__title-screen": !isHappinessSurveySkipFlagEnabled,
  });

  let ctaText;
  if (isSelectNoneChecked) {
    ctaText = "Next (0)";
  } else if (wornItemsBookingIds.length > 0) {
    ctaText = `Next (${wornItemsBookingIds.length})`;
  } else {
    ctaText = "Next";
  }

  const nextButonEnabled = wornItemsBookingIds.length > 0 || isSelectNoneChecked;

  const secondaryCtaProps = isHappinessSurveySkipFlagEnabled
    ? {
        secondaryCtaText: "Save For Later",
        secondaryCtaDisabled: false,
        onClickSecondaryCta: closeHappinessSurvey,
      }
    : {
        secondaryCtaText: "I Didn’t Wear Any Of These Items",
        onClickSecondaryCta: handleClickDidntWear,
      };

  return (
    <div className={HappinessSurveyStyles["happiness-survey__container"]} data-test-id="happiness-survey-start-screen">
      <div className={HappinessSurveyStyles[titleScreen]}>
        <HappinessSurveyTitle
          headerText={headerText}
          subheaderText={subheaderText}
          additionalClassName={HappinessSurveyStyles["start-screen"]}
        />
        <div className={HappinessSurveyStyles[labelAndCheckbox]} data-test-id="label-and-checkbox">
          <p className={HappinessSurveyStyles["grid-instructions"]}>{selectionText}</p>
          <HappinessSurveyCheckbox
            selectAllText={woreEverythingCopy}
            selectNoneText="I Didn't Wear Any"
            isSelectAllChecked={isSelectAllTapped}
            isSelectNoneChecked={isSelectNoneChecked}
            handleSelectAll={onSelectAllTapped}
            handleSelectNone={onSelectNoneTapped}
            isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
          />
        </div>
        <HappinessSurveyProductGrid
          itemSurveys={happinessSurvey?.itemSurveys}
          selectedItems={wornItemsBookingIds}
          onClickItem={onItemTapped}
          isSelectNoneChecked={isSelectNoneChecked}
          isHappinessSurveySkipFlagEnabled={isHappinessSurveySkipFlagEnabled}
        />
      </div>
      <HappinessSurveyFooter
        onClick={handleClickNext}
        primaryCtaText={ctaText}
        disabled={!nextButonEnabled}
        showSecondaryCta={true}
        secondaryCtaDisabled={nextButonEnabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...secondaryCtaProps}
      />
    </div>
  );
};

StartScreen.propTypes = propTypes;
export default StartScreen;
