import React from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames";
import InputStyles from "./inputs.module.scss";

const propTypes = {
  additionalClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

const DropdownFormSelect = ({ additionalClassName, error, id, name, onChange, options, placeholder, value }) => {
  const renderOption = option => {
    // convert option to [value, label] if not an array
    const arrayOption = Array.isArray(option) ? option : [option, option];
    // use replace instead of dangerouslySetInnerHTML on label to avoid XSS attack
    arrayOption[1] = arrayOption[1].replace(`&apos;`, `'`).replace(`&quot;`, `"`);

    return (
      <option key={arrayOption[0]} value={arrayOption[0]}>
        {arrayOption[1]}
      </option>
    );
  };

  const className = classNames(InputStyles["dropdown-select"], {
    [additionalClassName]: additionalClassName,
    error: error,
  });

  return (
    <select
      className={className}
      id={id ? id : name}
      data-test-id={id ? id : name}
      name={name}
      type="select"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      data-heap-redact-text="true">
      {options.map(option => renderOption(option))}
    </select>
  );
};

DropdownFormSelect.propTypes = propTypes;
export default DropdownFormSelect;
