import ProductImagePath from "./product-image-path.js";
import { selectMainImageGroup } from "./product-image-helpers";
import happinessSurveyValues, { status } from "constants/happiness-survey-values";

const HappinessSurveyHelpers = {
  getImageUrl: (survey, imageSize) => {
    const image = selectMainImageGroup(survey?.product?.images ?? {});
    if (!image[imageSize]) {
      return;
    }
    return ProductImagePath + image[imageSize];
  },
  isLastSurvey: (happinessSurvey = { itemSurveys: [] }) =>
    happinessSurvey?.itemSurveys.filter(s => s.status === status.INITIALIZED).length === 1,

  /**
   * converts a survey with feedback and returns an object where the key is the type of feedback that is given and the value
   * is the feedback itself. If given, we check the value through given feedback mapping, otherwise use our HS constants
   *
   * @param {itemSurvey} currentSurvey - survey that we check feedback values from
   * @param {object} defaulForm - object whose structure is returned with the values parsed from currentSurvey
   * @param {object} [feedbackValuesMap] - object with structure { [feedbackType]: customFeedbackMapValues } to be read instead of HSV[feedbackType] if supplied
   * @returns {object} - returns a new object where the keys are derived from the defaultForm and the values are parsed from currentSurvey
   */
  convertCurrentSurveyIntoForm: (currentSurvey, defaultForm, feedbackValuesMap) => {
    if (!currentSurvey?.surveyFeedbacks?.length) {
      return defaultForm;
    }
    const newObject = {};
    const allFeedback = currentSurvey.surveyFeedbacks.map(feedback => feedback.feedback);
    for (const key in defaultForm) {
      const selectedKeyValues = feedbackValuesMap?.[key]
        ? feedbackValuesMap[key]
        : Object.keys(happinessSurveyValues[key]);
      // for evey submitted feedback value, check if the value is associated with the given array of values
      const feedbackValue = allFeedback.find(feedback => selectedKeyValues.includes(feedback));
      newObject[key] = feedbackValue ? feedbackValue : defaultForm[key];
    }

    return newObject;
  },

  isLocalValueSelected: (form, value, type) => form[type] === value,
};

export default HappinessSurveyHelpers;

export const { getImageUrl, isLastSurvey, convertCurrentSurveyIntoForm, isLocalValueSelected } = HappinessSurveyHelpers;
