import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AdditionalComment from "./additional-comment";
import CapsuleSelect from "components/source/molecules/molecule-capsule-select";
import SurveyControls from "./survey-controls";
import { happinessSurveyValues } from "rtr-constants";
import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";

const propTypes = {
  goToPage: PropTypes.func.isRequired,
  completeSurvey: PropTypes.func.isRequired,
  addFeedback: PropTypes.func.isRequired,
  removeFeedback: PropTypes.func.isRequired,
  currentSurvey: PropTypes.object,
  currentPage: PropTypes.string.isRequired,
  lastPageViewed: PropTypes.string.isRequired,
  getNextPage: PropTypes.func.isRequired, // NW [EXPLANATION] 4/17/19: the next page from here is dynamic based on feedback received.
};

export default class FitFeedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentOpenCapsule: "",
    };
  }

  static get pageName() {
    return "FitFeedback";
  }

  static get baseClass() {
    return "fit-feedback";
  }

  previouslyAtCommentPage() {
    return AdditionalComment.pageName === this.props.lastPageViewed;
  }

  handleClickBack() {
    this.props.goToPage(this.props.lastPageViewed);
  }

  handleAdditionalFeedbackFit() {
    const additionalFeedbackIssue = happinessSurveyValues.additionalFeedbackIssue;
    const { FIT_FEEDBACK, FIT_ISSUE } = additionalFeedbackIssue;
    if (this.isFeedbackAlreadyPresent(FIT_FEEDBACK) || this.isFeedbackAlreadyPresent(FIT_ISSUE)) {
      return;
    }

    if (this.previouslyAtCommentPage()) {
      this.props.addFeedback(FIT_FEEDBACK);
    } else {
      this.props.addFeedback(FIT_ISSUE);
    }
  }

  handleClickSkip() {
    this.handleAdditionalFeedbackFit();
    this.props.completeSurvey();
  }

  handleClickNext() {
    this.handleAdditionalFeedbackFit();
    this.props.goToPage(this.props.getNextPage());
  }

  handleSelect(value, type) {
    if (!this.isFeedbackAlreadyPresent(value)) {
      this.props.addFeedback(value, type);
    } else {
      this.props.removeFeedback(value);
    }
  }

  handleOpenCapsule(type) {
    this.setState({
      currentOpenCapsule: type,
    });
  }

  handleCloseCapsule() {
    this.setState({
      currentOpenCapsule: "",
    });
  }

  handleClear(value, type) {
    this.props.removeFeedback(value, type);
    this.handleCloseCapsule();
  }

  isFeedbackAlreadyPresent(value) {
    const { currentSurvey } = this.props;
    return happinessSurveyContainsFeedback(currentSurvey, value);
  }

  willRender() {
    return this.props.currentPage === this.constructor.pageName;
  }

  insertJustRightFit(values, value) {
    // If we came from the additional comment page we want to insert Just Right as an option
    // in between Too Big and too Small
    // Otherwise just return current values of the page
    if (this.previouslyAtCommentPage()) {
      const justRight = {
        text: "Just Right",
        value: value,
        selected: this.isFeedbackAlreadyPresent(value),
      };

      values.splice(1, 0, justRight);
    }

    return values;
  }

  renderOverallFit() {
    const values = [
      {
        text: "Big",
        value: happinessSurveyValues.overallFit.BIG_OVERALL,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.overallFit.BIG_OVERALL),
      },
      {
        text: "Small",
        value: happinessSurveyValues.overallFit.SMALL_OVERALL,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.overallFit.SMALL_OVERALL),
      },
    ];

    const flaggedValues = this.insertJustRightFit(values, happinessSurveyValues.overallFit.JUST_RIGHT_OVERALL);
    return this.renderCapsuleSelect(happinessSurveyValues.feedbackTypes.overallFit, "Overall", flaggedValues);
  }

  renderArmsShouldersFit() {
    const values = [
      {
        text: "Big",
        value: happinessSurveyValues.armsShouldersFit.BIG_ARMS_SHOULDERS,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.armsShouldersFit.BIG_ARMS_SHOULDERS),
      },
      {
        text: "Small",
        value: happinessSurveyValues.armsShouldersFit.SMALL_ARMS_SHOULDERS,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.armsShouldersFit.SMALL_ARMS_SHOULDERS),
      },
    ];

    const flaggedValues = this.insertJustRightFit(
      values,
      happinessSurveyValues.armsShouldersFit.JUST_RIGHT_ARMS_SHOULDERS
    );
    return this.renderCapsuleSelect(
      happinessSurveyValues.feedbackTypes.armsShouldersFit,
      "Arms/Shoulders",
      flaggedValues
    );
  }

  renderChestFit() {
    const values = [
      {
        text: "Big",
        value: happinessSurveyValues.chestFit.BIG_CHEST,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.chestFit.BIG_CHEST),
      },
      {
        text: "Small",
        value: happinessSurveyValues.chestFit.SMALL_CHEST,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.chestFit.SMALL_CHEST),
      },
    ];

    const flaggedValues = this.insertJustRightFit(values, happinessSurveyValues.chestFit.JUST_RIGHT_CHEST);
    return this.renderCapsuleSelect(happinessSurveyValues.feedbackTypes.chestFit, "Chest", flaggedValues);
  }

  renderWaistFit() {
    const values = [
      {
        text: "Big",
        value: happinessSurveyValues.waistFit.BIG_WAIST,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.waistFit.BIG_WAIST),
      },
      {
        text: "Small",
        value: happinessSurveyValues.waistFit.SMALL_WAIST,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.waistFit.SMALL_WAIST),
      },
    ];

    const flaggedValues = this.insertJustRightFit(values, happinessSurveyValues.waistFit.JUST_RIGHT_WAIST);
    return this.renderCapsuleSelect(happinessSurveyValues.feedbackTypes.waistFit, "Waist", flaggedValues);
  }

  renderLengthFit() {
    const values = [
      {
        text: "Long",
        value: happinessSurveyValues.lengthFit.LONG_LENGTH,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.lengthFit.LONG_LENGTH),
      },
      {
        text: "Short",
        value: happinessSurveyValues.lengthFit.SHORT_LENGTH,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.lengthFit.SHORT_LENGTH),
      },
    ];

    const flaggedValues = this.insertJustRightFit(values, happinessSurveyValues.lengthFit.JUST_RIGHT_LENGTH);
    return this.renderCapsuleSelect(happinessSurveyValues.feedbackTypes.lengthFit, "Length", flaggedValues);
  }

  renderButtFit() {
    const values = [
      {
        text: "Big",
        value: happinessSurveyValues.buttFit.BIG_BUTT,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.buttFit.BIG_BUTT),
      },
      {
        text: "Small",
        value: happinessSurveyValues.buttFit.SMALL_BUTT,
        selected: this.isFeedbackAlreadyPresent(happinessSurveyValues.buttFit.SMALL_BUTT),
      },
    ];

    const flaggedValues = this.insertJustRightFit(values, happinessSurveyValues.buttFit.JUST_RIGHT_BUTT);
    return this.renderCapsuleSelect(happinessSurveyValues.feedbackTypes.buttFit, "Hips", flaggedValues);
  }

  renderCapsuleSelect(type, typeAsText, values) {
    return (
      <div className={`${this.constructor.baseClass}__action`}>
        <CapsuleSelect
          type={type}
          typeAsText={typeAsText}
          onOpen={this.handleOpenCapsule.bind(this)}
          onClose={this.handleCloseCapsule.bind(this)}
          onClear={this.handleClear.bind(this)}
          isOpen={this.state.currentOpenCapsule === type}
          values={values}
          onSelect={this.handleSelect.bind(this)}
        />
      </div>
    );
  }

  renderCopy() {
    let copy = "To ensure this doesn’t happen again, which areas didn’t fit well?";
    if (this.previouslyAtCommentPage()) {
      copy = "Let us know how this item fit.";
    }

    return <div className={`${this.constructor.baseClass}__copy h4`}>{copy}</div>;
  }

  render() {
    if (!this.willRender()) {
      return null;
    }

    const className = classNames(this.constructor.baseClass, {
      [`${this.constructor.baseClass}--comment-page`]: this.previouslyAtCommentPage(),
    });

    return (
      <div className={className}>
        <SurveyControls
          willShowBack={true}
          willShowSkip={true}
          onClickBack={() => {
            this.handleClickBack();
          }}
          onClickSkip={() => {
            this.handleClickSkip();
          }}
        />
        {this.renderCopy()}
        <div className={`${this.constructor.baseClass}__subcopy h5`}>Select all that apply.</div>
        {this.renderOverallFit()}
        {this.renderArmsShouldersFit()}
        {this.renderChestFit()}
        {this.renderWaistFit()}
        {this.renderButtFit()}
        {this.renderLengthFit()}
        <div className={`${this.constructor.baseClass}__next`}>
          <button
            onClick={() => {
              this.handleClickNext();
            }}
            className="btn">
            Next
          </button>
        </div>
      </div>
    );
  }
}

FitFeedback.propTypes = propTypes;
