import classNames from "classnames";
import PropTypes from "prop-types";
import HappinessSurveyStyles from "./happiness-survey.module.scss";

const propTypes = {
  headerText: PropTypes.string,
  subheaderText: PropTypes.string,
  additionalClassName: PropTypes.string,
};

const HappinessSurveyTitle = ({ headerText, subheaderText, additionalClassName }) => {
  const titleClassNames = classNames(HappinessSurveyStyles["happiness-survey__title"], {
    [additionalClassName]: additionalClassName,
  });

  return (
    <div className={titleClassNames} data-test-id="happiness-survey-title">
      <h1>{headerText}</h1>
      <p>{subheaderText}</p>
    </div>
  );
};

HappinessSurveyTitle.propTypes = propTypes;
export default HappinessSurveyTitle;
