import classNames from "classnames";
import PropTypes from "prop-types";

import InputsStyles from "./inputs.module.scss";

//  custom PropType validator for set
const setPropType = (props, propName, componentName) => {
  if (!(props[propName] instanceof Set)) {
    console.warn(`Invalid prop '${propName}' supplied to '${componentName}'. Expected a Set.`);
  }
  return null;
};

const propTypes = {
  additionalClassName: PropTypes.string,
  hasFullWidthButtons: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  onClickSelectionButton: PropTypes.func,
  selectedButtons: setPropType,
  buttons: PropTypes.objectOf(PropTypes.string),
};

const ButtonContainer = ({
  additionalClassName,
  hasFullWidthButtons,
  isMultiSelect,
  onClickSelectionButton,
  selectedButtons,
  buttons,
}) => {
  if (!buttons) {
    return null;
  }

  const getButtons = () => {
    const buttonArray = [];
    for (const [key, value] of Object.entries(buttons)) {
      const buttonClassName = classNames(InputsStyles["selection-button"], {
        [InputsStyles["selected"]]: selectedButtons.has(key),
      });
      buttonArray.push(
        <button className={buttonClassName} onClick={() => onClickSelectionButton(key)} key={key}>
          {value}
        </button>
      );
    }
    return buttonArray;
  };

  const buttonContainerClassName = classNames(InputsStyles["button-container"], {
    [additionalClassName]: additionalClassName,
    hasFullWidthButtons: hasFullWidthButtons,
    isMultiSelect: isMultiSelect,
  });

  return <div className={buttonContainerClassName}>{getButtons()}</div>;
};

ButtonContainer.propTypes = propTypes;
export default ButtonContainer;
