import { parseISOWithoutTime } from "helpers/date-helpers";
import { isBefore, subYears } from "date-fns";
import { clientSideErrorMessages } from "rtr-constants";

export const validators = {
  firstName: val => {
    const pattern = clientSideErrorMessages.firstName.pattern;
    return pattern.test(val);
  },
  lastName: val => {
    const pattern = clientSideErrorMessages.lastName.pattern;
    return pattern.test(val);
  },
  dateOfBirth: val => {
    const regex = new RegExp(/^[01]?[0-9]\/[0123]?[0-9]\/[12][0-9]{3}$/);
    if (!val || !regex.test(val)) return false;
    //parseISOWithoutTime returns today's date if the date is missing or malformed
    //Needless to say, a newborn is younger than 18
    const dateOfBirth = parseISOWithoutTime(val);
    const latestEligibleBirthDate = subYears(new Date(), 18);
    return isBefore(dateOfBirth, latestEligibleBirthDate);
  },
  zipCode: val => {
    const regex = new RegExp(/^\d{5}$/);
    return regex.test(val);
  },
  cellPhoneNumber: val => {
    const regex = new RegExp(/^\d{10}$/g);
    return regex.test(val);
  },
  weight: val => {
    const regex = new RegExp(/^[1-9]\d+$/);
    const numberVal = parseInt(val, 10);
    // backend validation has range of 50-400
    return !val || (regex.test(val) && numberVal > 50 && numberVal < 400);
  },
  primarySize: val => !!val,
  height: val => !!val,
  bodyType: val => !!val,
};

export const errorMessages = {
  firstName: clientSideErrorMessages.firstName.error,
  lastName: clientSideErrorMessages.lastName.error,
  dateOfBirth: "Please enter a birthday 18 years or older (MM/DD/YYYY)",
  zipCode: "Please enter a 5-digit number or leave blank",
  cellPhoneNumber: "Please enter a 10-digit number with no special characters or leave blank",
  weight: "Please enter a number from 50 to 400 or leave blank",
  primarySize: "Please select a primary size",
  height: "Please select a height",
  bodyType: "Please select a body type",
};
