import React from "react";
import PropTypes from "prop-types";
import AtomButton from "components/source/atoms/atom-button";
import { assetBaseUrls } from "rtr-constants";
import RtrImage from "../shared/rtr-image";

const propTypes = {
  isSubscriptionMember: PropTypes.bool,
};

const HomePickupNoActiveOrders = ({ isSubscriptionMember }) => {
  const hedText = isSubscriptionMember ? "You have no active returns" : "You have no active orders";
  const copyText = isSubscriptionMember
    ? "To schedule a pickup, swap your items at home and confirm a new shipment."
    : "Next time you’ve got returns, schedule a complimentary home pickup and we’ll come to you!";
  const copyClass = isSubscriptionMember ? "copy" : "copy narrow";

  return (
    <div className="home-pickup-no-active-orders">
      <RtrImage
        className="no-active-orders-icon"
        alt="No Active Home Pickup Orders"
        src={`${assetBaseUrls.DESIGNER_IMAGES}/HPU_NoActiveOrders_20230313.svg`}
      />
      <h4 className="header">{hedText}</h4>
      <p className={copyClass}>{copyText}</p>
      <a href="https://rtrshift.com/rtr-home-pickup/">
        <AtomButton buttonText="Home Pickup 101" />
      </a>
    </div>
  );
};

HomePickupNoActiveOrders.propTypes = propTypes;
export default HomePickupNoActiveOrders;
