import React, { useEffect } from "react";
import PropTypes from "prop-types";

import DropdownFormSelect from "../inputs/dropdown-form-select";
import DoubleDropdownFormSelect from "../inputs/double-dropdown-form-select";
import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";

import {
  FIT_INPUT_STANDALONE_CONFIG,
  FIT_PROFILE_KEY_NAMES,
  formatBustSize,
  formatFitProfile,
  formatBirthday,
  NESTED_PROFILE_KEY_NAMES,
  isProfileValid,
} from "./fit-profile-helpers";

import FitProfileStyles from "./fit-profile.module.scss";
import InputContainer from "../inputs/input-container";
import InputStyles from "../inputs/inputs.module.scss";

const propTypes = {
  fitProfile: PropTypes.object,
  setFitProfile: PropTypes.func,
  setHasFitProfileError: PropTypes.func,
  showEditForm: PropTypes.bool,
};

const FitProfile = ({ fitProfile, setFitProfile, setHasFitProfileError, showEditForm }) => {
  const [showEditFitProfile, setShowEditFitProfile] = React.useState(showEditForm);
  const requiredFields = [
    FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH,
    FIT_PROFILE_KEY_NAMES.HEIGHT,
    FIT_PROFILE_KEY_NAMES.PRIMARY_SIZE,
  ];
  useEffect(() => {
    setShowEditFitProfile(showEditForm);
  }, [showEditForm]);

  useEffect(() => {
    if (showEditFitProfile) {
      setHasFitProfileError(!isProfileValid(requiredFields, fitProfile));
    }
  }, [fitProfile]);

  // formating values to be saved within the form
  const savedValueFormatters = (name, newValue, oldValue) => {
    switch (name) {
      case FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH:
        return formatBirthday(newValue);
      case FIT_PROFILE_KEY_NAMES.WEIGHT:
        return newValue ? newValue.replace(/\D+/g, "") : null;
      case FIT_PROFILE_KEY_NAMES.BUST_SIZE:
        return formatBustSize(oldValue, newValue);
      default:
        return newValue;
    }
  };

  // formating values to be displayed in the input
  const displayedValueFormatter = (name, label) => {
    switch (name) {
      case FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH:
        return formatBirthday(label);
      case FIT_PROFILE_KEY_NAMES.BAND_SIZE:
        return label?.replace(/\D+/g, "");
      case FIT_PROFILE_KEY_NAMES.CUP_SIZE:
        return label?.replace(/\d+/g, "");
      default:
        return label;
    }
  };

  const onFieldUpdated = (e, name, value) => {
    if (typeof e?.preventDefault === "function") {
      e.preventDefault();
    }
    const key = name?.length ? name : e?.target?.name;
    const selectedValue = value?.length ? value : e?.target?.value;

    if (NESTED_PROFILE_KEY_NAMES.includes(key)) {
      setFitProfile(oldProfile => ({
        ...oldProfile,
        profiles: {
          ...oldProfile.profiles,
          [key]: savedValueFormatters(key, selectedValue, oldProfile.profiles[key]),
        },
      }));
    } else {
      setFitProfile(oldProfile => ({
        ...oldProfile,
        [key]: savedValueFormatters(key, selectedValue, oldProfile[key]),
      }));
    }
  };

  const renderFitInput = () => {
    return FIT_INPUT_STANDALONE_CONFIG.map(inputDetails => {
      if (!inputDetails?.name) {
        return;
      }
      const value = NESTED_PROFILE_KEY_NAMES.includes(inputDetails.name)
        ? fitProfile.profiles[inputDetails.name]
        : fitProfile[inputDetails.name];
      const labelProps = {
        headerText: inputDetails.labelText,
        isRequired: inputDetails.required,
      };
      let input;
      if (inputDetails.firstOptionProps) {
        const onChangeHandler = e => onFieldUpdated(e, inputDetails.name);
        input = (
          <DoubleDropdownFormSelect
            id={inputDetails.name}
            firstOptionProps={{
              ...inputDetails.firstOptionProps,
              onChange: onChangeHandler, // with the double inputs, we want the inputs to be changing a single key using two inputs. The displayedValueFormatter will handle how it should change
              value: displayedValueFormatter(inputDetails.firstOptionProps.name, value),
            }}
            secondOptionProps={{
              ...inputDetails.secondOptionProps,
              onChange: onChangeHandler,
              value: displayedValueFormatter(inputDetails.secondOptionProps.name, value),
            }}
          />
        );
      } else if (inputDetails.type === "select") {
        input = (
          <DropdownFormSelect
            labelProps={labelProps}
            value={displayedValueFormatter(inputDetails.name, value)}
            onChange={onFieldUpdated}
            name={inputDetails.name}
            options={inputDetails.options}
          />
        );
      } else {
        input = (
          <AnimatedTextInputWrapper
            additionalClassName={InputStyles["text-field"]}
            alwaysShowMask={inputDetails?.alwaysShowMask}
            helperText={inputDetails.placeholder}
            id={inputDetails.name}
            label={""} // to avoid animation for now, we will return a null label
            mask={inputDetails?.mask ?? null}
            maskChar={inputDetails?.maskChar}
            maskedInput={!!inputDetails?.mask}
            name={inputDetails.name}
            onBlurCallback={value => onFieldUpdated({}, inputDetails.name, value)}
            onChangeCallback={value => onFieldUpdated({}, inputDetails.name, value)}
            required={inputDetails.required}
            type={inputDetails.type}
            validateInput={inputDetails.validator}
            validationErrorMsg={inputDetails.errorMessage}
            value={displayedValueFormatter(inputDetails.name, value)}
          />
        );
      }

      return (
        <InputContainer key={inputDetails.name} labelProps={labelProps} isInlineInput={true}>
          {input}
        </InputContainer>
      );
    });
  };

  const filteredFitProfile = Object.values(formatFitProfile(fitProfile)).filter(val => val);

  return (
    <div className={FitProfileStyles["fit-profile-container"]}>
      <div className={FitProfileStyles["label-container"]}>
        <div className={FitProfileStyles["header-container"]}>
          <p className={FitProfileStyles["header"]}>Your Fit Profile</p>
          {!showEditFitProfile && (
            <button className={FitProfileStyles["header"]} onClick={() => setShowEditFitProfile(oldBool => !oldBool)}>
              Update Profile
            </button>
          )}
        </div>
        <p className={FitProfileStyles["sub-header"]}>Included on your review to help others find the right fit.</p>
      </div>
      {!showEditFitProfile ? (
        <div className={FitProfileStyles["fit-profile-values-container"]}>
          {filteredFitProfile.map((value, index) => (
            <React.Fragment key={index}>
              <span>{value}</span>
              {index !== filteredFitProfile.length - 1 && <div className={FitProfileStyles["vertical-border"]} />}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className={FitProfileStyles["fit-profile-inputs-container"]} data-test-id="fit-profile-form">
          {renderFitInput()}
        </div>
      )}
    </div>
  );
};

FitProfile.propTypes = propTypes;
export default FitProfile;
