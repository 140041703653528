import React from "react";
import PropTypes from "prop-types";
import SurveyControls from "./survey-controls";
import FitFeedback from "./fit-feedback";
import OtherIssues from "./other-issues";
import PanelCheckbox from "components/source/atoms/atom-panel-checkbox";
import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";
import { happinessSurveyValues } from "rtr-constants";

const propTypes = {
  lastPageViewed: PropTypes.string.isRequired,
  completeSurvey: PropTypes.func.isRequired,
  addFeedback: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  currentSurvey: PropTypes.object,
  isAccessory: PropTypes.string.isRequired,
  prevPage: PropTypes.string.isRequired,
  goToPage: PropTypes.func,
};

export default class AdditionalComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textCount: 0,
      text: "",
      visitedFromFitFeedback: false,
      visitedFromOtherIssues: false,
      clickedOnFitFeedback: false,
      clickedOnOtherIssues: false,
    };
  }

  static get pageName() {
    return "AdditionalComment";
  }

  static get baseClass() {
    return "additional-comment";
  }

  static get maxLength() {
    return 1024;
  }

  componentDidUpdate() {
    const { lastPageViewed } = this.props;
    // When we arrive to additional-comment from fit feedback
    const visitedFromFitFeedback = lastPageViewed === FitFeedback.pageName;
    if (visitedFromFitFeedback !== this.state.visitedFromFitFeedback) {
      this.setState({ visitedFromFitFeedback });
    }

    const visitedFromOtherIssues = lastPageViewed === OtherIssues.pageName;

    if (visitedFromOtherIssues && !this.state.visitedFromOtherIssues) {
      this.setState({ visitedFromOtherIssues });
    }
  }

  surveyFeedbackWasPositive() {
    const happinessLevels = happinessSurveyValues.wornHappinessLevel;
    const feedback = this.props.currentSurvey?.surveyFeedbacks ?? [];
    return feedback.find(f => f.feedback === happinessLevels.LIKED_IT || f.feedback === happinessLevels.LOVED_IT);
  }

  handleClickBack() {
    this.props.goToPage(this.props.prevPage);
  }

  handleDone() {
    this.props.completeSurvey();
    // Reset state for next item
    this.setState({
      textCount: 0,
      text: "",
      visitedFromFitFeedback: false,
      visitedFromOtherIssues: false,
      clickedOnFitFeedback: false,
      clickedOnOtherIssues: false,
    });
  }

  handleUserInput = e => {
    const input = e.target.value;
    this.props.addFeedback(input);
    this.setState({
      text: input,
      textCount: input.length,
    });
  };

  handleClickFitFeedBack() {
    this.props.goToPage(FitFeedback.pageName);
    this.setState({ clickedOnFitFeedback: true });
  }

  handleClickOtherIssues() {
    this.props.goToPage(OtherIssues.pageName);
    this.setState({ clickedOnOtherIssues: true });
  }

  willNotRenderFitFeedback() {
    // We want to hide fit feedback if we came from the fit feedback page and didn't get there from the additional comment page
    return this.props.isAccessory || (this.state.visitedFromFitFeedback && !this.state.clickedOnFitFeedback);
  }

  renderFitFeedback() {
    if (this.willNotRenderFitFeedback()) {
      return;
    }
    return (
      <div className={`${this.constructor.baseClass}__fit`}>
        <PanelCheckbox
          checked={this.state.clickedOnFitFeedback}
          onClick={() => {
            this.handleClickFitFeedBack();
          }}
          displayText={"Give Fit Feedback"}
          value={"Give Fit Feedback"}
        />
      </div>
    );
  }

  willNotRenderQualityIssue() {
    // We want to hide other issues if we came from the other issues page and didn't get there from the additional comment page
    return this.state.visitedFromOtherIssues && !this.state.clickedOnOtherIssues;
  }

  renderQualityIssue() {
    if (this.willNotRenderQualityIssue()) {
      return;
    }
    return (
      <div className={`${this.constructor.baseClass}__quality`}>
        <PanelCheckbox
          checked={this.state.clickedOnOtherIssues}
          onClick={() => {
            this.handleClickOtherIssues();
          }}
          displayText={"Report Quality Issue"}
          value={"Report Quality Issue"}
        />
      </div>
    );
  }

  renderTextarea() {
    const rows = 5;
    const textCount = (
      <div className={`${this.constructor.baseClass}__comment-box-count`}>
        {this.state.textCount}/{this.constructor.maxLength}
      </div>
    );

    return (
      <div className={`${this.constructor.baseClass}__comment-box`}>
        <textarea
          maxLength={this.constructor.maxLength}
          onChange={this.handleUserInput}
          placeholder="This is optional"
          rows={rows}>
          {this.state.text}
        </textarea>
        {textCount}
      </div>
    );
  }

  willRender() {
    return this.props.currentPage === this.constructor.pageName;
  }

  renderHeaderText() {
    const { currentSurvey } = this.props;

    const headerQuestion = "Anything else we should know?";

    const likedOrLovedFeedback = [
      happinessSurveyValues.wornHappinessLevel.LIKED_IT,
      happinessSurveyValues.wornHappinessLevel.LOVED_IT,
    ];

    if (happinessSurveyContainsFeedback(currentSurvey, likedOrLovedFeedback)) {
      return `Great! ${headerQuestion}`;
    }

    const nonFitProblemFeedback = [
      happinessSurveyValues.negativeFeedback.NOT_MY_STYLE,
      happinessSurveyValues.negativeFeedback.NOT_FLATTERING,
      happinessSurveyValues.negativeFeedback.DIDNT_ARRIVE_ON_TIME,
      happinessSurveyValues.negativeFeedback.WORE_SOMETHING_ELSE,
    ];

    if (happinessSurveyContainsFeedback(currentSurvey, nonFitProblemFeedback)) {
      return `Got it! ${headerQuestion}`;
    }

    return headerQuestion;
  }

  render() {
    if (!this.willRender()) {
      return null;
    }

    const skipText = "Done";

    return (
      <div className={this.constructor.baseClass}>
        <SurveyControls
          willShowBack={true}
          willShowSkip={true}
          onClickBack={() => {
            this.handleClickBack();
          }}
          onClickSkip={() => {
            this.handleDone();
          }}
          skipText={skipText}
        />
        <div className={`${this.constructor.baseClass}__copy h4`}>{this.renderHeaderText()}</div>
        {this.renderFitFeedback()}
        {this.renderQualityIssue()}
        {this.renderTextarea()}
        <div className={`${this.constructor.baseClass}__done`}>
          <button
            onClick={() => {
              this.handleDone();
            }}
            className="btn">
            All Set!
          </button>
        </div>
      </div>
    );
  }
}

AdditionalComment.propTypes = propTypes;
AdditionalComment.defaultProps = {
  lastPageViewed: "SurveyThanks",
};
