import FormattingUtils from "helpers/FormattingUtils";
import PropTypes from "prop-types";
import { parseISO, differenceInYears } from "date-fns";

const propTypes = {
  review: PropTypes.object,
  isUserSpecificReview: PropTypes.bool.isRequired,
};

const ReviewerStats = ({ review, isUserSpecificReview }) => {
  const reviewerSnapshot = isUserSpecificReview ? review?.reviewerSnapshot : review?.user;
  const reviewMoment = isUserSpecificReview ? review : review?.moment;

  return (
    <div className="reviewer-stats">
      <ul>
        {reviewerSnapshot?.heightInches && (
          <li className="reviewer-stat">
            <span className="label">Height</span>
            <span className="stat">{FormattingUtils.formatHeight(reviewerSnapshot.heightInches)}</span>
          </li>
        )}
        {reviewerSnapshot?.weightPounds && (
          <li className="reviewer-stat">
            <span className="label">Weight</span>
            <span className="stat">{reviewerSnapshot.weightPounds} lbs</span>
          </li>
        )}
        {reviewerSnapshot?.bustSize && (
          <li className="reviewer-stat">
            <span className="label">Bust</span>
            <span className="stat">{reviewerSnapshot.bustSize}</span>
          </li>
        )}
        {reviewerSnapshot?.bodyType && (
          <li className="reviewer-stat">
            <span className="label">Body Type</span>
            <span className="stat">{reviewerSnapshot.bodyType}</span>
          </li>
        )}
        {reviewerSnapshot?.birthday && (
          <li className="reviewer-stat">
            <span className="label">Age</span>
            <span className="stat">{differenceInYears(new Date(), parseISO(reviewerSnapshot.birthday))}</span>
          </li>
        )}
      </ul>
      <ul>
        {reviewMoment?.sizeWorn && (
          <li className="reviewer-stat">
            <span className="label">Size Worn</span>
            <span className="stat">{reviewMoment.sizeWorn}</span>
          </li>
        )}
        {reviewerSnapshot?.usStandardSize && (
          <li className="reviewer-stat">
            <span className="label">Usual Size</span>
            <span className="stat">{reviewerSnapshot.usStandardSize}</span>
          </li>
        )}
        {reviewMoment?.fit && (
          <li className="reviewer-stat">
            <span className="label">Overall Fit</span>
            <span className="stat">{reviewMoment.fit}</span>
          </li>
        )}
        {reviewMoment?.eventType && (
          <li className="reviewer-stat">
            <span className="label">Worn To</span>
            <span className="stat">{reviewMoment.eventType}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

ReviewerStats.propTypes = propTypes;

export default ReviewerStats;
