import PropTypes from "prop-types";
import { productPropType } from "./product-prop-type";
import { ratingMapPropType } from "./rating-map-prop-type";

export const itemSurveyPropType = PropTypes.shape({
  bookingId: PropTypes.number,
  created: PropTypes.string,
  id: PropTypes.string,
  inProgress: PropTypes.bool,
  product: productPropType,
  ratingMaps: ratingMapPropType,
  size: PropTypes.string,
  source: PropTypes.string,
  status: PropTypes.string,
  surveyFeedbacks: PropTypes.arrayOf(
    PropTypes.shape({
      bookingId: PropTypes.number,
      feedback: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  userId: PropTypes.number,
  version: PropTypes.string,
});
