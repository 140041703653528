import React from "react";
import PropTypes from "prop-types";
import ReviewCropPhoto from "./review-crop-photo";
import ActionLogger from "action-logger";
import { analytics } from "rtr-constants";
import { MODAL_NAMES } from "rtr-constants/modalNames";

export default class ReviewCropPhotosModal extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    onSetCoverPhoto: PropTypes.func.isRequired,
    onSubmitCroppedReviewPhoto: PropTypes.func.isRequired, // (photoId, formData) => {}
    onSuccess: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        isFeatured: PropTypes.bool,
        photoId: PropTypes.number,
        urls: PropTypes.shape({
          image: PropTypes.string,
        }),
      })
    ).isRequired,
    reviewId: PropTypes.number.isRequired,
    styleName: PropTypes.string,
  };

  static defaultProps = {
    photos: [],
  };

  static MODAL_NAME = MODAL_NAMES.REVIEW_CROP_PHOTOS;

  state = {
    coverPhotoId: null,
    croppedPhotos: {},
  };

  onSubmit = () => {
    if (this.state.coverPhotoId) {
      this.props.onSetCoverPhoto(this.props.reviewId, this.state.coverPhotoId).catch(response => {
        ActionLogger.logAction({
          object_type: analytics.OBJECT_TYPE.REVIEWS,
          action: analytics.ACTION_TYPE.ERROR,
          styleName: this.props.styleName,
          errorMessage: "postCoverPhoto failed: " + JSON.stringify(response),
        });
      });
    }

    const croppedPhotoIds = Object.keys(this.state.croppedPhotos);
    if (croppedPhotoIds.length) {
      for (let i = 0; i < croppedPhotoIds.length; i++) {
        const photoId = croppedPhotoIds[i];
        const cropDetails = this.state.croppedPhotos[photoId];
        if (cropDetails) {
          this.props.onSubmitCroppedReviewPhoto(photoId, cropDetails).catch(response => {
            ActionLogger.logAction({
              object_type: analytics.OBJECT_TYPE.REVIEWS,
              action: analytics.ACTION_TYPE.ERROR,
              styleName: this.props.styleName,
              errorMessage: "postCropForImage failed: " + JSON.stringify(response),
            });
          });
        }
      }
    }

    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.REVIEWS,
      action: "done_crop",
      styleName: this.props.styleName,
    });
    this.props.onSuccess();
  };

  onCropPhoto = (photoId, normalizedCropArgs) => {
    const mutatedCroppedPhotoState = {
      ...this.state.croppedPhotos,
      [photoId]: normalizedCropArgs,
    };
    this.setState({
      croppedPhotos: mutatedCroppedPhotoState,
    });
  };

  onCoverPhotoChange = photoId => {
    this.setState({
      coverPhotoId: photoId,
    });
  };

  renderCropPhoto(photo) {
    const radioId = `radio-cover-photo-${photo.photoId}`;
    const isSelectedAsCoverPhoto = this.state.coverPhotoId === photo.photoId || photo.isFeatured;

    return (
      <div key={photo.photoId}>
        <ReviewCropPhoto
          photo={photo}
          onCropPhoto={normalizedCropArgs => this.onCropPhoto(photo.photoId, normalizedCropArgs)}
        />
        <div className="cover-photo-radio">
          <input
            type="radio"
            id={radioId}
            checked={isSelectedAsCoverPhoto}
            onChange={() => this.onCoverPhotoChange(photo.photoId)}
          />
          <label htmlFor={radioId}>Make this the cover photo.</label>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="review-crop-photos">
        <div className="header-box">
          <div className="header-text-box">
            <div className="statement-headline-light">Looking good! One last step...</div>
            <div className="header-subtext">Use our cropping tool to make your moment runway ready.</div>
          </div>
        </div>
        <div className="review-crop-photos__photos">{this.props.photos.map(photo => this.renderCropPhoto(photo))}</div>
        <button className="submit-crop btn" onClick={this.onSubmit} disabled={this.props.loading}>
          Update
        </button>
      </div>
    );
  }
}
