import React from "react";
import PropTypes from "prop-types";

class SurveyControls extends React.Component {
  static propTypes = {
    willShowBack: PropTypes.bool,
    willShowSkip: PropTypes.bool,
    onClickBack: PropTypes.func.isRequired,
    onClickSkip: PropTypes.func.isRequired,
    skipText: PropTypes.string,
  };

  renderBackControl = () => {
    if (this.props.willShowBack) {
      return (
        <button onClick={this.props.onClickBack} className="survey-controls__back title">
          Back
        </button>
      );
    }
  };

  renderSkipControl = () => {
    if (this.props.willShowSkip) {
      const skipText = this.props.skipText ? this.props.skipText : "Skip";
      return (
        <button onClick={this.props.onClickSkip} className="survey-controls__skip title">
          {skipText}
        </button>
      );
    }
  };

  render() {
    return (
      <div className="survey-controls">
        {this.renderBackControl()}
        {this.renderSkipControl()}
      </div>
    );
  }
}

export default SurveyControls;
