import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "underscore";
import PlusSign from "images/plus_sign.svg";
import { hasFileSizeError } from "actions/shared-actions";
import RtrImage from "../shared/rtr-image";

export class PhotoUploader extends React.Component {
  constructor(props) {
    super(props);
    this.photouploader = React.createRef();
  }
  static propTypes = {
    deletePhoto: PropTypes.func.isRequired, // (photo, eventArgs) => {}
    hasFileSizeError: PropTypes.bool,
    onSizeUploadError: PropTypes.func,
    loadingPhotos: PropTypes.bool,
    loadingRotatedPhoto: PropTypes.bool,
    maxPhotos: PropTypes.number.isRequired,
    reviewPhotoError: PropTypes.string,
    reviewPhotos: PropTypes.arrayOf(
      PropTypes.shape({
        photoId: PropTypes.number,
        photoUrl: PropTypes.string,
      })
    ),
    rotatePhoto: PropTypes.func.isRequired, // photoId => {}
    submitPhoto: PropTypes.func.isRequired, // photo => {}
  };

  static defaultProps = {
    reviewPhotos: [],
  };

  state = {
    rotatedPhotos: {},
  };

  readFiles = e => {
    const photoCount = this.props.reviewPhotos ? this.props.reviewPhotos.length : 0,
      remainingPhotoCount = Math.max(0, this.props.maxPhotos - photoCount);
    e.preventDefault();

    _.chain(e.target.files)
      .take(remainingPhotoCount)
      .each(photo => {
        this.readPhoto(photo);
      })
      .value();
    this.photouploader.current.value = "";
  };

  readPhoto = photo => {
    const reader = new FileReader();
    const photoSizeLimitInMB = 5242800;
    const readerLoadEnd = () => {
      if (photo.size < photoSizeLimitInMB) {
        this.processPhoto({ data: reader.result, filename: photo.name, type: photo.type });
      } else {
        this.props.onSizeUploadError();
        return;
      }
      reader.removeEventListener("loadend", readerLoadEnd);
      reader.removeEventListener("error", readerError);
    };
    const readerError = () => {
      reader.removeEventListener("loadend", readerLoadEnd);
      reader.removeEventListener("error", readerError);
    };

    reader.addEventListener("loadend", readerLoadEnd);
    reader.addEventListener("error", readerError);
    reader.readAsDataURL(photo);
  };

  processPhoto = photo => {
    const image = new Image();
    const imageLoad = () => {
      image.removeEventListener("load", imageLoad);
      image.removeEventListener("error", imageError);

      this.props.submitPhoto(photo);
    };
    const imageError = function () {
      // ERROR HANDLING
      image.removeEventListener("load", imageLoad);
      image.removeEventListener("error", imageError);
    };

    image.src = photo.data;
    image.addEventListener("load", imageLoad);
    image.addEventListener("error", imageError);
  };

  renderError = () => {
    if (this.props.reviewPhotoError) {
      return <div className="photo-uploader__error">{this.props.reviewPhotoError}</div>;
    }
  };

  renderAddPhotoButton = () => {
    let loading;

    if (this.props.loadingPhotos) {
      loading = (
        <div className="photo-uploader__add-button">
          <div className="photo-uploader__add-button__loading"></div>
        </div>
      );
    }

    if (this.props.reviewPhotos?.length < this.props.maxPhotos) {
      return (
        <label
          htmlFor="photo-uploader__input"
          className="photo-uploader__input__label"
          data-test-id="photo-uploader__input_label">
          {loading}
          {this.props.reviewPhotos?.length === this.props.maxPhotos - 1 && this.props.loadingPhotos ? null : (
            <div className="photo-uploader__add-button">
              <PlusSign />
              <div className="universal-xsmall">Add a photo</div>
            </div>
          )}
        </label>
      );
    }
  };

  onRotatePhoto = (photoId, e) => {
    e?.preventDefault();
    this.props.rotatePhoto(photoId);
    // NW [EXPLANATION] 8/4/21: thumbnails are not re-generated for photos that are successfully rotated. so we are using component state and css for visual feedback
    const currentRotation = this.state.rotatedPhotos[photoId];
    const newRotation = currentRotation ? (currentRotation + 90) % 360 : 90;
    this.setState({
      rotatedPhotos: {
        ...this.state.rotatedPhotos,
        [`${photoId}`]: newRotation,
      },
    });
  };

  renderSizeError() {
    if (this.props.hasFileSizeError) {
      return (
        <div className="file-size-error universal-small">
          <p className="file-size-error-heading">Your photo is too big</p>
          <p className="file-size-error-subtext xsmall">
            Upload a new photo that does not exceed <br />
            the 5MB limit.
          </p>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="photo-uploader" data-test-id="photo-uploader">
        {this.renderError()}
        <input
          onChange={this.readFiles}
          type="file"
          name="photos"
          ref={this.photouploader}
          id="photo-uploader__input"
          className="photo-uploader__input"
          accept="image/*"
          multiple
          data-test-id="photo-uploader__input"
        />
        <div className="photo-uploader__photos">
          {this.props.reviewPhotos.map((photo, i) => {
            const loading = this.props.loadingRotatedPhoto === photo.photoId;

            const imageClassName = classNames("photo-uploader__photo__image", {
              "photo-uploader__photo__image--loading": loading,
              [`photo-uploader__photo__image--rotate-${this.state.rotatedPhotos[photo.photoId]}`]: this.state
                .rotatedPhotos[photo.photoId],
            });

            return (
              <div className="photo-uploader__photo" key={"photo-" + i}>
                <button
                  type="button"
                  className="photo-uploader__rotate-button"
                  onClick={e => this.onRotatePhoto(photo.photoId, e)}>
                  ↻
                </button>
                <div className={imageClassName} data-test-id="photo-uploader__photo__image">
                  <RtrImage src={photo.photoUrl} alt={"review photo" + i} key={"photo-img-" + i} />
                </div>
                <button
                  type="button"
                  className="photo-uploader__remove-button"
                  onClick={e => this.props.deletePhoto(photo, e)}>
                  ✕
                </button>
              </div>
            );
          })}
          {this.renderAddPhotoButton()}
        </div>
        {this.renderSizeError()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { hasFileSizeError } = state;

  return {
    hasFileSizeError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSizeUploadError: () => {
      dispatch(hasFileSizeError(true));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PhotoUploader);
