import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { isMasquerading, useUserData } from "../hoc/with-user-data";
import { initializeCastleSDK } from "../../../actions/on-initialization-actions";

function UserDevices() {
  const dispatch = useDispatch();
  const userData = useUserData();

  useEffect(() => {
    // Wait for calls to fetch feature flag state and auth data state to both return before proceeding
    if (!userData?.authDataHasLoaded) {
      return;
    }

    dispatch(initializeCastleSDK(isMasquerading(userData)));
  }, [userData?.authDataHasLoaded, isMasquerading(userData)]);

  return null;
}

export default UserDevices;
