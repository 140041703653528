import { Component } from "react";
import { connect } from "react-redux";
import { displayModal } from "actions/shared-actions";
import PropTypes from "prop-types";
import ClosableModal from "components/source/shared/closable-modal";
import AtomFullWidthLinkButton from "components/source/atoms/atom-full-width-link-button";
import ReferralAdvocateShareIcons from "images/referrals/referral_advocate_share_icons.svg";
import { Referrals, Home } from "routes";
import ActionLogger from "action-logger";
import { analytics } from "rtr-constants";
import actionUtils from "actions/action-utils";
import { browserPropType, membershipStatePropType } from "components/propTypes";
import RtrImage from "../../shared/rtr-image";

export const REFERRAL_REMINDER_MODAL = "REFERRAL_REMINDER_MODAL";
const NO_MODAL = "noModal";

export class SmartReferralsReminderModal extends Component {
  static propTypes = {
    browser: browserPropType,
    closeModal: PropTypes.func,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    membershipState: membershipStatePropType,
    smartComponentState: PropTypes.shape({
      didFetchSmartReferralsReminderModal: PropTypes.bool,
      referralsReminderModal: PropTypes.shape({
        CTA: PropTypes.string,
        body: PropTypes.string,
        title: PropTypes.string,
        iconURL: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    smartComponentState: {},
  };
  static modalName = REFERRAL_REMINDER_MODAL;

  componentDidUpdate(prevProps) {
    const {
      smartComponentState: { didFetchSmartReferralsReminderModal },
      membershipState: { id, membershipId, membershipTermNumber } = {},
      displayedModal,
    } = this.props;

    if (
      didFetchSmartReferralsReminderModal &&
      displayedModal === this.constructor.modalName &&
      !prevProps?.smartComponentState?.didFetchSmartReferralsReminderModal
    ) {
      ActionLogger.logAction({
        object_type: analytics.OBJECT_TYPE.REFERRAL_MESSAGING_MODAL,
        action: analytics.ACTION_TYPE.VIEW,
        user_id: id,
        membership_id: membershipId,
        term: membershipTermNumber,
      });
    }
  }

  onClickCTA = () => {
    const { membershipState: { id, membershipId, membershipTermNumber } = {} } = this.props;

    ActionLogger.inferAction({
      object_type: analytics.OBJECT_TYPE.REFERRAL_MESSAGING_MODAL,
      action: analytics.ACTION_TYPE.CLICK_CTA,
      user_id: id,
      membership_id: membershipId,
      term: membershipTermNumber,
    });
  };

  onRequestClose = () => {
    const { closeModal } = this.props;
    closeModal();
    actionUtils.safeNavigation(Home);
  };

  renderModalContent() {
    const { smartComponentState } = this.props;
    const ctaText = smartComponentState?.referralsReminderModal?.CTA;
    const bodyText = smartComponentState?.referralsReminderModal?.body;
    const titleText = smartComponentState?.referralsReminderModal?.title;
    const iconURL = smartComponentState?.referralsReminderModal?.iconURL;
    const CTALink = smartComponentState?.referralsReminderModal?.url;

    return (
      <div className="smart-referrals-reminder-modal">
        {iconURL ? <RtrImage src={iconURL} alt="icon" /> : <ReferralAdvocateShareIcons className="hearts-icon" />}
        <div className="top-modal-content">
          <div className="text-content">
            <p className="text-heading">{titleText}</p>
          </div>
          <div className="text-content subtext">
            <p>{bodyText}</p>
          </div>
        </div>
        <div className="btn-container">
          <AtomFullWidthLinkButton
            href={CTALink || Referrals.inviteAFriend}
            buttonText={ctaText}
            onClick={this.onClickCTA}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      browser: { isMobileViewport },
      displayedModal,
      smartComponentState,
    } = this.props;
    let additionalStyles;

    if (
      !smartComponentState?.didFetchSmartReferralsReminderModal ||
      smartComponentState?.referralsReminderModal?.name === NO_MODAL
    ) {
      return null;
    }

    if (isMobileViewport) {
      additionalStyles = {
        content: {
          height: "100%",
          padding: "0 20px",
        },
      };
    }

    return (
      <ClosableModal
        isOpen={displayedModal === this.constructor.modalName}
        optionalClass="modal-close-circle"
        optionalWrapperClass="referral-celebration-modal"
        additionalStyles={additionalStyles}
        onRequestClose={this.onRequestClose}>
        {this.renderModalContent()}
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  const { browser, displayedModal, membershipState, smartComponentState } = state;

  return {
    browser,
    displayedModal,
    membershipState,
    smartComponentState,
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(displayModal(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartReferralsReminderModal);
