import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  analytics,
  FORCE_PICKUP_SCHEDULER_PARAM,
  HomePickupOrderType,
  HPUEditAddressConfirmHandlerTypes,
  flagsAndExperimentServerError,
} from "rtr-constants";
import ActionLogger from "action-logger";
import { flagsAndExperimentsPropType, flagsAndExperimentNames } from "../hoc/with-flags-and-experiments";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";

import { isSubscriptionMember } from "helpers/membership-helpers";
import HomePickupActions from "actions/home-pickup-actions";
import HomePickupAlreadyScheduled from "components/source/home_pickup/home-pickup-already-scheduled";

import ReworkedScheduler from "components/source/home_pickup/scheduler/reworked-home-pickup-scheduler";
import HomePickupItemReturn from "components/source/home_pickup/order_return/home-pickup-order-return-container";
import { withQueryParams } from "../hoc/with-query-params";
import { Flags, useFeatureFlag } from "components/source/hoc/with-feature-flags";
import Close from "images/close.svg";
import { hasProfile } from "../hoc/with-user-data";
import HomePickupNoActiveOrders from "./home-pickup-no-active-orders";
import RtrImage from "../shared/rtr-image";

const propTypes = {
  queryParams: PropTypes.shape({
    [FORCE_PICKUP_SCHEDULER_PARAM]: PropTypes.string,
  }),
  flagsAndExperiments: flagsAndExperimentsPropType,
};

function getOrderFilters(userData, flags) {
  if (isSubscriptionMember(userData)) {
    const filters = [HomePickupOrderType.STANDALONE_MEMBERSHIP];
    if (flags.ENABLE_RESERVE_SCHEDULER) {
      filters.push(HomePickupOrderType.RESERVE);
    }
    return filters;
  } else if (flags.ENABLE_RESERVE_SCHEDULER && !isSubscriptionMember(userData) && hasProfile(userData)) {
    return [HomePickupOrderType.RESERVE];
  } else {
    return [];
  }
}

const HomePickupStandaloneContainer = ({ queryParams, flagsAndExperiments }) => {
  const [showError, setShowError] = React.useState(false);
  const [showScheduler, setShowScheduler] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState({});
  const [initialPhoneNumber, setInitialPhoneNumber] = React.useState();

  const dispatch = useDispatch();

  // hpu data
  const orders = useSelector(state => state.homePickupEligibleOrders);
  const homePickupEligibility = useSelector(state => state.homePickup?.homePickupEligibility);
  const homePickupAddress = useSelector(state => state.homePickupAddress);

  // display bools
  const isHomePickupSchedulerLoading = useSelector(state => state.homePickup?.isSchedulerLoading);
  const displayHomePickupOverlay = useSelector(state => state.displayHomePickupOverlay);
  const displayHomePickupServerError = useSelector(state => state.displayHomePickupServerError);
  const displayHomePickupAlreadyScheduled = useSelector(state => state.displayHomePickupAlreadyScheduled);
  const displayHomePickupNoActiveOrders = useSelector(state => state.displayHomePickupNoActiveOrders);

  // flags and userData
  const userData = useSelector(state => state.userData);
  const ENABLE_RESERVE_SCHEDULER = useFeatureFlag(Flags.ENABLE_RESERVE_SCHEDULER);

  React.useEffect(() => {
    if (typeof flagsAndExperiments?.[flagsAndExperimentNames.USE_HOME_PICKUP_POSTAL_CODE_GROUPS] === "undefined") {
      dispatch(
        FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.USE_HOME_PICKUP_POSTAL_CODE_GROUPS)
      );
    } else if (
      flagsAndExperiments?.[flagsAndExperimentNames.USE_HOME_PICKUP_POSTAL_CODE_GROUPS] ===
        flagsAndExperimentServerError &&
      displayHomePickupOverlay
    ) {
      dispatch(HomePickupActions.onHomePickupErrors());
    } else if (
      typeof flagsAndExperiments?.[flagsAndExperimentNames.USE_HOME_PICKUP_POSTAL_CODE_GROUPS] !== "undefined" &&
      displayHomePickupOverlay
    ) {
      handleCheckHomePickupEligibleOrders();
    }
  }, [flagsAndExperiments?.[flagsAndExperimentNames.USE_HOME_PICKUP_POSTAL_CODE_GROUPS]]);

  // checking for the url if we want to open hpu scheduler
  // if getOrderFilters returns any filters, then we know the overlay can be shown for that particular user
  React.useEffect(() => {
    if (
      queryParams?.[FORCE_PICKUP_SCHEDULER_PARAM] &&
      getOrderFilters(userData, {
        ENABLE_RESERVE_SCHEDULER,
      }).length > 0
    ) {
      dispatch(HomePickupActions.displayHomePickupOverlay(true));
    }
  }, []);

  // watching whenever the standalone overlay opens
  React.useEffect(() => {
    if (displayHomePickupOverlay) {
      //  because we dispatch checkHomePickupEligibleOrders from here,
      //  we need to make sure the postal code groups flag is loaded
      if (typeof flagsAndExperiments?.[flagsAndExperimentNames.USE_HOME_PICKUP_POSTAL_CODE_GROUPS] === "undefined") {
        dispatch(HomePickupActions.setIsHomePickupSchedulerLoading(true));
        return;
      } else {
        handleCheckHomePickupEligibleOrders();
      }
    }
  }, [displayHomePickupOverlay]);

  // automatically make the first order selected if there is only one order, when overlay is shown
  React.useEffect(() => {
    if (orders?.length === 1) {
      initializeSingleOrder();
    }
  }, [orders]);

  // handle instances where we need to immediately show the server error (wires crossed) display (e.g. zero eligible postal codes eligible for home pickup)
  React.useEffect(() => {
    if (displayHomePickupServerError) {
      setShowError(true);
      setShowScheduler(true);
    }
  }, [displayHomePickupServerError]);

  const handleCheckHomePickupEligibleOrders = () => {
    if (homePickupAddress?.editType === HPUEditAddressConfirmHandlerTypes.EDIT_ADDRESS_FROM_STANDALONE_ZIP_ERROR) {
      // if an address was recorded and wasn't corrected, skip calling hpu eligible orders, go straight to hpu unavailable modal
      dispatch(HomePickupActions.displayHomePickupUnavailableModal(homePickupAddress));
    } else if (!orders?.length) {
      const homePickupOrderFilters = getOrderFilters(userData, {
        ENABLE_RESERVE_SCHEDULER,
      });

      if (homePickupOrderFilters.length > 0) {
        // only call /homePickupGroups to get the hpu eligible orders when there are none in the redux store
        dispatch(
          HomePickupActions.checkHomePickupEligibleOrders(
            homePickupOrderFilters,
            flagsAndExperiments?.[flagsAndExperimentNames.USE_HOME_PICKUP_POSTAL_CODE_GROUPS]
          )
        );
      }
    } else if (orders.length === 1) {
      // if there is only one order, and the overlay has been closed, make sure that it's selected again the local state
      initializeSingleOrder();
    }
  };

  const initializeSingleOrder = () => {
    setSelectedOrder(orders[0]);
  };

  const onSelectedOrder = order => setSelectedOrder(order);

  const onReturnItemsCTA = () => {
    const onSuccess = () => {
      setInitialPhoneNumber(homePickupAddress?.phone);
      setShowScheduler(true);
    };
    const onError = () => {
      setShowError(true);
      setShowScheduler(true);
    };

    dispatch(
      HomePickupActions.checkStandaloneHomePickupEligibility(
        homePickupAddress.postalCode,
        selectedOrder.items.map(order => order.bookingId),
        selectedOrder.returnByDate,
        onSuccess,
        onError
      )
    );
  };

  const scheduleHomePickupHandler = (bookingIds, phoneNumber, pickupMethod, pickupType, specialInstructions) => {
    const homePickupOrderType = selectedOrder.pickupOrderType;
    const orderId = selectedOrder.orderId;
    const orderGroupId = homePickupOrderType === HomePickupOrderType.RESERVE ? selectedOrder.orderGroupId : null;
    const pickupAddress = homePickupAddress;
    const email = userData?.userProfile?.email;

    const logData = {
      object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
      action: analytics.ACTION_TYPE.SCHEDULE_HOME_PICKUP_CLICK,
      order_id: orderId,
      zip_code: pickupAddress?.postalCode,
    };
    ActionLogger.logAction(logData);

    const successCallback = () => closeStandaloneOverlay();
    const errorCallback = () => {
      setShowError(true);

      const errorLogData = {
        object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
        action: analytics.ACTION_TYPE.SCHEDULE_HOME_PICKUP_ERROR,
        order_id: orderId,
        zip_code: pickupAddress?.postalCode,
      };
      ActionLogger.logAction(errorLogData);
    };
    dispatch(
      HomePickupActions.scheduleHomePickup(
        bookingIds,
        email,
        errorCallback,
        homePickupEligibility,
        orderId,
        orderGroupId,
        phoneNumber,
        pickupAddress,
        pickupMethod,
        homePickupOrderType,
        pickupType,
        specialInstructions,
        successCallback
      )
    );
  };

  const closeStandaloneOverlay = () => {
    const url = new URL(window.location);
    url.searchParams.delete("pickupScheduler");
    window.history.pushState(null, null, url);
    setShowScheduler(false);
    setShowError(false);
    setSelectedOrder({});
    dispatch(HomePickupActions.closeHomePickupStandaloneScheduler());
  };

  const onClickCancel = () => {
    closeStandaloneOverlay();
    const logData = {
      object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
      action: analytics.ACTION_TYPE.CLICK_CANCEL,
      zip_code: homePickupAddress?.postalCode,
    };
    ActionLogger.logAction(logData);
  };

  const renderScheduler = () => {
    return (
      <ReworkedScheduler
        errorHandler={closeStandaloneOverlay}
        showHomePickupError={showError}
        initialPhoneDigitsOnly={initialPhoneNumber}
        scheduleHomePickup={scheduleHomePickupHandler}
        dismissHomePickupCTA={onClickCancel}
        dismissHomePickupCTAText="Cancel"
      />
    );
  };

  const Loader = () => (
    <div className="loading">
      <RtrImage src="//sf-p.rtrcdn.com/images/loading.gif" alt="Loading animation" />
    </div>
  );

  const renderContents = showScheduler => {
    if (displayHomePickupAlreadyScheduled) {
      return <HomePickupAlreadyScheduled onClose={closeStandaloneOverlay} />;
    }

    if (displayHomePickupNoActiveOrders) {
      return <HomePickupNoActiveOrders isSubscriptionMember={isSubscriptionMember(userData)} />;
    }

    return showScheduler ? (
      renderScheduler()
    ) : (
      <HomePickupItemReturn
        onSelectedOrder={onSelectedOrder}
        orders={orders}
        returnItemsCTA={onReturnItemsCTA}
        selectedOrder={selectedOrder}
      />
    );
  };

  if (!displayHomePickupOverlay) {
    return null;
  }

  return (
    <div className="Home-Pickup-Container">
      <div className="body">
        <div className="head">
          <div className="title-container">
            {showScheduler && !showError && (
              <button onClick={() => setShowScheduler(false)} className="bag-button" aria-label="back" />
            )}
            <div>Schedule a Home Pickup</div>
          </div>
          <button onClick={closeStandaloneOverlay} className="close-button" aria-label="close">
            <Close />
          </button>
        </div>
        {isHomePickupSchedulerLoading ? <Loader /> : renderContents(showScheduler)}
      </div>
      <div className="background visible" />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    flagsAndExperiments: state.flagsAndExperiments,
  };
}

HomePickupStandaloneContainer.propTypes = propTypes;
export default compose(withQueryParams(), connect(mapStateToProps))(HomePickupStandaloneContainer);
