import { isValid, subYears, isBefore } from "date-fns";
import { clientSideErrorMessages, dateFnsFormats } from "rtr-constants";
import { formatYYYYMMDD, getAge, parseISOWithoutTime } from "helpers/date-helpers";

import FormattingUtils from "helpers/FormattingUtils";

/* --- VALIDATORS --- */
export const validateDateOfBirth = value => {
  if (!value) {
    return false;
  }
  const condensedDate = value.replace(/\s/g, "");
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!regex.test(condensedDate)) {
    return false;
  }

  const [month, date, year] = condensedDate.split("/");
  const dateValue = parseISOWithoutTime(`${year}-${month}-${date}`);
  if (!isValid(dateValue)) {
    return false;
  }
  const minimumAgeRequirement = subYears(new Date(), 18);
  // The input is valid if it is a date at least 18 years before today's date
  return isBefore(dateValue, minimumAgeRequirement);
};

export const validateWeight = val => {
  const weight = parseInt(val, 10);
  // backend validation has range of 50-400
  return weight > 50 && weight < 400;
};

export const isProfileValid = (requiredFields, fitProfile) => {
  for (const key of requiredFields) {
    const value = NESTED_PROFILE_KEY_NAMES.includes(key) ? fitProfile.profiles?.[key] : fitProfile[key];
    if (!value || value === "-") {
      return false;
    } else if (key === FIT_PROFILE_KEY_NAMES.WEIGHT && !validateWeight(fitProfile[key])) {
      return false;
    } else if (key === FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH && !validateDateOfBirth(fitProfile[key])) {
      return false;
    }
  }
  return true;
};

/* --- FORMATTERS --- */

// different from user_profile_form/config as this takes in the current bust value,
// checks if the new value is either a band or cup size, and updates accordingly
export const formatBustSize = (oldBustSize, newValue) => {
  if (!oldBustSize) {
    return `${newValue}`;
  } else if (/\d/.test(newValue) && !/\d/.test(oldBustSize) && /\D/.test(oldBustSize)) {
    // if the new value is a digit and there is no digit but letters in the current value
    return `${newValue}${oldBustSize}`;
  } else if (/\D/.test(newValue) && !/\D/.test(oldBustSize) && /\d/.test(oldBustSize)) {
    // if the new value is a non digit and there is non-digit but digits in the current value
    return `${oldBustSize}${newValue}`;
  }

  // if the new value is a digit, replace the band size, otherwise replace the cup size
  const regexp = /\d/.test(newValue) ? /\d+/g : /\D+/g;
  return oldBustSize.replace(regexp, newValue);
};

// similar to reviews/review-upload-modal, just an additional check to see if the received input
// was already a formatted birthday
export const formatBirthday = dateOfBirth => {
  if (!dateOfBirth) {
    return "";
  }
  if (dateOfBirth.match(/^\d{2}\/\d{2}\/\d{4}$/g)) {
    return dateOfBirth;
  }

  // dateOfBirth can have a timestamp (ex: 1995-02-15T00:00:00.000Z)
  // so we need to clean it
  const timeStampIndex = dateOfBirth.indexOf("T") > 0 ? dateOfBirth.indexOf("T") : 10;
  const dateStringWithoutTimeStamp = dateOfBirth.slice(0, timeStampIndex);
  return formatYYYYMMDD(dateStringWithoutTimeStamp, dateFnsFormats.MM_DD_YYYY) || "";
};

export const formatFitProfile = userProfile => {
  if (!userProfile) {
    return {};
  }

  return Object.values(FIT_INPUT_STANDALONE_CONFIG).reduce((prev, curr) => {
    const keyName = curr.name;
    let value;
    if (!userProfile.profiles?.[keyName] && !userProfile[keyName]) {
      return { ...prev };
    }
    switch (keyName) {
      case FIT_PROFILE_KEY_NAMES.PRIMARY_SIZE:
        value = `Size ${userProfile.profiles[keyName]}`;
        break;
      case FIT_PROFILE_KEY_NAMES.WEIGHT:
        value = `${userProfile[keyName]}lbs`;
        break;
      case FIT_PROFILE_KEY_NAMES.HEIGHT:
        value = `${FormattingUtils.formatHeight(userProfile[keyName])}`;
        break;
      case FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH:
        value = `${getAge(userProfile[keyName])} yrs`;
        break;
      default:
        value = userProfile[keyName];
        break;
    }
    return {
      ...prev,
      [keyName]: value,
    };
  }, {});
};

/* --- PARSERS --- */
export const parseUserProfileToFitProfile = userProfile => {
  if (!userProfile) {
    return {};
  }

  return Object.values(FIT_INPUT_STANDALONE_CONFIG).reduce((prev, curr) => {
    if (NESTED_PROFILE_KEY_NAMES.includes(curr.name)) {
      return {
        ...prev,
        profiles: {
          [curr.name]: userProfile.profiles[curr.name],
        },
      };
    }
    return {
      ...prev,
      [curr.name]: userProfile[curr.name],
    };
  }, {});
};

export const parseFitProfileForSubmission = fitProfile => {
  if (!fitProfile) {
    return;
  }

  const keySnakeConverter = key =>
    key.replace(/([A-Z])/g, val => {
      return "_" + val.toLowerCase();
    });

  return Object.values(FIT_INPUT_STANDALONE_CONFIG).reduce((prev, curr) => {
    if (NESTED_PROFILE_KEY_NAMES.includes(curr.name)) {
      return {
        ...prev,
        [`user[profile][${keySnakeConverter(curr.name)}]`]:
          fitProfile.profiles[curr.name] === "-" ? "" : fitProfile.profiles[curr.name],
      };
    }
    return {
      ...prev,
      [`user[${keySnakeConverter(curr.name)}]`]: fitProfile[curr.name] === "-" ? "" : fitProfile[curr.name],
    };
  }, {});
};

/* --- CONSTANTS --- */

export const FIT_PROFILE_KEY_NAMES = {
  DATE_OF_BIRTH: "dateOfBirth",
  HEIGHT: "height",
  PRIMARY_SIZE: "primarySize",
  BODY_TYPE: "bodyType",
  BAND_SIZE: "bandSize",
  CUP_SIZE: "cupSize",
  BUST_SIZE: "bustSize",
  WEIGHT: "weight",
};

export const NESTED_PROFILE_KEY_NAMES = [FIT_PROFILE_KEY_NAMES.PRIMARY_SIZE];

export const FIT_INPUT_STANDALONE_CONFIG = [
  {
    labelText: "Birth date",
    name: FIT_PROFILE_KEY_NAMES.DATE_OF_BIRTH,
    placeholder: "MM/DD/YYYY",
    required: true,
    type: "tel",
    mask: "99/99/9999",
    maskChar: " ",
    alwaysShowMask: true,
    validator: validateDateOfBirth,
    errorMessage: clientSideErrorMessages.formValidation.minAge,
  },
  {
    labelText: "Height",
    name: FIT_PROFILE_KEY_NAMES.HEIGHT,
    options: ["-", ...[...Array(78 - 54 + 1).keys()].map(x => [x + 54, FormattingUtils.formatHeight(x + 54)])],
    required: true,
    type: "select",
  },
  {
    labelText: "Primary size",
    name: FIT_PROFILE_KEY_NAMES.PRIMARY_SIZE,
    options: ["-", ...[...Array(11 + 1).keys()].map(x => `${(x + 0) * 2}`)],
    required: true,
    type: "select",
  },
  {
    labelText: "Body type",
    name: FIT_PROFILE_KEY_NAMES.BODY_TYPE,
    options: ["-", "Apple", "Athletic", "Full Bust", "Hourglass", "Pear", "Petite", "Straight & narrow"],
    required: false,
    type: "select",
  },
  {
    labelText: "Bust Size",
    name: FIT_PROFILE_KEY_NAMES.BUST_SIZE,
    firstOptionProps: {
      name: FIT_PROFILE_KEY_NAMES.BAND_SIZE,
      options: ["-", ...[...Array((48 - 28 + 2) / 2).keys()].map(x => x * 2 + 28)],
    },
    secondOptionProps: {
      name: FIT_PROFILE_KEY_NAMES.CUP_SIZE,
      options: ["-", "AA", "A", "B", "C", "D", "DD", "DDD/E", "F", "G", "H", "I", "J"],
    },
    required: false,
    type: "select",
  },
  {
    labelText: "Weight (lbs)",
    name: FIT_PROFILE_KEY_NAMES.WEIGHT,
    placeholder: null,
    required: false,
    type: "text",
    validator: validateWeight,
    mask: "999 lbs",
    maskChar: " ",
    errorMessage: clientSideErrorMessages.formValidation.weight,
  },
];
