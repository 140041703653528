import { analytics, happinessSurveyValues } from "rtr-constants";
import { connect } from "react-redux";
import ActionLogger from "action-logger";
import PropTypes from "prop-types";
import React from "react";

import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";
import happinessSurveyActions from "actions/happiness-survey-actions";

import Modal from "components/source/shared/modal";
import ProgressImages from "./progress-images";
import AdditionalComment from "./additional-comment";
import Apology from "./apology";
import Damaged from "./damaged";
import ExchangeSurveyThanks from "./exchange-survey-thanks";
import FitFeedback from "./fit-feedback";
import OtherIssues from "./other-issues";
import UnwornBroadReason from "./unworn-broad-reason";

export class HSSelfServiceExchange extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleRequestClose: PropTypes.func.isRequired,
    happinessSurvey: PropTypes.object,
    isModalOpen: PropTypes.bool,
    selfServiceExchange: PropTypes.object,
    smartComponentState: PropTypes.shape({
      didFetchSmartReferralsReminderModal: PropTypes.bool,
      referralsReminderModal: PropTypes.shape({
        name: PropTypes.string,
        CTA: PropTypes.string,
        body: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    userData: PropTypes.object,
  };

  static completeStatus = "COMPLETE";

  state = {
    completedAllSurveys: false,
    lastPageViewed: "",
    currentPage: UnwornBroadReason.pageName,
  };

  // if users have incomplete surveys, show unworn reason page
  // if users have all complete surveys, show exchange survey thanks page
  checkIfAllSurveysAreCompleted(itemSurveys = []) {
    const incompleteSurveys = itemSurveys.filter(s => s.status !== this.constructor.completeStatus);
    if (incompleteSurveys.length) {
      this.setState({
        currentPage: UnwornBroadReason.pageName,
      });
    } else if (itemSurveys?.length) {
      this.prepareSurveyThanksPage();
    }
  }

  componentDidMount() {
    this.checkIfAllSurveysAreCompleted(this.props.happinessSurvey?.itemSurveys);
  }

  componentDidUpdate() {
    // finish exchange survey
    if (
      !this.state.completedAllSurveys &&
      this.state.currentPage === UnwornBroadReason.pageName &&
      !this.currentSurvey()
    ) {
      ActionLogger.logAction({
        object_type: analytics.SELF_SERVICE_EXCHANGES,
        action: "happiness_survey_complete",
      });

      this.prepareSurveyThanksPage();
    }
  }

  prepareSurveyThanksPage() {
    this.setState({
      completedAllSurveys: true,
      currentPage: ExchangeSurveyThanks.pageName,
    });
  }

  handleGoToPage = page => {
    this.setState({
      lastPageViewed: this.state.currentPage,
      currentPage: page,
    });
  };

  handleCompleteSurvey = () => {
    this.props.dispatch(
      happinessSurveyActions.completeSurvey(this.currentSurvey().bookingId, getState => {
        const { happinessSurvey } = getState();
        this.checkIfAllSurveysAreCompleted(happinessSurvey?.itemSurveys);
      })
    );
  };

  handleAddFeedback = (value, exclusiveFeedbackTypeOption) => {
    this.props.dispatch(
      happinessSurveyActions.addFeedback(value, this.currentSurvey().bookingId, exclusiveFeedbackTypeOption)
    );
  };

  handleRemoveFeedback = value => {
    this.props.dispatch(happinessSurveyActions.removeFeedback(value, this.currentSurvey().bookingId));
  };

  handleAddSurveyComment = value => {
    this.props.dispatch(happinessSurveyActions.addSurveyComment(value, this.currentSurvey().bookingId));
  };

  lastPageViewed = () => {
    const lastPage = Damaged.pageName;

    // We don't want to go back to the apology page, so go back to the page we know is before apology page
    return this.state.lastPageViewed === Apology.pageName ? lastPage : this.state.lastPageViewed;
  };

  currentSurveyIsAccessory = () => {
    if (!this.currentSurvey()) {
      return false;
    }

    const survey = this.currentSurvey();
    let { category } = survey.product;

    while (category) {
      category = category.parent;

      if (category?.id === "accessory") {
        return true;
      }
    }

    return false;
  };

  currentSurvey = () => {
    const itemSurveys = this.props?.happinessSurvey?.itemSurveys;
    return itemSurveys.find(s => s.inProgress === true);
  };

  renderExchangeSurveyThanks = () => {
    const happinessGroupId = this.props?.happinessSurvey?.reserveGroupId?.toString();
    const exchangeGroups = this.props.selfServiceExchange?.exchangeGroups ?? {};
    const happinessGroup = exchangeGroups?.[happinessGroupId] ?? {};

    const bookings = happinessGroup?.bookings ?? [];
    const bookingIds = bookings.map(b => b.id);
    const surveys = this.props?.happinessSurvey?.itemSurveys;
    const filteredSurveys = surveys.filter(s => bookingIds.includes(s.bookingId.toString()));

    const isExemptFromShippingCosts = () => {
      const happinessGroupId = this.props?.happinessSurvey?.reserveGroupId?.toString();
      const exchangeGroups = this.props.selfServiceExchange?.exchangeGroups ?? {};
      const happinessGroup = exchangeGroups?.[happinessGroupId] ?? {};
      const { hadPO, newCustomer } = happinessGroup;
      const isPro = this.props?.userData?.memberships?.isProMember;
      return hadPO || newCustomer || isPro;
    };

    return (
      <ExchangeSurveyThanks
        currentPage={this.state.currentPage}
        dispatch={this.props.dispatch}
        dontCharge={isExemptFromShippingCosts()}
        group={happinessGroup}
        itemSurveys={filteredSurveys}
        selfServiceExchange={this.props.selfServiceExchange}
        type={this.props.happinessSurvey.type}
      />
    );
  };

  getFitFeedbackNextPage = () => {
    const currentSurvey = this.currentSurvey();
    // NW [EXPLANATION] 4/17/19: if the item didn't fit, we display Apology page before AdditionalComment.
    if (happinessSurveyContainsFeedback(currentSurvey, happinessSurveyValues.negativeFeedback.DIDNT_FIT)) {
      return Apology.pageName;
    }
    return AdditionalComment.pageName;
  };

  render() {
    const itemSurveys = this.props?.happinessSurvey?.itemSurveys?.length;

    if (!itemSurveys) {
      return null;
    }
    const modalClassnames = "happiness-survey-modal happiness-survey-modal--designv2";

    return (
      <div className="happiness-survey-container">
        <Modal
          className={modalClassnames}
          isOpen={this.props.isModalOpen}
          onRequestClose={this.props.handleRequestClose}>
          <UnwornBroadReason
            addFeedback={this.handleAddFeedback}
            completeSurvey={this.handleCompleteSurvey}
            currentPage={this.state.currentPage}
            currentSurvey={this.currentSurvey()}
            goToPage={this.handleGoToPage}
            isAccessory={this.currentSurveyIsAccessory()}
            removeFeedback={this.handleRemoveFeedback}
            surveyType={this.props.happinessSurvey.type}
          />
          <Apology
            addFeedback={this.handleAddFeedback}
            currentPage={this.state.currentPage}
            currentSurvey={this.currentSurvey()}
            goToPage={this.handleGoToPage}
            nextPage={AdditionalComment.pageName}
            removeFeedback={this.handleRemoveFeedback}
          />
          <Damaged
            addFeedback={this.handleAddFeedback}
            completeSurvey={this.handleCompleteSurvey}
            currentPage={this.state.currentPage}
            currentSurvey={this.currentSurvey()}
            goToPage={this.handleGoToPage}
            nextPage={Apology.pageName}
            prevPage={OtherIssues.pageName}
            removeFeedback={this.handleRemoveFeedback}
          />
          <OtherIssues
            addFeedback={this.handleAddFeedback}
            completeSurvey={this.handleCompleteSurvey}
            currentPage={this.state.currentPage}
            currentSurvey={this.currentSurvey()}
            damagedPage={Damaged.pageName}
            goToPage={this.handleGoToPage}
            isAccessory={this.currentSurveyIsAccessory()}
            lastPageViewed={this.lastPageViewed()}
            nextPage={Apology.pageName}
            prevPage={UnwornBroadReason.pageName}
            removeFeedback={this.handleRemoveFeedback}
          />
          <AdditionalComment
            addFeedback={this.handleAddSurveyComment}
            completeSurvey={this.handleCompleteSurvey}
            currentPage={this.state.currentPage}
            currentSurvey={this.currentSurvey()}
            goToPage={this.handleGoToPage}
            isAccessory={this.currentSurveyIsAccessory()}
            lastPageViewed={this.lastPageViewed()}
            prevPage={UnwornBroadReason.pageName}
            removeFeedback={this.handleRemoveFeedback}
          />
          <FitFeedback
            addFeedback={this.handleAddFeedback}
            completeSurvey={this.handleCompleteSurvey}
            currentPage={this.state.currentPage}
            currentSurvey={this.currentSurvey()}
            getNextPage={this.getFitFeedbackNextPage}
            goToPage={this.handleGoToPage}
            lastPageViewed={this.lastPageViewed()}
            prevPage={UnwornBroadReason.pageName}
            removeFeedback={this.handleRemoveFeedback}
          />
          {this.renderExchangeSurveyThanks()}
          <ProgressImages
            currentPage={this.state.currentPage}
            currentSurvey={this.currentSurvey()}
            itemSurveys={this.props.happinessSurvey.itemSurveys}
            willShowProgressImages={true}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dispatch: ownProps.dispatch,
    happinessSurvey: state.happinessSurvey,
    selfServiceExchange: state.selfServiceExchange,
    smartComponentState: state.smartComponentState,
    userData: state.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HSSelfServiceExchange);
