import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import StarRatingLabel from "./star-rating-label";

class Rating extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelectRating: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    defaultValue: 0,
    values: [],
  };

  state = {
    rating: this.props.defaultValue,
  };

  setRating = e => {
    this.setState({
      rating: e.target.value,
    });

    if (typeof this.props.onSelectRating === "function") {
      this.props.onSelectRating(e.target.value);
    }
  };

  renderRatingLabel = (value, children) => {
    return (
      <StarRatingLabel value={value} rating={this.state.rating}>
        {children}
      </StarRatingLabel>
    );
  };

  render() {
    const className = classNames(`rating rating-starRating`, {
      [this.props.className]: this.props.className,
    });

    return (
      <div className={className} data-test-id="rating-starRating">
        {this.props.values.map(value => {
          const id = "starRating-" + value;
          return (
            <div className="rating__option" key={id}>
              {this.renderRatingLabel(
                value,
                <input
                  type="radio"
                  checked={this.state.rating === value.toString()}
                  id={id}
                  name="starRating"
                  onChange={this.setRating}
                  value={value}
                  data-test-id={id}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Rating;
