import React from "react";
import PropTypes from "prop-types";
import { happinessSurveyValues } from "rtr-constants";
import PanelCheckbox from "components/source/atoms/atom-panel-checkbox";
import SurveyControls from "./survey-controls";
import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";

const propTypes = {
  addFeedback: PropTypes.func.isRequired,
  completeSurvey: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  currentSurvey: PropTypes.object,
  goToPage: PropTypes.func.isRequired,
  isAccessory: PropTypes.bool,
  nextPage: PropTypes.string.isRequired,
  prevPage: PropTypes.string.isRequired,
  removeFeedback: PropTypes.func.isRequired,
};

export default class Damaged extends React.Component {
  static get pageName() {
    return "Damaged";
  }

  handleClickBack() {
    this.props.goToPage(this.props.prevPage);
  }

  handleClickSkip() {
    this.props.completeSurvey(this.props?.currentSurvey?.bookingId);
  }

  handleClickNext() {
    this.props.goToPage(this.props.nextPage);
  }

  handleSelect(value) {
    if (!this.isFeedbackAlreadyPresent(value)) {
      this.props.addFeedback(value);
    } else {
      this.props.removeFeedback(value);
    }
  }

  isFeedbackAlreadyPresent(value) {
    const { currentSurvey } = this.props;
    return happinessSurveyContainsFeedback(currentSurvey, value);
  }

  willRender() {
    return this.props.currentPage === this.constructor.pageName;
  }

  renderBrokenZipperHardware() {
    return this.renderPanelCheckbox(happinessSurveyValues.damagedIssues.ITEM_BROKEN_ZIPPER, "Broken Zipper/Hardware");
  }

  renderFabricPull() {
    return this.renderPanelCheckbox(happinessSurveyValues.damagedIssues.ITEM_FABRIC_PULL, "Fabric Pull");
  }

  renderStained() {
    return this.renderPanelCheckbox(happinessSurveyValues.damagedIssues.ITEM_STAINED, "Stained");
  }

  renderOtherIssue() {
    if (!this.props.isAccessory) {
      return this.renderPanelCheckbox(happinessSurveyValues.damagedIssues.ITEM_DAMAGED_OTHER, "Other");
    }
  }

  renderPanelCheckbox(value, valueAsText) {
    return (
      <div className="damaged__action">
        <PanelCheckbox
          checked={this.isFeedbackAlreadyPresent(value)}
          onClick={this.handleSelect.bind(this)}
          displayText={valueAsText}
          value={value}
        />
      </div>
    );
  }

  render() {
    if (!this.willRender()) {
      return null;
    }

    return (
      <div className="damaged">
        <SurveyControls
          willShowBack={true}
          onClickBack={() => {
            this.handleClickBack();
          }}
          onClickSkip={() => {
            this.handleClickSkip();
          }}
        />
        <div className="damaged__copy h4">How was this item damaged?</div>
        <div className="damaged__subcopy h5">Select all that apply.</div>
        {this.renderBrokenZipperHardware()}
        {this.renderFabricPull()}
        {this.renderStained()}
        {this.renderOtherIssue()}
        <div className="damaged__next">
          <button
            onClick={() => {
              this.handleClickNext();
            }}
            className="btn">
            Next
          </button>
        </div>
      </div>
    );
  }
}

Damaged.propTypes = propTypes;
