import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";

export const ratingMapPropType = PropTypes.shape({
  id: idPropType,
  ratingMap: PropTypes.shape({
    "2": PropTypes.string,
    "4": PropTypes.string,
    "6": PropTypes.string,
    "8": PropTypes.string,
    "10": PropTypes.string,
  }),
});
