import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ActionLogger from "action-logger";
import {
  analytics,
  HomePickupMethod,
  HomePickupType,
  HOME_PICKUP_METHOD_KEY,
  HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY,
  HPUEditAddressModalName,
  HPUEditAddressConfirmHandlerTypes,
} from "rtr-constants";
import { getDigitsFromVisibleNumber } from "helpers/format-phone-number-helper";
import SharedActions from "actions/shared-actions";
import HomePickupActions, { HOME_PICKUP_STORAGE_NAME_SPACE } from "actions/home-pickup-actions";
import { LocalStorage } from "site/localStorage";
import { Flags, useFeatureFlag } from "components/source/hoc/with-feature-flags";

import HomePickupSchedulerError from "../home-pickup-scheduler-error";
import HomePickupAddressCard from "./home-pickup-address-card";
import HomePickupDatePicker from "./home-pickup-date-picker";
import HomePickupMethodPicker from "./home-pickup-method-picker";
import RtrImage from "../../shared/rtr-image";

const localStorageClient = new LocalStorage(HOME_PICKUP_STORAGE_NAME_SPACE);
const propTypes = {
  errorHandler: PropTypes.func.isRequired,
  dismissHomePickupCTA: PropTypes.func,
  dismissHomePickupCTAText: PropTypes.string,
  scheduleHomePickup: PropTypes.func.isRequired,
  showHomePickupError: PropTypes.bool,
};

const HomePickupScheduler = ({
  errorHandler,
  dismissHomePickupCTA,
  dismissHomePickupCTAText,
  scheduleHomePickup,
  showHomePickupError,
}) => {
  const [pickupMethod, setPickupMethod] = React.useState(
    localStorageClient.get(HOME_PICKUP_METHOD_KEY)
      ? localStorageClient.get(HOME_PICKUP_METHOD_KEY)
      : HomePickupMethod.NONE_SELECTED
  );
  const [specialInstructions, setSpecialInstructions] = React.useState(
    localStorageClient.get(HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY)
      ? localStorageClient.get(HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY)
      : ""
  );

  const homePickupAddress = useSelector(state => state.homePickupAddress);
  const homePickupBookingIds = useSelector(state => state.homePickup?.homePickupBookingIds);
  const homePickupEligibility = useSelector(state => state.homePickup?.homePickupEligibility);
  const homePickupRentBeginDate = useSelector(state => state.homePickup?.homePickupRentBeginDate);
  const isHomePickupSchedulerLoading = useSelector(state => state.homePickup?.isSchedulerLoading);

  const dispatch = useDispatch();
  const homePickupMultipleDates = useFeatureFlag(Flags.HOME_PICKUP_MULTIPLE_DATES);

  const isLiveSwap = homePickupEligibility?.preferredPickupDate === homePickupRentBeginDate;

  const scheduleHomePickupHandler = () => {
    const pickupType = isLiveSwap ? HomePickupType.LIVE_SWAP_ELIGIBLE : HomePickupType.HOME_PICKUP_ONLY;

    scheduleHomePickup(
      homePickupBookingIds,
      getDigitsFromVisibleNumber(homePickupAddress?.phone),
      pickupMethod,
      pickupType,
      specialInstructions
    );
  };

  if (showHomePickupError) {
    return <HomePickupSchedulerError errorHandler={errorHandler} />;
  }

  if (!homePickupEligibility?.preferredPickupDate || isHomePickupSchedulerLoading) {
    return (
      <div className="Home-Pickup-Scheduler">
        <div className="loading">
          <RtrImage src="//sf-p.rtrcdn.com/images/loading.gif" alt="Loading animation" />
        </div>
      </div>
    );
  }

  if (showHomePickupError) {
    return <HomePickupSchedulerError errorHandler={errorHandler} />;
  }

  const onEditAddress = () => {
    const logData = {
      object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
      action: analytics.ACTION_TYPE.EDIT_ADDRESS,
      zip_code: homePickupAddress?.postalCode,
    };
    ActionLogger.logAction(logData);
    dispatch(
      HomePickupActions.setHomePickupAddress({
        ...homePickupAddress,
        editType: HPUEditAddressConfirmHandlerTypes.EDIT_ADDRESS_FROM_SCHEDULER_CARD,
      })
    );
    dispatch(SharedActions.displayModal(HPUEditAddressModalName));
  };

  const setAndLogPickupMethod = method => {
    setPickupMethod(method);
    const actionType =
      method === HomePickupMethod.DIRECT_HANDOFF
        ? analytics.ACTION_TYPE.CLICK_DIRECT_HANDOFF
        : analytics.ACTION_TYPE.CLICK_CONTACTLESS;
    const logData = {
      object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
      action: actionType,
      zip_code: homePickupAddress?.postalCode,
    };
    ActionLogger.logAction(logData);
  };

  return (
    <div className="reworked-home-pickup-scheduler">
      <div className="content">
        <HomePickupAddressCard address={homePickupAddress} onEditAddress={onEditAddress} />
        <HomePickupDatePicker
          handleOnSelectDate={selectedDate => dispatch(HomePickupActions.updateHomePickupDateDetails(selectedDate))}
          isReworkedScheduler={true}
          multipleDatesFeatureFlag={homePickupMultipleDates}
          pickupDetails={homePickupEligibility}
        />
        <HomePickupMethodPicker
          isLiveSwap={isLiveSwap}
          isReworkedScheduler={true}
          pickupMethod={pickupMethod}
          setPickupMethod={setAndLogPickupMethod}
          setSpecialInstructions={setSpecialInstructions}
          specialInstructions={specialInstructions}
        />
        <div className="tos-pp universal-xsmall">
          By scheduling a pickup, I agree to the <a href="/pages/termsofservice">Terms of Service</a>,
          <br />
          <a href="/privacy">Privacy Policy</a> and to receive text messages.
        </div>
      </div>
      <div className="footer">
        <div className="schedule-button">
          <button
            disabled={pickupMethod === HomePickupMethod.NONE_SELECTED}
            className="btn btn-primary"
            onClick={scheduleHomePickupHandler}>
            Schedule Pickup
          </button>
        </div>
        {dismissHomePickupCTA && (
          <button className="decline-pickup universal-xsmall" onClick={dismissHomePickupCTA}>
            {dismissHomePickupCTAText ? dismissHomePickupCTAText : "No thanks, I'll drop off my returns instead"}
          </button>
        )}
      </div>
    </div>
  );
};

HomePickupScheduler.propTypes = propTypes;

export default HomePickupScheduler;
