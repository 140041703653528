import { Component } from "react";
import $ from "jquery";

import ActionLogger from "action-logger";
import { withFeatureFlags, featureFlagsPropType, Flags } from "components/source/hoc/with-feature-flags";
import { waitForElement } from "../../../helpers/dom-helpers";

export class OneTrustDOMHelperComponent extends Component {
  static propTypes = {
    featureFlags: featureFlagsPropType,
  };

  static defaultProps = {
    featureFlags: {},
  };

  static querySelectors = {
    footerLaunchButton: ".onetrust-footer-link",
    privacyPolicyLaunchButton: ".onetrust-privacy-policy-link",
    performanceCookiesToggleButton: "#ot-group-id-C0002",
    // For some reason this id changes whenever we update the scripts so opting for a more generic selector. Something
    // to figure out long-term. This refers to the group id that comprises the two sub-category ids (targeting and
    // social media).
    targetingCookiesToggleButton: "#ot-pc-content .ot-cat-item:nth-of-type(3) input",
    closeModalButton: "#close-pc-btn-handler",
    closeBannerButton: "#onetrust-banner-sdk .banner-close-button",
    confirmMyChoicesButton: ".save-preference-btn-handler",
    privacyNoticeLink: ".privacy-notice-link",
    onetrustConsentSdk: "#onetrust-consent-sdk",
  };

  static launchButtonHandlers = [
    {
      elementName: "footerEl",
      querySelector: this.querySelectors.footerLaunchButton,
      callbackMethod: "clickDoNotSellMyPersonalInformationFromFooter",
      eventListener: "click",
    },
    {
      elementName: "privacyPolicyEl",
      querySelector: this.querySelectors.privacyPolicyLaunchButton,
      callbackMethod: "clickDoNotSellMyPersonalInformationFromPrivacyPolicy",
      eventListener: "click",
    },
  ];

  static modalHandlers = [
    {
      elementName: "performanceToggleEl",
      querySelector: this.querySelectors.performanceCookiesToggleButton,
      callbackMethod: "clickCookieCategoryToggle",
      eventListener: "click",
    },
    {
      elementName: "targetingToggleEl",
      querySelector: this.querySelectors.targetingCookiesToggleButton,
      callbackMethod: "clickCookieCategoryToggle",
      eventListener: "click",
    },
    {
      elementName: "closeButtonEl",
      querySelector: this.querySelectors.closeModalButton,
      callbackMethod: "clickCloseButton",
      eventListener: "click",
    },
    {
      elementName: "confirmMyChoicesEl",
      querySelector: this.querySelectors.confirmMyChoicesButton,
      callbackMethod: "clickConfirmMyChoices",
      eventListener: "click",
    },
    {
      elementName: "privacyNoticeLinkEl",
      querySelector: this.querySelectors.privacyNoticeLink,
      callbackMethod: "clickPrivacyNotice",
      eventListener: "click",
    },
  ];

  static allHandlers = [].concat(this.launchButtonHandlers).concat(this.modalHandlers);

  componentDidMount() {
    if (this.props.featureFlags[Flags.ONETRUST_CONSENT_MANAGEMENT]) {
      waitForElement(this.constructor.querySelectors.onetrustConsentSdk).then(() => {
        const fromClick = false;
        this.attachEventListeners(fromClick);
      });

      this.handleDomManipulations();
    }
  }

  componentWillUnmount() {
    if (this.props.featureFlags[Flags.ONETRUST_CONSENT_MANAGEMENT]) {
      const fromClick = false;
      this.removeEventListeners(fromClick);
    }
  }

  getActionType = id => {
    if (id?.includes("BG")) {
      return "toggle_personalized_advertising";
    } else if (id?.includes("C0002")) {
      return "toggle_performance_analytics";
    } else {
      return "";
    }
  };

  getToggleValue = checked => {
    switch (checked) {
      case true:
        return "on";
      case false:
        return "off";
      // In this case, the user does not see the toggle
      default:
        return "on_default";
    }
  };

  handleDomManipulations = () => {
    document.body.classList?.add("ot-hosted");

    // The latest sdk adds their close button svg (on banner and modal) with an inline-style background-image
    // property. We aren't able to override this in the css with the '!important' tag because we use a scss mixin to
    // generate code to select the right image on our sprite sheet (see the reb-close_x mixin in the _reb_mixins.scss
    // file). That file is machine generated code, so we can't modify the mixin to take params. Thus, we have to
    // remove the property here in JS so we can use the mixin.
    waitForElement(this.constructor.querySelectors.closeModalButton).then(closeModalButton => {
      closeModalButton?.style?.removeProperty?.("background-image");
    });
    waitForElement(this.constructor.querySelectors.closeBannerButton).then(closeBannerButton => {
      closeBannerButton?.style?.removeProperty?.("background-image");
    });
  };

  clickDoNotSellMyPersonalInformationFromFooter = () => {
    ActionLogger.logAction({
      object_type: "top_nav",
      action_type: "click_do_not_sell_my_personal_information",
    });

    // We have to do this because OneTrust loads its assets that compose the Preference Center
    // modal asynchronously in the background. Because of this, all the modal elements are
    // unavailable when this component initially loads.
    waitForElement(this.constructor.querySelectors.onetrustConsentSdk).then(() => {
      const fromClick = true;
      this.attachEventListeners(fromClick);
    });
  };

  clickDoNotSellMyPersonalInformationFromPrivacyPolicy = () => {
    ActionLogger.logAction({
      object_type: "privacy_settings_modal",
      action_type: "click_do_not_sell_my_personal_information",
    });

    // We have to do this because OneTrust loads its assets that compose the Preference Center
    // modal asynchronously in the background. Because of this, all the modal elements are
    // unavailable when this component initially loads.
    waitForElement(this.constructor.querySelectors.onetrustConsentSdk).then(() => {
      const fromClick = true;
      this.attachEventListeners(fromClick);
    });
  };

  clickCookieCategoryToggle = event => {
    const checked = event?.target?.checked;
    const id = event?.target?.attributes?.id?.value;
    const toggleValue = this.getToggleValue(checked);
    const actionType = this.getActionType(id);

    ActionLogger.logAction({
      object_type: "privacy_settings_modal",
      action_type: actionType,
      toggle: toggleValue,
    });
  };

  clickCloseButton = () => {
    ActionLogger.logAction({
      object_type: "privacy_settings_modal",
      action_type: "click_close",
    });

    const fromClick = true;
    this.removeEventListeners(fromClick);
  };

  clickConfirmMyChoices = () => {
    const performanceToggleValue = $(this.constructor.querySelectors.performanceCookiesToggleButton)?.[0]?.checked;
    const targetingToggleValue = $(this.constructor.querySelectors.targetingCookiesToggleButton)?.[0]?.checked;

    ActionLogger.logAction({
      object_type: "privacy_settings_modal",
      action_type: "click_confirm_my_choices",
      performance_analytics: this.getToggleValue(performanceToggleValue),
      personalized_advertising: this.getToggleValue(targetingToggleValue),
    });

    const fromClick = true;
    this.removeEventListeners(fromClick);
  };

  clickPrivacyNotice = () => {
    ActionLogger.logAction({
      object_type: "privacy_settings_modal",
      action_type: "click_privacy_policy",
    });
  };

  attachEventListeners = fromClick => {
    const handlers = fromClick ? this.constructor.modalHandlers : this.constructor.launchButtonHandlers;

    handlers.forEach(handler => {
      this[handler.elementName] = $(handler.querySelector);

      if (this[handler.elementName] && typeof this[handler.callbackMethod] === "function") {
        this[handler.elementName].on(handler.eventListener, this[handler.callbackMethod]);
      }
    });

    if (fromClick) {
      /* when modal is closed, reset the body */
      $(this.constructor.querySelectors.closeModalButton).on("click", this.allowBodyScroll);
      $(this.constructor.querySelectors.confirmMyChoicesButton).on("click", this.allowBodyScroll);
    } else {
      /* when modal is opened, prevent body from scrolling in the background */
      $(this.constructor.querySelectors.footerLaunchButton).on("click", this.preventBackgroundBodyScroll);
      $(this.constructor.querySelectors.privacyPolicyLaunchButton).on("click", this.preventBackgroundBodyScroll);
    }
  };

  preventBackgroundBodyScroll = () => $("body").css("overflow", "hidden");

  allowBodyScroll = () => {
    // OneTrust does their own overflow:hidden logic that appears to not work on our
    // Gated Anonymous HP (sometimes), so adding this here to correct that issue.
    setTimeout(() => {
      $("html").css("overflow", "visible");
      $("body").css("overflow", "visible");
    }, 500);
  };

  removeEventListeners = fromClick => {
    // If removing event listeners from a click within the modal, remove modal handlers.
    // Otherwise, remove everything on unmount.
    const handlers = fromClick ? this.constructor.modalHandlers : this.constructor.allHandlers;

    handlers.forEach(handler => {
      if (this[handler.elementName]) {
        this[handler.elementName].off(handler.eventListener, this[handler.callbackMethod]);
      }
    });

    if (fromClick) {
      $(this.constructor.querySelectors.closeModalButton).off("click", this.allowBodyScroll);
      $(this.constructor.querySelectors.confirmMyChoicesButton).off("click", this.allowBodyScroll);
    } else {
      $(this.constructor.querySelectors.closeModalButton).off("click", this.allowBodyScroll);
      $(this.constructor.querySelectors.confirmMyChoicesButton).off("click", this.allowBodyScroll);
      $(this.constructor.querySelectors.footerLaunchButton).off("click", this.preventBackgroundBodyScroll);
      $(this.constructor.querySelectors.privacyPolicyLaunchButton).off("click", this.preventBackgroundBodyScroll);
    }
  };

  render() {
    return null;
  }
}

export default withFeatureFlags(Flags.ONETRUST_CONSENT_MANAGEMENT)(OneTrustDOMHelperComponent);
