import React from "react";
import PropTypes from "prop-types";

import SurveyControls from "./survey-controls";
import { HappinessSurveyTypes, happinessSurveyValues } from "rtr-constants";
import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";
import ProductImage from "components/source/shared/product-image";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageOrientation,
  ProductImageSize,
} from "helpers/product-image-helpers";

const propTypes = {
  goToPage: PropTypes.func.isRequired,
  completeSurvey: PropTypes.func.isRequired,
  addFeedback: PropTypes.func.isRequired,
  currentSurvey: PropTypes.object,
  currentPage: PropTypes.string.isRequired,
  isAccessory: PropTypes.bool.isRequired,
  surveyType: PropTypes.oneOf(["reserve"]),
};

export default class UnwornBroadReason extends React.Component {
  static get pageName() {
    return "UnwornBroadReason";
  }

  handleAction(value, nextPage) {
    this.props.addFeedback(value);
    this.props.goToPage(nextPage);
  }

  handleClickFit = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.DIDNT_FIT, "FitFeedback");
  };

  handleClickStyle = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.NOT_MY_STYLE, "AdditionalComment");
  };

  handleClickMaterial = () => {
    this.handleAction(happinessSurveyValues.otherIssues.MATERIAL, "AdditionalComment");
  };

  handleClickTooBig = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.TOO_BIG, "AdditionalComment");
  };

  handleClickTooSmall = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.TOO_SMALL, "AdditionalComment");
  };

  handleClickFlattering = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.NOT_FLATTERING, "AdditionalComment");
  };

  handleClickArriveOnTime = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.DIDNT_ARRIVE_ON_TIME, "AdditionalComment");
  };

  handleWoreSomethingElse = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.WORE_SOMETHING_ELSE, "AdditionalComment");
  };

  handleClickOther = () => {
    this.handleAction(happinessSurveyValues.negativeFeedback.OTHER_ISSUES, "OtherIssues");
  };

  handleClickBack = () => {
    const feedback = this.props.currentSurvey?.surveyFeedbacks ?? [];
    const sheWoreIt = feedback.find(f => f.feedback === happinessSurveyValues.wornInquiry.WORE_IT);

    if (sheWoreIt) {
      this.props.goToPage("WornHappinessLevel");
    } else {
      this.props.goToPage("WornInquiry");
    }
  };

  handleClickSkip() {
    this.props.completeSurvey(this.props.currentSurvey.bookingId);
  }

  willRender() {
    return this.props.currentSurvey && this.props.currentPage === this.constructor.pageName;
  }

  isFeedbackAlreadyPresent(value) {
    const { currentSurvey } = this.props;
    return happinessSurveyContainsFeedback(currentSurvey, value);
  }

  renderTitle() {
    if (this.props.surveyType === HappinessSurveyTypes.RESERVE) {
      return "Why are you replacing this style?";
    } else if (this.isFeedbackAlreadyPresent(happinessSurveyValues.wornInquiry.DIDNT_WEAR_IT)) {
      return "Hey, it happens! So we can make next time better, why didn’t you wear it?";
    } else if (this.isFeedbackAlreadyPresent(happinessSurveyValues.wornHappinessLevel.JUST_OK)) {
      return "Hey, it happens! So we can make next time better, why was it just OK?";
    } else {
      return "What was wrong with this piece?";
    }
  }

  renderProductInfo() {
    const { currentSurvey, surveyType } = this.props;
    const { product } = currentSurvey;

    if (surveyType !== HappinessSurveyTypes.RESERVE) {
      return null;
    }

    if (!product) {
      return null;
    }

    return (
      <div className="unworn-broad-reason__product">
        <ProductImage
          altText={`Survey image - ${getProductImageAltText(product?.displayName, product?.designer?.displayName)}`}
          className="unworn-broad-reason__product__image"
          imageGroup={getProductImageUrlsByOrientation(product?.images, [ProductImageOrientation.FRONT.path])}
          imageSize={ProductImageSize.x70}
        />
        <div className="unworn-broad-reason__product__info">
          <div className="unworn-broad-reason__product__designer">{product?.designer?.displayName}</div>
          <div className="unworn-broad-reason__product__name">{product.displayName}</div>
          <div className="unworn-broad-reason__product__size"> Size: {currentSurvey.size} </div>
        </div>
      </div>
    );
  }

  renderReason(handler, valueAsText) {
    return (
      <div className="unworn-broad-reason__action">
        <button onClick={handler} className="btn btn-secondary">
          {valueAsText}
        </button>
      </div>
    );
  }

  renderFlaggedReason() {
    // Only care about reserve flag if it's a reserve happiness survey
    if (this.props.surveyType === HappinessSurveyTypes.RESERVE) {
      return null;
    }

    return (
      <div>
        {this.renderReason(this.handleClickArriveOnTime, "Didn't Arrive On Time")}
        {this.renderReason(this.handleWoreSomethingElse, "Wore Something Else")}
      </div>
    );
  }

  renderAccessoryReasons() {
    if (this.props.isAccessory) {
      return (
        <div className="unworn-broad-reason__accessory">
          {this.renderReason(this.handleClickMaterial, "Material")}
          {this.renderReason(this.handleClickTooBig, "Too Big")}
          {this.renderReason(this.handleClickTooSmall, "Too Small")}
          {this.renderReason(this.handleClickStyle, "Not My Style")}
          {this.renderFlaggedReason()}
          {this.renderReason(this.handleClickOther, "Other Reasons")}
        </div>
      );
    }
  }

  renderApparelReasons() {
    if (!this.props.isAccessory) {
      return (
        <div className="unworn-broad-reason__apparel">
          {this.renderReason(this.handleClickFit, "Didn't Fit")}
          {this.renderReason(this.handleClickStyle, "Not My Style")}
          {this.renderReason(this.handleClickFlattering, "Not Flattering")}
          {this.renderFlaggedReason()}
          {this.renderReason(this.handleClickOther, "Other Reasons")}
        </div>
      );
    }
  }
  render() {
    if (!this.willRender()) {
      return null;
    }
    const showNav = this.props.surveyType !== HappinessSurveyTypes.RESERVE;

    return (
      <div className="unworn-broad-reason">
        <SurveyControls
          willShowBack={showNav}
          willShowSkip={showNav}
          onClickBack={() => {
            this.handleClickBack();
          }}
          onClickSkip={() => {
            this.handleClickSkip();
          }}
        />
        <div className="unworn-broad-reason__copy h4">{this.renderTitle()}</div>
        {this.renderProductInfo()}
        {this.renderAccessoryReasons()}
        {this.renderApparelReasons()}
      </div>
    );
  }
}

UnwornBroadReason.propTypes = propTypes;
