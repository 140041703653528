import GoogleOneTap from "components/source/shared/auth_form/google-one-tap";
import OneTrustDOMHelperComponent from "components/source/homepage/onetrust-dom-helper";
import SnackBarContainer from "components/source/shared/snack-bar-container";
import UserDevices from "../source/shared/UserDevices";
import PDPPhotoReviewModal from "components/source/pdp/pdp-photo-review-modal";
import ReviewsModalContainer from "components/source/reviews/reviews-modal-container";
import HappinessSurveyContainer from "components/source/happiness_survey/happiness-survey-container";
import HomePickupStandaloneContainer from "../source/home_pickup/home-pickup-standalone-container";
import KeepItForeverReturnsModal from "components/source/pdp/keep-it-forever-returns-modal";
import ShoppableImagesModal from "components/source/shared/shoppable-images-modal/shoppable-images-modal";

// STOP! Before you add a new component here, consider if it is truly site-wide.
// If it is only for customers (users who have made at least 1 order), add it to IsCustomerSiteWideComponents.jsx
// If it is only for subscribers, add it to SubscriptionSiteWideComponents.jsx
export default function SiteWideComponents() {
  return (
    <>
      <UserDevices />
      <GoogleOneTap />
      <OneTrustDOMHelperComponent />
      <SnackBarContainer />
      <PDPPhotoReviewModal />
      <ReviewsModalContainer />
      {/* Plans to revert back HappinessSurveyContainer to isCustomerSiteWideComponents
      once https://renttherunway.jira.com/browse/PSEC-2808 is resolved 
      here: https://renttherunway.jira.com/browse/SWAT-555  */}
      <HappinessSurveyContainer />
      <HomePickupStandaloneContainer /> {/* adding this to site wide as reserve user were not seeing this */}
      <KeepItForeverReturnsModal /> {/* As this needs to be available even for a reserve user */}
      <ShoppableImagesModal /> {/* this modal's for everyone so here it goes */}
    </>
  );
}
