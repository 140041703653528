import { getCustomerReviews } from "actions/product-drawer-actions";

var ProductDrawerHelper = {
  productDrawerGridIndex: function (productIndex, productCount, columnCount) {
    if (typeof productIndex !== "number" || typeof columnCount !== "number" || productIndex < 0 || columnCount <= 0) {
      return;
    }

    let indexToAddDrawer = Math.ceil((productIndex + 1) / columnCount) * columnCount;

    // This is to fix the index if for some reason there is an unusual number on the last row of the last page of products ( 1 or 2 of a 3 row grid. Or, 1 on a 2 row grid.)
    if (indexToAddDrawer >= productCount) {
      indexToAddDrawer = productCount;
    }

    return indexToAddDrawer;
  },

  gridInsertProductDrawer: function (productList, productIndex, columnCount, productDrawer) {
    if (!Array.isArray(productList) || productList.length === 0) {
      return;
    }

    const drawerIndex = this.productDrawerGridIndex(productIndex, productList.length, columnCount);
    if (typeof drawerIndex === "undefined") {
      return;
    }

    productList.splice(drawerIndex, 0, productDrawer);
  },

  getOnlyReviewsWithPhotos: function (reviews, isUserSpecificReview = false) {
    if (isUserSpecificReview) {
      return reviews ? reviews.moments.filter(review => review?.photos) : [];
    }
    return reviews?.data ? reviews.data.filter(review => review?.moment?.photos) : [];
  },

  loadReviewsForProductDrawer: function (props, productId) {
    const { dispatch } = props;

    dispatch(getCustomerReviews(productId));
  },

  scrollIntoView: function (element) {
    if (!element) {
      return;
    }
    if (this.scrollIntoViewTimeout !== -1) {
      window.clearTimeout(this.scrollIntoViewTimeout);
    }

    const headerContainer = document.getElementById("reb-header");
    const header = document.getElementById("reb-header-unshaded");
    const headerVisibleNow = !header?.classList.contains("hidden");
    const headerHeightStr = headerContainer && window.getComputedStyle(headerContainer).height;
    const headerHeight = headerHeightStr && parseInt(headerHeightStr.replace(/px/i, ""));
    const isHeaderFixed = header && window.getComputedStyle(header).position === "fixed";

    let y = element.getBoundingClientRect().top + window.scrollY;
    const scrollDistance = y - window.scrollY;
    // if the scroll-up distance is at least 300px, the header will re-appear.
    // if the scroll-down distance is at least 150px, the header will be hidden.
    // (from sneakyHeaderScrollHandler() in header.jsx)
    const headerVisibleAfterScroll = scrollDistance < -300;
    const headerHiddenAfterScroll = scrollDistance > 150;
    const headerVisible = (headerVisibleNow || headerVisibleAfterScroll) && !headerHiddenAfterScroll;
    const addHeaderOffset = isHeaderFixed && headerVisible;

    if (addHeaderOffset) {
      y -= headerHeight;
    }

    this.scrollIntoViewTimeout = window.setTimeout(() => window.scrollTo({ top: y, behavior: "smooth" }), 100);
  },
};

export default ProductDrawerHelper;

export const {
  productDrawerGridIndex,
  gridInsertProductDrawer,
  shouldLoadReviewPhotosForProductDrawer,
  getOnlyReviewsWithPhotos,
  loadReviewsForProductDrawer,
  scrollIntoView,
} = ProductDrawerHelper;
