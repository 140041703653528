import PropTypes from "prop-types";
import {
  ProductImageOrientation,
  ProductImageSize,
  getProductImageAltText,
  getProductImageUrlsByOrientation,
} from "helpers/product-image-helpers";
import ProductImagePath from "helpers/product-image-path";
import ProductImage from "components/source/shared/product-image";

import InputsStyles from "./inputs.module.scss";
import classNames from "classnames";

const propTypes = {
  productData: PropTypes.object,
  bookingId: PropTypes.number,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const HappinessSurveyGridItem = ({ productData, bookingId, isSelected, onClick = () => {}, disabled }) => {
  const fallBackThumbnailImgSrc = () =>
    productData?.images?.[ProductImageOrientation.NO_MODEL.path]
      ? ProductImagePath + productData.images[ProductImageOrientation.NO_MODEL.path]?.[ProductImageSize.x480.path]
      : ProductImagePath + productData?.images?.[ProductImageOrientation.FRONT.path]?.[ProductImageSize.x480.path];

  const getProductImage = isSelected => {
    const className = disabled
      ? InputsStyles["grid-item-disabled"]
      : classNames(InputsStyles["grid-item"], { [InputsStyles["item-selected"]]: isSelected });

    return (
      <ProductImage
        className={className}
        altText={getProductImageAltText(productData?.displayName, productData?.designer?.displayName)}
        imageGroup={getProductImageUrlsByOrientation(productData?.images, [ProductImageOrientation.NO_MODEL.path])}
        imageSize={ProductImageSize.x480}
        fallbackSrc={fallBackThumbnailImgSrc()}
        disabled={disabled}
      />
    );
  };

  const getCheckmark = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-test-id="grid-item-checkmark">
        <circle cx="10.0842" cy="10.0842" r="9.68083" fill="#1C1C1C" stroke="#1C1C1C" strokeWidth="0.806736" />
        <path
          d="M5.64709 10.2411L8.18222 12.7763L14.5044 6.4541"
          stroke="#F1F0EE"
          strokeWidth="1.2101"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const onClickItem = () => {
    onClick(bookingId);
  };

  const gridItem = disabled ? (
    <button className={InputsStyles["clickable-grid-item-container"]} onClick={onClickItem}>
      {getProductImage(isSelected)}
    </button>
  ) : (
    <button className={InputsStyles["clickable-grid-item-container"]} onClick={onClickItem}>
      {getProductImage(isSelected)}
      {isSelected && getCheckmark()}
    </button>
  );

  return gridItem;
};

HappinessSurveyGridItem.propTypes = propTypes;
export default HappinessSurveyGridItem;
