import React from "react";
import PropTypes from "prop-types";
import { getVisiblePhoneNumber } from "helpers/format-phone-number-helper";

const propTypes = {
  address: PropTypes.object.isRequired,
  onEditAddress: PropTypes.func,
};
const HomePickupAddressCard = ({ address, onEditAddress }) => {
  return (
    <div className="address-card">
      <div className="header">
        <h2 className="section-title universal-medium">Pickup Address</h2>
        <button onClick={onEditAddress}>Edit</button>
      </div>
      <h3 className="section-sub-title universal-small">Track your pickup via your confirmation email</h3>
      <div className="address-details">
        <span>{`${address.firstName} ${address.lastName}`}</span>
        <span>{`${address.street1}, ${address.city}, ${address.zoneCode}, ${address.postalCode}`}</span>
        <span>{getVisiblePhoneNumber(address.phone)}</span>
      </div>
    </div>
  );
};

HomePickupAddressCard.propTypes = propTypes;

export default HomePickupAddressCard;
