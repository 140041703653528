import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { childrenPropType } from "components/propTypes";
import InputsStyles from "./inputs.module.scss";
import InputsLabel from "./input-label";

const propTypes = {
  additionalClassName: PropTypes.string,
  children: childrenPropType, // when using AccordionContainer, wrap it around the input you want
  isInlineInput: PropTypes.bool,
  labelProps: PropTypes.shape({
    additionalClassName: PropTypes.object,
    headerText: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    subHeaderText: PropTypes.string,
  }),
  showDisplay: PropTypes.bool,
  onShowDisplay: PropTypes.func,
};

// AccordionContainer is different from AtomAccordion by using our HS InputLabel styling,
// css modules and more flexible css styling. Plans to merge AtomAccordion into AccordionContainer here in SWAT-578

const AccordionContainer = ({
  additionalClassName,
  children,
  isInlineInput,
  labelProps,
  showDisplay,
  onShowDisplay,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(showDisplay);

  React.useEffect(() => {
    setIsExpanded(isExpanded);
  }, [showDisplay]);

  const containerClassName = classNames({
    [InputsStyles["input-container"]]: !additionalClassName,
    [additionalClassName]: additionalClassName,
    [InputsStyles["inline"]]: isInlineInput,
  });

  const onExpand = () =>
    setIsExpanded(oldValue => {
      const newValue = !oldValue;
      if (typeof onShowDisplay === "function") {
        onShowDisplay(newValue);
      }
      return newValue;
    });

  const renderButton = (
    <button
      className={`${InputsStyles["accordion-container-cta"]}${!isExpanded ? ` ${InputsStyles["closed"]}` : ""}`}
      onClick={onExpand}
      data-test-id="accordion-container-cta"
    />
  );

  return (
    <div className={containerClassName}>
      <div className={InputsStyles["accordion-container-title"]}>
        <InputsLabel
          additionalClassName={labelProps.additionalClassName}
          ctaElement={renderButton}
          headerText={labelProps?.headerText || ""}
          isRequired={labelProps.isRequired}
          subHeaderText={labelProps?.subHeaderText}
        />
      </div>
      {isExpanded && children}
    </div>
  );
};

AccordionContainer.propTypes = propTypes;
export default AccordionContainer;
