import React from "react";
import PropTypes from "prop-types";
import HappinessSurveyStyles from "./happiness-survey.module.scss";
import AtomButton from "components/source/atoms/atom-button";
import classNames from "classnames";
import { happinessSurveyCTACopy } from "constants/happiness-survey-values";

const propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  primaryCtaText: PropTypes.string,
  showSecondaryCta: PropTypes.bool,
  secondaryCtaText: PropTypes.string,
  secondaryCtaDisabled: PropTypes.bool,
  onClickSecondaryCta: PropTypes.func,
  isHappinessSurveySkipFlagEnabled: PropTypes.bool,
};

const HappinessSurveyFooter = ({
  disabled,
  onClick = () => {},
  primaryCtaText,
  showSecondaryCta = false,
  onClickSecondaryCta = () => {},
  secondaryCtaText,
  secondaryCtaDisabled,
  isHappinessSurveySkipFlagEnabled = false,
}) => {
  const buttonClassName = classNames(HappinessSurveyStyles["happiness-survey-footer"], {
    [HappinessSurveyStyles["with-secondary-footer"]]: showSecondaryCta,
  });

  const secondaryButtonClassName = classNames(HappinessSurveyStyles["happiness-survey-secondary-footer"], {
    [HappinessSurveyStyles["skip"]]: isHappinessSurveySkipFlagEnabled,
  });

  return (
    <div className={HappinessSurveyStyles["happiness-survey-footer-container"]} data-test-id="happiness-survey-footer">
      <AtomButton
        buttonText={primaryCtaText ? primaryCtaText : happinessSurveyCTACopy.NEXT_SCREEN}
        className={buttonClassName}
        disabled={disabled}
        fullWidth
        onClick={onClick}
        data-test-id="happiness-survey-footer-primary-cta"
      />
      {showSecondaryCta && (
        <button
          className={secondaryButtonClassName}
          disabled={secondaryCtaDisabled}
          onClick={onClickSecondaryCta}
          data-test-id="happiness-survey-footer-secondary-cta">
          {secondaryCtaText}
        </button>
      )}
    </div>
  );
};

HappinessSurveyFooter.propTypes = propTypes;
export default HappinessSurveyFooter;
