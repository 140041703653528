import React from "react";
import PropTypes from "prop-types";
import ReactImageCrop from "react-image-crop";
import $ from "jquery";
import RtrImage from "../shared/rtr-image";

export default class ReviewCropPhoto extends React.Component {
  static propTypes = {
    onCropPhoto: PropTypes.func.isRequired, // normalizedCropArgs => {}
    photo: PropTypes.shape({
      hasCroppedPhoto: PropTypes.bool,
      height: PropTypes.number,
      photoId: PropTypes.number,
      urls: PropTypes.shape({
        image: PropTypes.string,
      }),
      width: PropTypes.number,
    }),
  };

  state = {
    crop: {
      unit: "%",
      width: 30,
      aspect: 9 / 16,
    },
    canBeCropped: true,
    imageRenderedDimensions: {},
  };

  componentDidUpdate(_prevProps, prevState) {
    if (
      prevState.crop !== this.state.crop ||
      prevState.imageRenderedDimensions !== this.state.imageRenderedDimensions
    ) {
      const normalizedCropValues = this.getNormalizedCropValues();
      this.props.onCropPhoto(normalizedCropValues);
    }
  }

  onCropChange = crop => {
    this.setState({ crop });
  };

  getNormalizedCropValues() {
    const { crop, imageRenderedDimensions } = this.state;

    if (crop.height > 0 && crop.width > 0) {
      return {
        x: parseFloat((crop.x / imageRenderedDimensions.width).toFixed(2)),
        width: parseFloat((crop.width / imageRenderedDimensions.width).toFixed(2)),
        y: parseFloat((crop.y / imageRenderedDimensions.height).toFixed(2)),
        height: parseFloat((crop.height / imageRenderedDimensions.height).toFixed(2)),
      };
    } else {
      // the user has reset the crop selection
      return null;
    }
  }

  onImageLoaded = () => {
    // NW [EXPLANATION] 8/25/21: the rendered image dimensions are needed to normalize the crop measurements
    const imageElement = $(".ReactCrop__image")[0];
    if (!imageElement) {
      this.onImageError();
    } else {
      this.setState({
        imageRenderedDimensions: {
          height: imageElement.height,
          width: imageElement.width,
        },
      });
    }
  };

  onImageError = () => {
    this.setState({
      canBeCropped: false,
    });
  };

  render() {
    if (!this.state.canBeCropped) {
      return (
        <div className="review-crop-photo">
          <p>This image cannot be cropped.</p>
          <RtrImage src={this.props.photo?.urls?.image} />
        </div>
      );
    }
    return (
      <ReactImageCrop
        src={this.props.photo?.urls?.image}
        crop={this.state.crop}
        onImageError={this.onImageError}
        onImageLoaded={this.onImageLoaded}
        onChange={this.onCropChange}
        maxHeight={this.state.imageRenderedDimensions.height}
        maxWidth={this.state.imageRenderedDimensions.width}
        className="review-crop-photo"
      />
    );
  }
}
