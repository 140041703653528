import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import AdditionalComment from "./additional-comment";
import PanelCheckbox from "components/source/atoms/atom-panel-checkbox";
import SurveyControls from "./survey-controls";
import { happinessSurveyValues } from "rtr-constants";
import { happinessSurveyContainsFeedback } from "helpers/feedback-helper";

const propTypes = {
  addFeedback: PropTypes.func.isRequired,
  completeSurvey: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  currentSurvey: PropTypes.object,
  damagedPage: PropTypes.string,
  goToPage: PropTypes.func.isRequired,
  isAccessory: PropTypes.bool.isRequired,
  lastPageViewed: PropTypes.string.isRequired,
  nextPage: PropTypes.string.isRequired,
  prevPage: PropTypes.string.isRequired,
  removeFeedback: PropTypes.func.isRequired,
};

export default class OtherIssues extends React.Component {
  static get pageName() {
    return "OtherIssues";
  }

  handleClickBack() {
    if (this.props.lastPageViewed === this.props.damagedPage) {
      // This is to avoid an infinite lastPage loop between other-issues and damaged
      this.props.goToPage(this.props.prevPage);
    } else {
      this.props.goToPage(this.props.lastPageViewed);
    }
  }

  previouslyAtCommentPage() {
    return AdditionalComment.pageName === this.props.lastPageViewed;
  }

  handleAdditionalFeedbackQuality() {
    const additionalFeedbackIssue = happinessSurveyValues.additionalFeedbackIssue;
    const { QUALITY_FEEDBACK, QUALITY_ISSUE } = additionalFeedbackIssue;
    if (this.isFeedbackAlreadyPresent(QUALITY_FEEDBACK) || this.isFeedbackAlreadyPresent(QUALITY_ISSUE)) {
      return;
    }

    if (this.previouslyAtCommentPage()) {
      this.props.addFeedback(QUALITY_FEEDBACK);
    } else {
      this.props.addFeedback(QUALITY_ISSUE);
    }
  }

  handleClickSkip() {
    this.handleAdditionalFeedbackQuality();
    this.props.completeSurvey(this.props.currentSurvey.bookingId);
  }

  handleClickNext() {
    this.handleAdditionalFeedbackQuality();
    if (this.isDamaged()) {
      this.props.goToPage(this.props.damagedPage);
    } else {
      this.props.goToPage(this.props.nextPage);
    }
  }

  isDamaged() {
    return this.isFeedbackAlreadyPresent(happinessSurveyValues.otherIssues.ITEM_DAMAGED);
  }

  handleSelect(value) {
    if (!this.isFeedbackAlreadyPresent(value)) {
      this.props.addFeedback(value);
    } else {
      this.props.removeFeedback(value);
    }
  }

  isFeedbackAlreadyPresent = value => {
    const { currentSurvey } = this.props;
    return happinessSurveyContainsFeedback(currentSurvey, value);
  };

  willRender() {
    return _.isEqual(this.props.currentPage, this.constructor.pageName);
  }

  atLeastOneReasonSelected() {
    if (!this.props?.currentSurvey?.surveyFeedbacks) {
      return false;
    }
    return _.find(happinessSurveyValues.otherIssues, this.isFeedbackAlreadyPresent);
  }

  renderMissingPiece() {
    return this.renderPanelCheckbox(happinessSurveyValues.otherIssues.MISSING_PIECE, "Missing Parts");
  }

  renderWorn() {
    return this.renderPanelCheckbox(happinessSurveyValues.otherIssues.WORN, "Overworn");
  }

  renderItemDamaged() {
    return this.renderPanelCheckbox(happinessSurveyValues.otherIssues.ITEM_DAMAGED, "Damaged");
  }

  renderScentIssue() {
    if (!this.props.isAccessory) {
      return this.renderPanelCheckbox(happinessSurveyValues.otherIssues.ODOR, "Scent");
    }
  }

  renderPanelCheckbox(value, valueAsText) {
    return (
      <div className="other-issues__action">
        <PanelCheckbox
          checked={this.isFeedbackAlreadyPresent(value)}
          onClick={this.handleSelect.bind(this)}
          displayText={valueAsText}
          value={value}
        />
      </div>
    );
  }

  render() {
    if (!this.willRender()) {
      return null;
    }

    const disableNext = !this.atLeastOneReasonSelected();

    return (
      <div className="other-issues">
        <SurveyControls
          willShowBack={true}
          onClickBack={() => {
            this.handleClickBack();
          }}
          onClickSkip={() => {
            this.handleClickSkip();
          }}
        />
        <div className="other-issues__copy h4">What was wrong with this item?</div>
        <div className="other-issues__subcopy h5">Select all that apply.</div>
        {this.renderMissingPiece()}
        {this.renderWorn()}
        {this.renderScentIssue()}
        {this.renderItemDamaged()}
        <div className="other-issues__next">
          <button
            onClick={() => {
              this.handleClickNext();
            }}
            className="btn"
            disabled={disableNext}>
            Next
          </button>
        </div>
      </div>
    );
  }
}

OtherIssues.propTypes = propTypes;
