import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { membershipStatePropType, userDataPropType } from "components/propTypes";
import ActionLogger from "action-logger";
import { analytics } from "rtr-constants";
import ClosableModal from "components/source/shared/closable-modal";
import {
  closeReviewUploadModal,
  displayReviewUploadModal,
  getIsProductReviewableByCurrentUser,
  setReviewCoverPhoto,
  submitReview,
} from "actions/reviews-actions";
import { cropReviewPhoto, deleteReviewPhoto, rotateReviewPhoto, submitReviewPhoto } from "actions/review-photo-actions";
import { displayModal, hasFileSizeError } from "actions/shared-actions";
import { getLocationSearch } from "helpers/location-helpers";
import ReviewUploadModal from "./review-upload-modal";
import ReviewPopupSubmitSuccess from "./popups/review-popup-submit-success";
import ReviewCropPhotosModal from "./review-crop-photos-modal";

// A container for the Review Upload and Review Viewing modals
class ReviewsModalContainer extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    cropReviewPhotoLoading: PropTypes.bool,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    launchReviewModalFromQueryString: PropTypes.func.isRequired, // styleName => {}
    loadingReviewUploadPhotos: PropTypes.bool,
    loadingReviewUploadRotatedPhoto: PropTypes.bool,
    membershipState: membershipStatePropType,
    onDeletePhoto: PropTypes.func,
    onReviewCropPhotosSuccess: PropTypes.func,
    onRotatePhoto: PropTypes.func,
    onSetCoverPhoto: PropTypes.func,
    onSubmitCroppedReviewPhoto: PropTypes.func,
    onSubmitReview: PropTypes.func,
    onUploadPhoto: PropTypes.func,
    product: PropTypes.shape({
      id: PropTypes.string,
    }), // the product on the current PDP, if any
    reviewUploadPhotos: PropTypes.arrayOf(PropTypes.object),
    reviewUploadState: PropTypes.shape({
      errorMessage: PropTypes.string,
      isSubmitting: PropTypes.bool,
      loading: PropTypes.bool,
      pendingReview: PropTypes.shape({
        photos: PropTypes.arrayOf(PropTypes.object),
        reviewId: PropTypes.number,
        rating: PropTypes.number,
      }),
      product: PropTypes.shape({
        type: PropTypes.string,
        styleName: PropTypes.string,
      }),
      rentedSizes: PropTypes.arrayOf(PropTypes.string),
    }),
    userData: userDataPropType,
  };

  componentDidMount() {
    if (this.props.product?.id) {
      const queryString = getLocationSearch();

      // NW [EXPLANATION] 8/12/21: a PDP link with this query string will auto-open the Review Upload modal
      if (queryString.indexOf("writereview") > -1) {
        this.props.launchReviewModalFromQueryString(this.props.product.id);
      }
    }
  }

  isUploadModalOpen() {
    return this.props.displayedModal === ReviewUploadModal.MODAL_NAME;
  }

  isReviewCropPhotosModalOpen() {
    return this.props.displayedModal === ReviewCropPhotosModal.MODAL_NAME;
  }

  isReviewSubmitSuccessModalOpen() {
    return this.props.displayedModal === ReviewPopupSubmitSuccess.MODAL_NAME;
  }

  isReviewModalOpen() {
    return this.isUploadModalOpen() || this.isReviewCropPhotosModalOpen() || this.isReviewSubmitSuccessModalOpen();
  }

  closeModal = () => {
    const { displayedModal, reviewUploadState } = this.props;
    if (displayedModal === ReviewUploadModal.MODAL_NAME) {
      ActionLogger.logAction({
        object_type: analytics.OBJECT_TYPE.REVIEWS,
        action: "cancel_review",
        styleName: reviewUploadState?.product?.styleName,
      });
    } else if (displayedModal === ReviewPopupSubmitSuccess.MODAL_NAME) {
      ActionLogger.logAction({
        object_type: analytics.OBJECT_TYPE.REVIEWS,
        action: "close_review_popup_thank_you",
        styleName: reviewUploadState?.product?.styleName,
      });
    } else if (displayedModal === ReviewCropPhotosModal.MODAL_NAME) {
      ActionLogger.logAction({
        object_type: analytics.OBJECT_TYPE.REVIEWS,
        action: "skip_crop",
        styleName: reviewUploadState?.product?.styleName,
      });
    }

    this.props.closeModal(displayedModal);
  };

  render() {
    const modalOptionalClass = classNames("reviews-modal-container modal-close-circle", {
      "review-upload-modal": this.isUploadModalOpen(),
      "review-crop-photos-modal": this.isReviewCropPhotosModalOpen(),
      "review-submit-success-modal": this.isReviewSubmitSuccessModalOpen(),
    });
    const additionalStyles = {
      content: {
        padding: "0",
      },
    };

    return (
      <ClosableModal
        additionalStyles={additionalStyles}
        isOpen={this.isReviewModalOpen()}
        onRequestClose={this.closeModal}
        optionalClass={modalOptionalClass}>
        {this.isUploadModalOpen() && (
          <ReviewUploadModal
            errorMessage={this.props.reviewUploadState?.errorMessage}
            profile={this.props.userData?.userProfile}
            isSubmitting={this.props.reviewUploadState?.isSubmitting}
            loading={this.props.reviewUploadState?.loading}
            loadingPhotos={this.props.loadingReviewUploadPhotos}
            loadingRotatedPhoto={this.props.loadingReviewUploadRotatedPhoto}
            pendingReview={this.props.reviewUploadState?.pendingReview}
            product={this.props.reviewUploadState?.product}
            rentedSizes={this.props.reviewUploadState?.rentedSizes}
            reviewPhotos={this.props.reviewUploadPhotos}
            onDeletePhoto={this.props.onDeletePhoto}
            onRotatePhoto={this.props.onRotatePhoto}
            onSubmitReview={this.props.onSubmitReview}
            onUploadPhoto={this.props.onUploadPhoto}
          />
        )}
        {this.isReviewCropPhotosModalOpen() && (
          <ReviewCropPhotosModal
            photos={this.props.reviewUploadState?.pendingReview?.photos}
            reviewId={this.props.reviewUploadState?.pendingReview?.reviewId}
            onSetCoverPhoto={this.props.onSetCoverPhoto}
            onSubmitCroppedReviewPhoto={this.props.onSubmitCroppedReviewPhoto}
            loading={this.props.cropReviewPhotoLoading}
            onSuccess={this.props.onReviewCropPhotosSuccess}
            styleName={this.props.reviewUploadState?.product?.styleName}
          />
        )}
        {this.isReviewSubmitSuccessModalOpen() && (
          <ReviewPopupSubmitSuccess
            hideCloseButton={true}
            reviewRating={this.props.reviewUploadState?.pendingReview?.rating}
            closeAction={this.props.closeModal}
            membershipState={this.props.membershipState}
          />
        )}
        {/* NW [TODO] 7/29/21: implement ReviewViewModal here in MA-589 */}
      </ClosableModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    cropReviewPhotoLoading: state.cropReviewPhotoLoading,
    displayedModal: state.displayedModal,
    loadingReviewUploadPhotos: state.loadingPhotos,
    loadingReviewUploadRotatedPhoto: state.loadingRotatedPhoto,
    membershipState: state.membershipState,
    product: state.product, // this is a thing?!?!
    reviewUploadPhotos: state.reviewPhotos,
    reviewUploadState: state.reviewUploadState,
    userData: state.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: displayedModal => {
      if (displayedModal === ReviewUploadModal.MODAL_NAME) {
        dispatch(hasFileSizeError(false));
      } else {
        dispatch(displayModal(false));
      }
      dispatch(closeReviewUploadModal());
    },
    launchReviewModalFromQueryString: styleName => {
      dispatch(getIsProductReviewableByCurrentUser(styleName)).then(isReviewable => {
        if (isReviewable) {
          dispatch(displayReviewUploadModal({ styleName, popupSource: "writereviewstring" }));
        }
      });
    },
    onDeletePhoto: photoId => {
      return dispatch(deleteReviewPhoto(photoId));
    },
    onReviewCropPhotosSuccess: () => {
      dispatch(displayModal(ReviewPopupSubmitSuccess.MODAL_NAME));
    },
    onRotatePhoto: photoId => {
      return dispatch(rotateReviewPhoto(photoId));
    },
    onSubmitReview: reviewData => {
      return dispatch(submitReview(reviewData));
    },
    onUploadPhoto: (styleName, photo) => {
      return dispatch(submitReviewPhoto(styleName, photo));
    },
    onSetCoverPhoto: (reviewId, photoId) => {
      return dispatch(setReviewCoverPhoto(reviewId, photoId));
    },
    onSubmitCroppedReviewPhoto: (photoId, croppedPhotoData) => {
      return dispatch(cropReviewPhoto(photoId, croppedPhotoData));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsModalContainer);
export { ReviewsModalContainer as UnconnectedReviewsModalContainer };
